import {BaseContainer} from "./base.container";
import {Well} from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import {MD, Span, XXL} from "@zendeskgarden/react-typography";
import React, {useContext, useEffect, useState} from "react";
import {Button, ButtonGroup} from "@zendeskgarden/react-buttons";
import {Col, Row} from "@zendeskgarden/react-grid";
import {PALETTE} from "@zendeskgarden/react-theming";
import {useHistory} from "react-router-dom";
import {Skeleton} from "@zendeskgarden/react-loaders";
import moment from "moment";
import {DriverScopeContext} from "../../context/driver-scope.context";
import {Donation} from "../../domain";
import {extraButton} from "../../utility/extraButton";
import {DatePicker} from '../../components/date-picker.c';
import {DonationStatus} from '../../domain/Donation';
import {Field} from '@zendeskgarden/react-forms';
import {Truck} from '../../domain/Truck';
import {AutocompleteField} from '../../components/auto-complete-field';
import {useIntercom} from 'react-use-intercom';
import {AppContext} from '../../context';

export const DonationsContainer = () => {
    const history = useHistory()
    const { boot } = useIntercom();
    const {user} = useContext(AppContext);

    const {donations, actions, meta, selectedTruck, setSelectedTruck, trucks} = useContext(DriverScopeContext)
    const [dateFilter, setDateFilter] = useState(moment(new Date()).format('MM-DD-YYYY'))

    const getDonations = () => {
        actions.getDonations(`date=${dateFilter}&truck_id=${selectedTruck?.id}`)
    }

    const getTrucks = () => {
        actions.getTrucks()
    }

    useEffect(() => {
        if (selectedTruck && dateFilter) {
            getDonations();
        }
    }, [selectedTruck, dateFilter])

    useEffect(getTrucks, [])


    const renderPlaceholder = () => (
        <Row justifyContent="center">
            <Col sm={5}>
                <XXL>
                    <Skeleton/>
                </XXL>
                <MD>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </MD>
            </Col>
        </Row>
    )

    const startIntercom = (donation: Donation) => {
        boot({
            name: user?.details.name,
            customAttributes: {
                tplOrganizationId: user?.details.tplOrganizationId,
                donationCode: donation.donationCode

            }
        });
    }

    const onCustomDateSelected = (field: string, value?: string) => {
        setDateFilter(value!);
    }

    const startDonation = (donation: Donation) => {
        const address = donation.donor.address + ',' + donation.donor.city
        window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${address}`);
        updateDonationAndNotifyDonor(donation);
    }

    const updateDonationAndNotifyDonor = (donation: Donation) => {
        if (donation){
            donation.donationStatus = DonationStatus.driver_en_route;
            donation.eventType = `donation_${DonationStatus.driver_en_route}`
            actions.updateDonation(donation).then((result) => {
                getDonations();
            })
        }
    }

    const notifyArrival = (donation: Donation) => {
        if (donation){
            donation.donationStatus = DonationStatus.driver_arrived;
            donation.eventType = `donation_${DonationStatus.driver_arrived}`
            actions.updateDonation(donation).then(() => {
                history.push(`/donations/${donation.id}/quote-calculator`)
            })
        }
    }

    const decideAction = (donation: Donation) => {
        if (donation.donationStatus === DonationStatus.driver_assigned || donation.donationStatus === DonationStatus.hauler_accepted) {
            startDonation(donation);
        } else if (donation.donationStatus === DonationStatus.driver_en_route) {
            notifyArrival(donation)
        } else {
            resumeJob(donation)
        }
    }

    const decideActionLabel = (donation: Donation) => {
        if (donation.donationStatus === DonationStatus.driver_assigned || donation.donationStatus === DonationStatus.hauler_accepted) {
            return "Start Donation"
        } else if (donation.donationStatus === DonationStatus.driver_en_route) {
            return "Arrived"
        } else {
            return "Resume Donation"
        }
    }

    const resumeJob = (donation: Donation) => {
        if (donation){
            let step = '';
            switch (donation.donationStatus){
                case DonationStatus.driver_arrived: step = 'quote-calculator'; break;
                case DonationStatus.quote_sent: step = 'awaiting-user-acceptance'; break;
                case DonationStatus.payment_successful: step = 'quote-accepted'; break;
                case DonationStatus.photos_taken: step = 'load-up-and-move-out'; break;
                case DonationStatus.awaiting_charity_confirmation: step = 'awaiting-charity-confirmation'; break;
                case DonationStatus.completed: step = 'completed-primary-drop-off'; break;
                default: step = 'start-donation'
            }

            history.push(`/donations/${donation.id}/${step}`)
        }
    }

    const resolveTruckName = (t: Truck) => {
        return t?.name
    }

    const openDonationDetails = (donation: Donation) => {
        history.push(`/donations/${donation.id}`)
    }

    const extraButtons = [
        extraButton("Refresh", getDonations)
    ]

    return (
        <BaseContainer title={"Donations"} subtitle={"All of your donations"} extraButtons={extraButtons}>
            <>
                <StyledField>
                    <AutocompleteField
                        label={"Selected truck"}
                        options={trucks}
                        value={selectedTruck}
                        valueResolver={resolveTruckName}
                        onValueSelected={setSelectedTruck}
                        mobileFocusDisabled
                    />
                </StyledField>
                <CenteredRow>
                    <ButtonGroup selectedItem={dateFilter} onSelect={setDateFilter} style={{marginRight: '10px'}}>
                        <Button value={moment(new Date()).format('MM-DD-YYYY')} isPrimary>Today</Button>
                        <Button value={moment(new Date()).add(1, 'd').format('MM-DD-YYYY')} isPrimary>Tomorrow</Button>
                    </ButtonGroup>
                    <DatePicker value={dateFilter} name={'date'} onChange={onCustomDateSelected}/>
                </CenteredRow>

                {meta.loading.isDonationsLoading ? (
                    renderPlaceholder()
                ) : (
                    donations.length === 0 ? (
                        <CenteredRow style={{padding: '100px 0'}}>
                            <> No donations for selected date or truck yet</>
                        </CenteredRow>

                    ) : (
                        <>
                            {donations.map((donation: Donation) => (
                                <StyledWell key={donation.id}
                                            style={{
                                                opacity: donation.donationStatus === DonationStatus.completed || donation.donationStatus === DonationStatus.canceled ? 0.6 : 1,
                                                pointerEvents: donation.donationStatus === DonationStatus.completed || donation.donationStatus === DonationStatus.canceled ? 'none' : 'all'
                                            }}>
                                    <StyledWellTop>
                                        <WellHeader>
                                            <p>{donation.donationCode}</p>
                                        </WellHeader>
                                        <DonationDetails>
                                            <Row>
                                                <Col xs={4}>
                                                    <Span>Part of day:</Span>
                                                    <UppercaseMD>{donation.partOfDay}</UppercaseMD>
                                                </Col>
                                                <Col xs={8}>
                                                    <Span>City (Zip):</Span>
                                                    <UppercaseMD>{donation.donor.city + " (" + donation.donor.zip + ")"}</UppercaseMD>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Span>Items:</Span>
                                                    <MD>
                                                        {DonationItem("Extra large items", donation.spec.xl)}
                                                        {DonationItem("Large items", donation.spec.lg)}
                                                        {DonationItem("Medium items", donation.spec.md)}
                                                        {DonationItem("Bags & Boxes", donation.spec.sm)}

                                                        {DonationItem("Large items", donation.spec.largeItems)}
                                                        {DonationItem("Small items", donation.spec.smallItems)}
                                                        {DonationItem("Boxes", donation.spec.boxes)}
                                                        {DonationItem("Bags", donation.spec.bags)}
                                                        {DonationItem("Staircases", donation.spec.staircases)}
                                                        {DonationItem("Disassembly", donation.spec.disassembly)}
                                                    </MD>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Span>Cost estimate:</Span>
                                                    <MD>${donation.costEstimate}</MD>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Span>Charity Name:</Span>
                                                    <MD>{donation.charity?.name}</MD>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <Span>Donor:</Span>
                                                    <UppercaseMD>{donation.donor?.name}</UppercaseMD>
                                                </Col>
                                                <Col xs={6}>
                                                    <Span>Donor contact:</Span>
                                                    <UppercaseMD>
                                                        <a href={"sms:" + donation.donor.phone}>{donation.donor.phone}</a>
                                                    </UppercaseMD>
                                                </Col>

                                                <Col xs={12}>
                                                    <Span>Notes:</Span>
                                                    <p>{donation.spec.additionalInformation || '/'}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <Span>Donation status:</Span>
                                                    <UppercaseMD
                                                        style={{color: donation.donationStatus === 'completed' ? PALETTE.crimson.M600 : "black"}}
                                                    >{donation.donationStatus.replaceAll("_", " ").toUpperCase()}</UppercaseMD>
                                                </Col>
                                                <Col xs={6}>
                                                    <Button isStretched onClick={() => openDonationDetails(donation)}>More Details</Button>
                                                </Col>
                                            </Row>
                                        </DonationDetails>
                                    </StyledWellTop>
                                    <BottomControls>
                                        <Row>
                                            <Col xs={6}>
                                                <Button isStretched
                                                        isPrimary
                                                        onClick={() => {
                                                            decideAction(donation);
                                                        }}>
                                                    {decideActionLabel(donation)}
                                                </Button>
                                            </Col>
                                            <Col xs={6}><Button isStretched={true}
                                                                onClick={() => { startIntercom(donation) }}>Chat
                                                Resupply</Button></Col>
                                        </Row>
                                    </BottomControls>
                                </StyledWell>
                            ))}
                        </>
                    )
                )}
            </>

        </BaseContainer>
    )
}

const DonationItem = (label: string, quantity: number) => {
    return (quantity !== undefined && quantity > 0) ? (
        <>{label + ' x' + Math.round(quantity) + ', '}</>
    ) : null
}

const StyledWellTop = styled.div`
`

const StyledWell = styled(Well)`
  padding: 20px;
  margin-bottom: 10px;
`

const StyledField = styled(Field)`
  display: block;
  input {
    font-size: 16px!important;
  }
`

const UppercaseMD = styled(MD)`
  text-transform: uppercase;
`

const CenteredRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    font-size: 16px;
  }
`

const DonationDetails = styled.div`
  font-size: 18px;
  font-weight: 600;
  Span {
    font-size: 10px;
    font-weight: normal;
  }
`

const WellHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`

const BottomControls = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  margin-top: 10px;
`


const VCenteredCol = styled(Col)`
  display: flex;
  align-items: center;

  Button {
    &:first-of-type {
      margin-left: 30px;
      margin-right: 10px;
    }
  }
`
