import {Donation} from "../../domain";
import {BaseContainer} from "./base.container";
import React, {useContext, useEffect, useState} from "react";
import {DonationContext} from "../../context";
import {useHistory, useParams} from "react-router-dom";
import {Paragraph} from "@zendeskgarden/react-typography";
import {Space} from "../../components";
import QRCode from "react-qr-code";
import {Button} from "@zendeskgarden/react-buttons";
import {Col, Row} from "@zendeskgarden/react-grid";
import {DonationStatus} from "../../domain/Donation";

export const AwaitCharityAcceptanceDonationContainer = () => {
    const history = useHistory();
    const [donation, setDonation] = useState<Donation>()
    const [reloadInterval, setReloadInterval] = useState<any>()
    const {actions} = useContext(DonationContext)

    const params = useParams<{ id: string }>()
    const {id} = params;

    useEffect(() => {
        actions.getDonation(id).then(setDonation);
        const timeout = setInterval(() => {
            actions.getDonation(id).then(setDonation);
        }, 3000);
        setReloadInterval(timeout);

        return () => {
            console.log('page left');
            clearInterval(timeout)
        }
    }, [])

    useEffect(() => {
        if (donation) {
            if (donation.donationStatus === DonationStatus.completed) {
                progress();
            }
        }
    }, [donation])

    const progress = () => {
        if (donation) {
            clearInterval(reloadInterval);
            history.replace(`/donations/${id}/completed-primary-drop-off`)
        }
    }

    return (
        <BaseContainer title={"Awaiting Charity acceptance!"} showBackButton showAsModal>
            <>
                <Paragraph>
                    We are waiting on your Charity manager to accept the items!
                </Paragraph>
                <Paragraph>
                    If they are having trouble, help them by having them scan this QR code to take them to their acceptance screen!
                </Paragraph>

                <Space size={50}/>
                <QRCode value={`https://portal.resupplyme.com/d/${donation?.donationCode}`}/>

                <Space size={50}/>

            </>
        </BaseContainer>
    )
}
