import Api from "../../../services/api.service";
import {routes} from "../../../services/api.routes";
import {CharitySlots} from "../../../domain/CharitySlots";

export const getAction = (id: string): Promise<CharitySlots> => {
    return Api.$<CharitySlots>(routes.availability).get(id)
}

export const getByCharityAction = (charityId: string): Promise<CharitySlots[]> => {
    return Api.$<CharitySlots>(routes.availability).getAll(`charity_id=${charityId}`)
}
