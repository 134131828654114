import {autoserialize} from "cerialize";

export class Authorization{
    @autoserialize
    token: string;


    constructor(token: string) {
        this.token = token;
    }
}
