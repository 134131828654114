import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React, {useContext, useEffect, useState} from "react";
import {Inline} from "@zendeskgarden/react-loaders";
import {TplOrganization} from "../../../../domain/schema";
import {CaptainScopeContext} from "../../../../context/captain-scope.context";
import {useForm} from "react-hook-form";
import {partnerDefaults} from "../partner.defaults";
import {partnerResolver} from "../partner.resolver";
import {Form} from "../../../../components/form";
import {Space} from "../../../../components";
import {User} from "../../../../domain";

type Props = {
	partner: TplOrganization
	onDone?: () => void
	onClose?: () => void
}
export const TplAdminCreationModal = (props: Props) => {
	const {partner, onDone = () => {}, onClose} = props;
	const {actions} = useContext(CaptainScopeContext);

	const [isLoading, setLoading] = useState(false);

	const {control, setValue, handleSubmit, formState: {errors}} = useForm<User>({
		defaultValues: partnerDefaults.admin,
		resolver: partnerResolver.admin
	})

	const onSubmit = handleSubmit((data) => {
		setLoading(true);
		actions.createUser(data).then(data => {
			onDone()
		}).finally(() => {setLoading(false)})
	})

	useEffect(() => {
		if (partner) {
			setValue('username', partner.email)
			setValue('details.email', partner.email)
			setValue('details.phone', partner.phone)
			setValue('details.name', partner.accountManagerName)
			setValue('details.tplOrganizationId', partner.id)
		}
	}, [partner])

	return (
		<Modal onClose={onClose}>
			<form onSubmit={onSubmit}>
				<Header>Add a 3PL admin profile</Header>
				<Body>
					Please provide necessary info in order to create a 3PL admin profile.
					They will use the credentials provided to access their account.
					<Space size={10}/>
					<Form.Input control={control} name={'username'} label={"Username"} errors={errors}/>
					<Form.Input control={control} name={'password'} label={"Password"} errors={errors}
								type={'password'}/>

					<Form.Input control={control} name={'details.name'} label={"Name"} errors={errors}/>
					<Form.Input control={control} name={'details.email'} label={"Email"} errors={errors}/>
					<Form.Input control={control} name={'details.phone'} label={"Phone number"} errors={errors}/>
				</Body>
				<Footer>
					<FooterItem>
						<Button onClick={onClose} isDanger isBasic>
							Cancel
						</Button>
					</FooterItem>
					<FooterItem>
						<Button
							style={{minWidth: 200}}
							disabled={isLoading}
							isPrimary
							type={"submit"}
						>
							{isLoading ? <Inline/> : ('Create admin profile')}
						</Button>
					</FooterItem>
				</Footer>
				<Close aria-label="Close modal"/>
			</form>
		</Modal>
	)
}
