import React, {FC, HTMLProps} from "react";
import {Field, Input, Label, Message} from "@zendeskgarden/react-forms";
import {Control, Controller} from "react-hook-form";
import styled from "styled-components";

type Props = {
	label: string,
	name: string,
	errors?: any,
	control: Control<any>
} & HTMLProps<HTMLInputElement>
export const FormInput:FC<Props> = (props) => {
	const {label, name, control, errors, type} = props;

	return (
		<StyledField>
			<Label>{label}</Label>
			<Controller
				name={name}
				control={control}
				render={({field}) => (
					<Input
						onChange={field.onChange}
						value={field.value}
						type={type}
					/>
				)}
			/>
			{errors && errors[name] && (<Message validation="error">{errors[name].message}</Message>)}
		</StyledField>
	)
}

const StyledField = styled(Field)`
  margin-bottom: 15px;
`
