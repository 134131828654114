import React, {useContext} from 'react';
import {Body, Chrome, Content, Main, Nav, NavItem, NavItemIcon, NavItemText} from '@zendeskgarden/react-chrome';
import {DEFAULT_THEME, PALETTE, ThemeProvider} from '@zendeskgarden/react-theming';

import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";

import {ReactComponent as ProductIcon} from '../assets/icons/pictogram.svg';

import {ReactComponent as HomeIcon} from '../assets/icons/parachute-box-light.svg';
import {ReactComponent as CharitiesIcon} from '../assets/icons/warehouse-alt-light.svg';
import {ReactComponent as UsersIcon} from '../assets/icons/user-friends-light.svg';
import {ReactComponent as PartnersIcon} from '../assets/icons/truck-light.svg';
import {ReactComponent as ZonesIcon} from '../assets/icons/map-light.svg';
import {ReactComponent as AvailabilityIcon} from '../assets/icons/calendar-times-light.svg';
import {ReactComponent as ExceptionsIcon} from '../assets/icons/engine-warning-light.svg';
import {ReactComponent as LogisticsIcon} from '../assets/icons/person-dolly-light.svg';

import styled from "styled-components";
import {CharitiesContainer, CharityContainer, CreateCharityContainer, CreateDonationContainer, CreateUserContainer, CreateZoneContainer, DonationContainer, DonationsFunnelContainer, ExceptionsContainer, LoginContainer, PartnerContainer, PartnersContainer, UsersContainer, ZonesContainer} from "../containers/captain";
import {CaptainHeader, Space} from "../components";
import {captainTheme} from "../themes";
import {AppContext} from "../context";
import {CaptainScopeProvider} from "../providers/captain-scope.provider";
import {CreatePartnerContainer} from "../containers/captain/create-partner.container";
import {AvailabilityContainer} from "../containers/captain/availability.container";
import {Paragraph} from "@zendeskgarden/react-typography";
import {LogisticsContainer} from "../containers/captain/logistics.container";
import {ServiceRequestContainer} from "../containers/captain/service-request.container";

const CaptainApp = () => {
    const {isAuthenticated} = useContext(AppContext).meta;

    const MenuItem = (link: string, Icon: any, label: string) => (
        <StyledLink to={link}>
            <StyledNavItm>
                <NavItemIcon>
                    <Icon style={{color: '#ffffff'}}/>
                </NavItemIcon>
                <NavItemText>{label}</NavItemText>
            </StyledNavItm>
        </StyledLink>
    )

    return (
        <ThemeProvider focusVisibleRef={null} theme={captainTheme as any}>
            {isAuthenticated() ? (
                <Router>
                    <CaptainScopeProvider>
                        <StyledChrome hue={PALETTE.blue["600"]} isFluid>
                            <Body>
                                <CaptainHeader/>
                                <Content>
                                    <StyledNav isExpanded>
                                        <MenuCategory>Operations</MenuCategory>
                                        {MenuItem('/', HomeIcon, 'Donations')}
                                        {MenuItem('/availability', AvailabilityIcon, 'Master Schedule')}
                                        {MenuItem('/exceptions', ExceptionsIcon, 'Exceptions')}
                                        {MenuItem('/logistics', LogisticsIcon, 'Logistics')}
                                        <Space size={30}/>
                                        <MenuCategory>Collections</MenuCategory>
                                        {MenuItem('/partners', PartnersIcon, 'Partners')}
                                        {MenuItem('/charities', CharitiesIcon, 'Charities')}
                                        {MenuItem('/zones', ZonesIcon, 'Zones')}
                                        {MenuItem('/users', UsersIcon, 'Users')}


                                        <NavItem hasBrandmark title="ReSupply">
                                            <NavItemIcon>
                                                <ProductIcon/>
                                            </NavItemIcon>
                                            <NavItemText>ReSupply</NavItemText>
                                        </NavItem>
                                    </StyledNav>
                                    <Main>
                                        <div style={{padding: DEFAULT_THEME.space.lg}}>
                                            <Switch>
                                                <Route exact path={'/'} component={DonationsFunnelContainer}/>
                                                <Route exact path={'/donations/:id'} component={DonationContainer}/>
                                                <Route exact path={'/create-donation'} component={CreateDonationContainer}/>
                                                <Route exact path={'/edit-donation/:id'} component={CreateDonationContainer}/>

                                                <Route exact path={'/partners'} component={PartnersContainer}/>
                                                <Route exact path={'/create-partner'} component={CreatePartnerContainer}/>
                                                <Route exact path={'/partners/:id'} component={PartnerContainer}/>
                                                <Route exact path={'/edit-partner/:id'} component={CreatePartnerContainer}/>

                                                <Route exact path={'/charities'} component={CharitiesContainer}/>
                                                <Route exact path={'/charities/:id'} component={CharityContainer}/>
                                                <Route exact path={'/create-charity'} component={CreateCharityContainer}/>
                                                <Route exact path={'/edit-charity/:id'} component={CreateCharityContainer}/>

                                                <Route exact path={'/zones'} component={ZonesContainer}/>
                                                <Route exact path={'/create-zone'} component={CreateZoneContainer}/>
                                                <Route exact path={'/edit-zone/:id'} component={CreateZoneContainer}/>

                                                <Route exact path={'/users'} component={UsersContainer}/>
                                                <Route exact path={'/create-user'} component={CreateUserContainer}/>
                                                <Route exact path={'/edit-user/:id'} component={CreateUserContainer}/>

                                                <Route exact path={'/availability'} component={AvailabilityContainer}/>
                                                <Route exact path={'/exceptions'} component={ExceptionsContainer}/>
                                                <Route exact path={'/logistics'} component={LogisticsContainer}/>

                                                <Route exact path={'/service-request/:tplId/:date'} component={ServiceRequestContainer}/>
                                            </Switch>
                                        </div>
                                    </Main>
                                </Content>
                            </Body>

                        </StyledChrome>
                    </CaptainScopeProvider>
                </Router>
            ) : (
                <Router>
                    <Switch>
                        <Route exact path={'/'} component={LoginContainer}/>
                    </Switch>
                </Router>
            )}
        </ThemeProvider>

    );
}

export default CaptainApp;

const StyledChrome = styled(Chrome)`
  height: 100vh;
`

const StyledLink = styled(Link)`
  width: 100%;
`

const StyledNavItm = styled(NavItem)`
  width: 100%;
  color: white !important;

  &:hover {
    color: white;
  }
`
const StyledNav = styled(Nav)`
`
const MenuCategory = styled(Paragraph)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 14px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0.5;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
`
