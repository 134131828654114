import React, {useContext, useEffect, useState} from "react";
import {BaseContainer} from "../../base.container";
import {Alert, Close, Title} from "@zendeskgarden/react-notifications";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Button} from "@zendeskgarden/react-buttons";
import {Space} from "../../../components";
import Api, {method} from "../../../services/api.service";
import {routes} from "../../../services/api.routes";
import {AppContext} from "../../../context";


export const PaymentsContainer = () => {
	const {user} = useContext(AppContext)

	const [isLoading, setIsLoading] = useState(true)
	const [accountEnabled, setAccountEnabled] = useState(false)

	const setupAccount = () => {
		Api.$(routes.getMerchantUrl).call(method.post, {
			tplId: user?.details.tplOrganizationId,
			returnUrl: `https://resupplyme.com`,
			refreshUrl: `https://resupplyme.com`
		}).then((res: any) => {
			window.open(res.url, "_blank")
		})
	}

	const openDashboard = () => {
		Api.$(routes.getMerchantAccountLogin).call(method.post, {
			tplOrganizationId: user?.details.tplOrganizationId
		}).then((res: any) => {
			window.open(res.url, "_blank")
		})
	}

	useEffect(() => {
		if (user?.details.tplOrganizationId){
			Api.$(routes.getMerchantAccountInfo).call(method.post, {
				tplOrganizationId: user?.details.tplOrganizationId
			}).then((res:any) => {
				setAccountEnabled(res.enabled)
			}).finally(() => {
				setIsLoading(false);
			})
		}
	}, [user])

	return (
		<BaseContainer
			title={'Payments'}
			subtitle={'Manage your payments account'}
		>
			{!isLoading && (
				<Row>
					{accountEnabled ? (
						<Col xs={6}>
							<Alert type="success">
								<Title>Your stripe account is connected</Title>
								Use the button below to access your Stripe Express account
							</Alert>
							<Space size={20}/>
							<Button size={'large'} onClick={openDashboard}>Open dashboard</Button>
						</Col>
					) : (
						<Col xs={6}>
							<Alert type="warning">
								<Title>Stripe account setup required</Title>
								You must setup your Stripe payments account in order to receive proceeds from your Resupply donation pickups.
							</Alert>
							<Space size={20}/>
							<Button size={'large'} onClick={setupAccount}>Setup your account</Button>
						</Col>
					)}
				</Row>
			)}

		</BaseContainer>
	)
}
