import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React from "react";

type Props = {
	onDone?: () => void
	onClose?: () => void
	title: string
	content: string
}

export const ConfirmationModal = (props: Props) => {
	const {
		onDone = () => {
		},
		onClose = () => {
		},
		title,
		content
	} = props;


	return (
		<Modal onClose={onClose}>
			<Header>{title}</Header>
			<Body>{content}</Body>
			<Footer>
				<FooterItem>
					<Button onClick={onClose} isDanger isBasic>
						Cancel
					</Button>
				</FooterItem>
				<FooterItem>
					<Button
						type={'submit'}
						style={{minWidth: 200}}
						isPrimary
						onClick={onDone}
					>
						Yes
					</Button>
				</FooterItem>
			</Footer>
			<Close aria-label="Close modal"/>
		</Modal>
	)
}
