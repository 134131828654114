import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@zendeskgarden/css-bedrock';

const container = document.getElementById('root')
ReactDOM.render(
    <App/>,
    container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// debugContextDevtool(container);
reportWebVitals();
