import React, {useContext, useEffect, useState} from "react";
import {CaptainScopeContext} from "../context/captain-scope.context";
import {TPLOrganization} from "../domain";
import {Title, Well} from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import {Body, Cell, Row as TRow, Table} from "@zendeskgarden/react-tables";
import {Paragraph} from "@zendeskgarden/react-typography";
import {Row} from "@zendeskgarden/react-grid";

type Props = {
    partnerId: string,
    title?: string
}
export const PartnerWidget = (props: Props) => {
    const {partnerId, title = '3PL organization'} = props;
    const {actions} = useContext(CaptainScopeContext);
    const [partner, setPartner] = useState<TPLOrganization>()
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        actions.getPartner(partnerId).then(setPartner).then(() => {
            setLoading(false);
        })
    }, [partnerId])

    return (
        <Well style={{opacity: loading ? '0.5' : '1.0'}}>
            {partner && (
                <>
                    <StiledTitle>{title}</StiledTitle>
                    <Row>
                        <Table>
                            <Body>
                                <TRowNoBorder isReadOnly>
                                    <Cell>3PL name</Cell>
                                    <Cell><Paragraph>{partner.name}</Paragraph></Cell>
                                </TRowNoBorder>
                                <TRowNoBorder isReadOnly>
                                    <Cell>Account manager</Cell>
                                    <Cell><Paragraph>{partner.accountManagerName}</Paragraph></Cell>
                                </TRowNoBorder>
                                <TRowNoBorder isReadOnly>
                                    <Cell>Phone</Cell>
                                    <Cell><Paragraph>{partner.phone}</Paragraph></Cell>
                                </TRowNoBorder>
                            </Body>
                        </Table>
                    </Row>
                </>
            )}
        </Well>
    )
}

const StiledTitle = styled(Title)`
  margin-bottom: 20px;
`

const TRowNoBorder = styled(TRow)`
  border: none
`
