import React, {useContext, useEffect, useState} from 'react';
import {BaseContainer} from "../../base.container";
import {Col, Row} from "@zendeskgarden/react-grid";
import {useParams} from "react-router-dom";
import {CharityWidget} from "../../../components";

import {Tab, TabList, TabPanel, Tabs} from '@zendeskgarden/react-tabs';
import {Charity, Donation} from "../../../domain";
import {DonationAddressWidget} from "../../../components/donation-address-widget.c";
import {EstimateWidget} from "../../../components/estimate-widget.c";
import {EntityRouteParams} from "../../../types";
import {DonationDateWidget} from "../../../components/donation-date-widget.c";
import {defaultPricing} from "../../../utility/pricing";
import {TplScopeContext} from "../../../context/tpl-scope.context";
import {DonorWidget} from "../components/donor-widget.c";
import {extraButton} from "../../../utility/extraButton";
import {DonationStatus} from "../../../domain/Donation";
import {Modal} from '../../../components/modals';
import {TruckAssignmentModal} from "../components/truck-assignment-modal.c";
import {Truck} from "../../../domain/schema";

export const DonationContainer = () => {
	const {id} = useParams<EntityRouteParams>()

	const {actions, trucks} = useContext(TplScopeContext)

	const [selectedTab, setSelectedTab] = useState('general');

	const [donation, setDonation] = useState<Donation>()
	const [charity, setCharity] = useState<Charity>()

	const [showDeclineModal, setShowDeclineModal] = useState(false)
	const [showAssignModal, setShowAssignModal] = useState(false)
	const [showCancelModal, setShowCancelModal] = useState(false)
	const [showAcceptModal, setShowAcceptModal] = useState(false)

	useEffect(() => {
		if (id) {
			loadDonation()
		}
	}, [id])

	useEffect(() => {
		if (donation) {
			actions.getCharity(donation.primaryDropOff?.id!).then(setCharity);
		}
	}, [donation])

	const loadDonation = () => {
		actions.getDonation(id).then(setDonation);
	}
	const toggleAssignModal = () => {
		setShowAssignModal(!showAssignModal)
	}
	const toggleDeclineModal = () => {
		setShowDeclineModal(!showDeclineModal)
	}
	const toggleCancelModal = () => {
		setShowCancelModal(!showCancelModal)
	}
	const toggleAcceptModal = () => {
		setShowAcceptModal(!showAcceptModal)
	}

	const acceptDonation = () => {
		toggleAcceptModal()
		if (donation) {
			const d = {id: donation.id, donationStatus: DonationStatus.hauler_accepted}
			actions.updateDonation(d as Donation).then(loadDonation)
		}
	}

	const declineDonation = () => {
		toggleDeclineModal()
		if (donation) {
			const d = {id: donation.id, donationStatus: DonationStatus.hauler_declined}
			actions.updateDonation(d as Donation).then(loadDonation)
		}
	}

	const cancelDonation = () => {
		toggleCancelModal()
		if (donation) {
			const d = {id: donation.id, donationStatus: DonationStatus.canceled, cancellationReason: 'Canceled by 3PL'}
			actions.updateDonation(d as Donation).then(loadDonation)
		}
	}

	const assignTruck = (truck:Truck) => {
		if (donation){
			donation.truckId = truck.id;
			actions.updateDonation(donation).then(loadDonation).finally(toggleAssignModal)
		}
	}

	const extraButtons = [
		extraButton("Accept donation", toggleAcceptModal, donation?.donationStatus !== DonationStatus.service_request_dispatched),
		extraButton("Decline donation", toggleDeclineModal, donation?.donationStatus !== DonationStatus.service_request_dispatched),
		extraButton("Assign truck", toggleAssignModal, donation?.donationStatus !== DonationStatus.hauler_accepted),
		extraButton("Cancel donation", toggleCancelModal, donation?.donationStatus !== DonationStatus.hauler_accepted),
	]


	return (donation && charity) ? (
		<>
			<BaseContainer
				showBackButton
				extraButtons={extraButtons}
				title={`Request: ${donation.donationCode}`}
				subtitle={'Donation details'}>
				<Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
					<TabList>
						<Tab item="general">General</Tab>
					</TabList>
					<TabPanel item="general">
						<Row>
							<Col xs={12} lg={4}>
								<DonorWidget donor={donation.donor}/>
								<CharityWidget charity={charity} spacing={10}/>
							</Col>
							<Col xs={12} lg={4}>
								<DonationDateWidget donation={donation} spacing={0}/>
								<DonationAddressWidget donation={donation} spacing={10}/>
								<EstimateWidget spec={donation.spec} pricing={donation.pricing || defaultPricing}
												spacing={10}/>
							</Col>
							<Col xs={4}>

							</Col>
						</Row>
					</TabPanel>
				</Tabs>
			</BaseContainer>
			{showDeclineModal && (
				<Modal.ConfirmationModal
					title={"Are you sure?"}
					content={"Are you sure you want to to DECLINE this donation pickup?"}
					onDone={declineDonation}
					onClose={toggleDeclineModal}/>)
			}
			{showAcceptModal && (
				<Modal.ConfirmationModal
					title={"Are you sure?"}
					content={"Are you sure you want to to ACCEPT this donation pickup?"}
					onDone={acceptDonation}
					onClose={toggleAcceptModal}/>)
			}
			{showCancelModal && (
				<Modal.ConfirmationModal
					title={"Are you sure?"}
					content={"Are you sure you want to to CANCEL this donation pickup?"}
					onDone={cancelDonation}
					onClose={toggleCancelModal}/>)
			}
			{showAssignModal && (
				<TruckAssignmentModal onClose={toggleAssignModal} onDone={assignTruck}/>
			)}
		</>

	) : (<></>)
};
