import {User} from "../domain";
import {createContext} from "react";
import {AuthProvider} from "../services/auth-provider";

type AppContextActionsType = {
    authenticate:(provider: AuthProvider, data: any) => Promise<User>
    trackUserActivity:() => void;
    logout:() => void
}
type AppContextMetaType = {
    isAuthenticated: () => boolean
}
export type AppContextType = {
    user: User | undefined
    setUser: (user: User) => void

    actions: AppContextActionsType
    meta: AppContextMetaType
}

export const AppContext = createContext<AppContextType>({} as AppContextType)
