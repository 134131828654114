import {BaseContainer} from "../base.container";
import {AutocompleteInput, FormField, Space, TableComponent} from "../../components";
import {Well} from "@zendeskgarden/react-notifications";
import {Col, Row} from "@zendeskgarden/react-grid";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {field} from "../../utility/field";
import {extraButton, ExtraButton} from "../../utility/extraButton";
import {navigationRoutes} from "../../utility/navigationRoutes";
import {Charity} from "../../domain";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {CharitiesTable} from "../../components/charities-table.c";

type LocalFilter = {
    charity: string
    state: string
    city: string
}
type Props = {};
export const CharitiesContainer = (props: Props) => {

    const history = useHistory();
    const {charities} = useContext(CaptainScopeContext)
    const [matchedCharities, setMatchedCharities] = useState<Charity[]>([])

    const [localFilter, setLocalFilter] = useState<LocalFilter>({
        charity: '',
        state: '',
        city: ''
    })

    const openCreateCharity = () => history.push(navigationRoutes.createCharity)
    const openCharityDetails = (charity: Charity) => history.push(navigationRoutes.charityDetails(charity))

    const changeLocalFilter = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target
        setLocalFilter({
            ...localFilter,
            [name]: value
        })
    }
    const extraButtons: ExtraButton[] = [
        extraButton("Create new charity", openCreateCharity)
    ]

    useEffect(() => {
        if (localFilter) {
            setMatchedCharities([
                ...charities
                    .filter(charity => {
                        return localFilter.charity.split(' ').reduce((p: boolean, c: string) => {
                            return p && charity.name.toLowerCase().includes(c.toLowerCase());
                        }, true);
                    })
                    .filter(charity => {
                        return charity.state.toLowerCase().includes(localFilter.state.toLowerCase())
                    })
                    .filter(charity => {
                        return charity.city.toLowerCase().includes(localFilter.city.toLowerCase())
                    })
            ])
        }
    }, [localFilter])

    return (
        <BaseContainer title={"Charities"} subtitle={"List of all charities"} extraButtons={extraButtons}>
            <Well>
                <Row>
                    <Col>
                        <FormField label={"Charity filter"} onChange={changeLocalFilter} name={'charity'}/>
                    </Col>
                    <Col>
                        <FormField label={"State filter"} onChange={changeLocalFilter} name={'state'}/>
                    </Col>
                    <Col>
                        <FormField label={"City filter"} onChange={changeLocalFilter} name={'city'}/>
                    </Col>
                </Row>
            </Well>
            <Space size={30}/>
            <Well>
                <Row>
                    <Col>
                        <CharitiesTable charities={matchedCharities} onCharitySelected={openCharityDetails}/>
                    </Col>
                </Row>

            </Well>
        </BaseContainer>
    )
}
