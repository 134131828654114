import React, {useEffect, useState} from 'react';
import { Dropdown as GardenDropdrown, Field, Menu, Item, Select, Label } from '@zendeskgarden/react-dropdowns';

export type DropdownItem = {
    label: string
    value: string
}
type Props = {
    label: string
    items: DropdownItem[],
    onChange?: (value: DropdownItem) => void
    value?: string
}

export const dropdownItem = (label: string, value: string) => {
    return {label, value}
}

export const Dropdown = (props: Props) => {
    const {items, label, onChange, value} = props;
    const [selectedItem, setSelectedItem] = useState(items[0]);

    // State changes
    const selectedItemChanged = () => {
        if (onChange){
            onChange(selectedItem);
        }
    }

    // Props changes
    const itemsChanged = () => {
        if (items && items.length > 0){
            setSelectedItem(items[0])
        }
    }

    useEffect(itemsChanged,[items])
    useEffect(selectedItemChanged, [selectedItem])
    useEffect(() => {
        if (value){
            setSelectedItem(items.find(i => i.value === value) || items[0])
        }
    }, [value])

    return(
        <GardenDropdrown
            selectedItem={selectedItem}
            onSelect={setSelectedItem}
            downshiftProps={{ itemToString: (item: DropdownItem) => item && item.label }}
        >
            <Field>
                <Label>{label}</Label>
                <Select>{selectedItem.label}</Select>
            </Field>
            <Menu>
                {items.map(option => (
                    <Item key={option.value} value={option}>
                        {option.label}
                    </Item>
                ))}
            </Menu>
        </GardenDropdrown>
    )
}



