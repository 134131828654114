import {Route, Switch, useHistory} from 'react-router-dom';
import {Donor} from './index';
import {Elements} from '@stripe/react-stripe-js';
import React, {useEffect} from 'react';
import {loadStripe} from "@stripe/stripe-js";
import styled from 'styled-components';
import {mediaQuery} from '@zendeskgarden/react-theming';
import ReactGA from 'react-ga';

const stripePromise = loadStripe('pk_live_51IbPzLION1witdQTAnASC69EjzZgS48HlVTFEleX2KSAZEbmng5Mo16WjaMk4LQ0BmXLniqxd90cDzeG5YXuL3vL00pG9KD8Hq');
export const HomeContainer = () => {

    const history = useHistory();

    const trackEvent = (location: any) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }
    useEffect(() => {
        trackEvent(history.location);
        history.listen(trackEvent);
    }, [])

    return (
        <Elements stripe={stripePromise}>
            <Main>
                <Content>
                    <Switch>
                        <Route exact path={'/start/:charityId'}>
                            <Donor.FlowContainer/>
                        </Route>
                        <Route exact path={'/:charityId/additional-information'}>
                            <Donor.DonationInformationContainer/>
                        </Route>
                        <Route exact path={'/:charityId/confirm'}>
                            <Donor.ConfirmDonationContainer/>
                        </Route>
                        <Route exact path={'/:charityId/success'}>
                            <Donor.DonationSuccessContainer/>
                        </Route>
                        <Route exact path={'/d/:donationCode'}>
                            <Donor.PaymentContainer/>
                        </Route>
                        <Route exact path={'/i/:donationCode'}>
                            <Donor.DonationUpdateContainer/>
                        </Route>
                        <Route exact path={'/i/:donationCode/reschedule'}>
                            <Donor.DonationUpdateDateContainer/>
                        </Route>
                        <Route exact path={'/i/:donationCode/cancel'}>
                            <Donor.DonationCancelContainer/>
                        </Route>
                        <Route exact path={'/c/:donationId/:outcome'}>
                            <Donor.PaymentCaptureOutcomeContainer/>
                        </Route>
                        <Route exact path={'/p/:donationId/:outcome'}>
                            <Donor.PaymentOutcomeContainer/>
                        </Route>
                    </Switch>
                </Content>
            </Main>
        </Elements>
    )
}
const Main = styled.main`
  height: -webkit-fill-available;
  height: calc(100vh);
  overflow: scroll;
  display: flex;
  flex-direction: row;

  * {
    box-sizing: border-box;
  }

  //background-color: #2c3b64;
`

const Content = styled.div`
  padding: 20px;
  flex: 1;
  max-width: 400px;
  margin: auto;

  ${p => mediaQuery('up', 'lg', p.theme)} {
    max-width: 900px;
    align-items: center;
    display: flex;
    justify-content: center;

  }

`
