import React, {useContext} from 'react';
import {Body, Chrome, Content, Main} from '@zendeskgarden/react-chrome';
import {DEFAULT_THEME, ThemeProvider} from '@zendeskgarden/react-theming';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import styled from "styled-components";
import {Driver} from '../containers/driver';
import {DonationProvider, UserProvider} from "../providers";
import {CharityProvider} from "../providers/charity.provider";
import {captainTheme} from "../themes";
import {DriverScopeProvider} from "../providers/driver-scope.provider";
import {AppContext} from "../context";
import {DriverHeader} from "../components/driver-header.component";
import {Helmet} from "react-helmet";

const DriverApp = () => {
    const {isAuthenticated} = useContext(AppContext).meta;

    return (
        <ThemeProvider focusVisibleRef={null} theme={captainTheme as any}>
            <Helmet>
                <script>
                {`
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:2549452,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
            </script>
            </Helmet>
            {isAuthenticated() ? (
                <Router>
                    <UserProvider>
                        <DonationProvider>
                            <DriverScopeProvider>
                                <CharityProvider>
                                    <StyledChrome isFluid>
                                        <StyledChromeBody>
                                            <DriverHeader/>
                                            <Content>
                                                <StyledMain
                                                    style={{padding: DEFAULT_THEME.space.md, boxSizing: "border-box"}}>
                                                    <Switch>
                                                        <Route exact path={'/'}>
                                                            <Driver.DonationsContainer/>
                                                        </Route>
                                                        <Route exact path={'/queue'}>
                                                            <Driver.DonationsContainer/>
                                                        </Route>
                                                        <Route exact path={'/truck-selection'}>
                                                            <Driver.TruckSelectionContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id'}>
                                                            <Driver.DonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/quote-calculator'}>
                                                            <Driver.QuoteCalculatorDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/awaiting-user-acceptance'}>
                                                            <Driver.AwaitDonorAcceptanceDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/quote-accepted'}>
                                                            <Driver.QuoteAcceptedDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/picture-gallery'}>
                                                            <Driver.PictureGaleryDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/load-up-and-move-out'}>
                                                            <Driver.LoadUpAndMoveOutDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/primary-drop-off'}>
                                                            <Driver.PrimaryDropOffDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/awaiting-charity-confirmation'}>
                                                            <Driver.AwaitCharityAcceptanceDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/completed-primary-drop-off'}>
                                                            <Driver.CompletedPrimaryDropOffDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/validate'}>
                                                            <Driver.ValidateDonationContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/photos'}>
                                                            <Driver.DonationPhotosContainer/>
                                                        </Route>
                                                        <Route exact path={'/donations/:id/primary-drop'}>
                                                            <Driver.DropOffContainer/>
                                                        </Route>
                                                    </Switch>
                                                </StyledMain>
                                            </Content>
                                        </StyledChromeBody>
                                    </StyledChrome>
                                </CharityProvider>
                            </DriverScopeProvider>
                        </DonationProvider>
                    </UserProvider>
                </Router>
            ) : (
                <Router>
                    <Switch>
                        <Route exact path={'/'} component={Driver.LoginContainer}/>
                    </Switch>
                </Router>
            )}

        </ThemeProvider>
    );
}

export default DriverApp;

const StyledChrome = styled(Chrome)`
  height: calc(100vh);
  height: -webkit-fill-available;
  overflow: hidden !important;
`
const StyledChromeBody = styled(Body)`
`
const StyledMain = styled(Main)`
  height: calc(100vh - 150px);
  height: -webkit-fill-available;
  //overflow: scroll;
`
