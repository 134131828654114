import {Truck} from "../../../domain/schema";

export const truckDefaults = {
	name: "",
	make: "",
	model: "",
	licensePlate: "",
	tplOrganizationId: "",
	type: "open",
	size: '12ft'
} as Truck
