import client, { ArchitectResource } from 'architect-sdk';
import {Truck, Trailer, TplOrganization, Zone} from "../domain/schema";

const baseUrl = 'https://architect.rspl.dev';

export type Schema = {
	trailers: ArchitectResource<Trailer>
	trucks: ArchitectResource<Truck>
	tpls: ArchitectResource<TplOrganization>
	zones: ArchitectResource<Zone>
}

export const architect = client<Schema>({ baseUrl });
