import React from "react";
import {Field, Label} from "@zendeskgarden/react-forms";
import {Space} from "../../../../components";
import {ZoneTags} from "../../../../components/zone-tags.c";
import {Paragraph} from "@zendeskgarden/react-typography";
import styled from "styled-components";
import {Box} from "../../../../components/box.c";
import {TplOrganization} from "../../../../domain/schema";

const infoField = (value: string, display: string) => (
	<StyledField>
		<Label>{display}</Label>
		<Paragraph>{value}</Paragraph>
	</StyledField>
)

type Props = {
	partner: TplOrganization
}
export const PartnerGeneralInfo = (props: Props) => {
	const {partner} = props;
	return (
		<Box>
			{infoField(partner.name, "Name")}
			{infoField(partner.phone, "Phone")}
			{infoField(partner.accountManagerName!, "Account manager")}
			{infoField(partner.email, "Email")}
			<StyledField>
				<Label>Zones</Label>
				<Space size={10}/>
				<ZoneTags zones={partner.zones}/>
			</StyledField>
		</Box>
	)
}

const StyledField = styled(Field)`
  margin-bottom: 15px;
`
