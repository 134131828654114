import React, {useContext, useEffect, useState} from "react";
import {BaseContainer} from "./base.container";
import {Col, Row} from "@zendeskgarden/react-grid";

import styled from "styled-components";
import {LG, MD, Paragraph, Span, XL} from "@zendeskgarden/react-typography";
import {Button} from "@zendeskgarden/react-buttons";
import {useParams} from "react-router-dom";
import {DonorContext} from "../../context/donor.context";
import {EstimateComponent, Space} from "../../components";
import {PALETTE} from '@zendeskgarden/react-theming';
import * as yup from 'yup';
import {removeKeys} from '../../utility/remove-keys';
import Api from "../../services/api.service";
import {routes} from "../../services/api.routes";
import {loadStripe} from "@stripe/stripe-js";
import {Charity, Donation} from "../../domain";
import {Inline} from "@zendeskgarden/react-loaders";
import {Checkbox, Field, Label} from '@zendeskgarden/react-forms';
import {DonationStatus} from "../../domain/Donation";
import {Box} from "../../components/box.c";
import PoweredByResupply from "../../assets/images/powered_by_resupply_logo.png";

const stripeKey = 'pk_live_51IbPzLION1witdQTAnASC69EjzZgS48HlVTFEleX2KSAZEbmng5Mo16WjaMk4LQ0BmXLniqxd90cDzeG5YXuL3vL00pG9KD8Hq';

export const ConfirmDonationContainer = () => {
    const params = useParams<{ charityId: string }>();
    const {charityId} = params;

    const [charity, setCharity] = useState<Charity>()
    const [loading, setLoading] = useState(true);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [isInCharityZone, setIsInCharityZone] = useState(false);

    const schema = yup.object().shape({
        name: yup.string().required(),
        address: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        zip: yup.string().required(),
        phone: yup.string().matches(/^(\+1)\d{10}/g).required(),
        email: yup.string().email().required(),
    })

    const {donationData,setDonationData, actions} = useContext(DonorContext)
    const [coiRequired, setCoiRequired] = useState(false)
    const [userAgrees,setUserAgrees] = useState(false)

    const progress = () => {
        setSubmitDisabled(true);
        if (!isInCharityZone){
            actions.getCharity("a7731ed4-c60e-4d48-92bf-564b2e509cd0").then(charity => {
                donationData.requestedCharityId = donationData.charity!.id
                donationData.charity = charity;
                donationData.isException = "yes";
                donationData.exceptionMeta = "Donation is outside charity zone."
                submitDonation(donationData)
            })
        }else{
            submitDonation(donationData);
        }
    }

    const submitDonation = (donationData: Donation) => {
        schema.validate(donationData.donor).then(() => {
            removeKeys(donationData.primaryDropOff, ['primaryDropOff', 'secondaryDropOff'])
            removeKeys(donationData.secondaryDropOff, ['primaryDropOff', 'secondaryDropOff'])
            removeKeys(donationData, ['zones'])
            donationData.donationStatus = DonationStatus.awaiting_card_on_file;
            donationData.eventType = 'donor_created_donation'
            actions.submitDonation(donationData).then((data) => {
                setDonationData({...donationData, donationCode: data.donationCode} as Donation)
                setupPayment(data);
            })
        }).catch(() => {
            setSubmitDisabled(false);
        })

    }

    const setupPayment = (donation: Donation) => {
        if (donation) {
            Api.$(routes.createStripeCustomer).create({
                success_url: `https://donate.resupplyme.com/c/${donation.id}/success`,
                cancel_url: `https://donate.resupplyme.com/c/${donation.id}/error`,
                // success_url: `http://localhost:3000/c/${donation.id}/success`,
                // cancel_url: `http://localhost:3000/c/${donation.id}/error`,
                donation_id: donation.id
            }).then((result: any) => {
                loadStripe(stripeKey, {
                    stripeAccount: result.account
                })
                    .then((value) => {
                        value!.redirectToCheckout({sessionId: result.id})
                    })

            })
                .catch(_ => {
                    //Todo: Dispatch this event somewhere and inform the support team about it.
                    console.log("Error");
                })
        }
    }

    useEffect(() => {
        donationData.coiRequired = coiRequired;
    }, [coiRequired])

    useEffect(() => {
        if (charityId){
            actions.getCharity(charityId).then(setCharity)
        }
    }, [charityId])

    useEffect(() => {
        if (charity){
            setLoading(false);
            const calc = charity.zones.reduce((p, c) => {
                return p || c.zips.includes(donationData?.donor.zip!)
            }, false)

            console.log(calc);
            setIsInCharityZone(calc)
        }

    }, [charity])


    return <BaseContainer
        title={"Review your Donation Details!"}
        subtitle={`Please review your price estimate, verify your donation details, and submit your donation when you are ready!`}
        showBackButton={true}>

        {loading ? (<Inline/>) : (
            <ContentWrapper>
                <StyledRow>
                    <Col xs={12}>
                        <img style={{margin: 20, maxWidth: 280}} src={charity?.logoUrl} alt={charity?.name}/>
                    </Col>
                </StyledRow>

                <StyledRow>
                    <Col lg={12}>
                        <Space size={30}/>
                        <Line/>
                        <Space size={30}/>
                        <div style={{textAlign: 'left'}}>
                            <LG style={{color: PALETTE.grey["500"]}}>Donation Summary</LG>
                            <Space size={30}/>
                            <LG><Span isBold>{charity?.name}</Span></LG>
                            <MD><Span isBold>Name:</Span> {donationData.donor.name}</MD>
                            <MD><Span isBold>Phone:</Span> {donationData.donor.phone}</MD>
                            <MD><Span isBold>Address:</Span> {donationData.donor.address} {donationData.donor.city} {donationData.donor.state} {donationData.donor.zip} </MD>
                            <MD><Span isBold>Date:</Span> {donationData.date || 'DATE MISSING'} at {donationData.partOfDay} </MD>
                        </div>

                        <StyledRow style={{marginBottom: 40, textAlign: 'left'}}>
                            <Col lg={12}>
                                <EstimateComponent spec={donationData.spec} pricing={donationData.pricing!}/>
                            </Col>
                        </StyledRow>

                        <Space size={30}/>

                        <StyledRow style={{textAlign: 'left'}}>
                            <Col lg={12}>
                                <Box>
                                    <Paragraph size={"small"}>Reserve your pick up appointment by adding a credit card. Your card WILL NOT BE CHARGED until our team is on site and has removed and loaded all of your donation items.</Paragraph>
                                    <Paragraph size={"small"}>Our donation driver team will likely ask for your card again on site.</Paragraph>
                                    <Paragraph size={"small"}>A $5 cancellation fee may be applied for last minute cancellations within 48 hours of your pick up.</Paragraph>
                                    <Space size={20}/>
                                    <Field>
                                        <Checkbox checked={userAgrees} onChange={() => {setUserAgrees(!userAgrees)}}>
                                            <Label>I understand that my card will not be charged until the donation pick up is complete.</Label>
                                        </Checkbox>
                                    </Field>

                                    <Space size={20}/>
                                    <StyledButton
                                        disabled={!userAgrees || submitDisabled}
                                        onClick={progress}
                                        isStretched>Reserve your pickup with a card</StyledButton>
                                </Box>
                            </Col>
                        </StyledRow>


                    </Col>
                </StyledRow>
                <Space size={60}/>
                <StyledRow>
                    <Col>
                        <img src={PoweredByResupply} alt="Powered by Resupply"/>
                    </Col>
                </StyledRow>
                <Space size={60}/>
            </ContentWrapper>
        )}
    </BaseContainer>
}

const ContentWrapper = styled.div`
  color: #2c3b64;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${PALETTE.grey["200"]};
`

const StyledRow = styled(Row)`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`
