import React, {useState} from "react";
import {DonorContext} from "../context/donor.context";
import Api, {method} from "../services/api.service";
import {routes} from "../services/api.routes";
import {Donation} from "../domain";
import {actions as commonActions} from "./actions/index"

type Props = {
    children: any
}
export const DonorProvider = (props: Props) => {
    const [donationData, setDonationData] = useState<Donation>(new Donation())


    const submitDonation = (data: Donation):Promise<Donation> => {
        return Api.$<Donation>(routes.submitDonation).create(data);
    }

    const getCharity = commonActions.charities.getAction


    return (
        <DonorContext.Provider value={{donationData, setDonationData, actions: {submitDonation, getCharity}}}>
            {props.children}
        </DonorContext.Provider>
    )
}
