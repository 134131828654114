import {Charity, User} from "../domain";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Space} from "./space";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import ReactInputMask from "react-input-mask";
import {Button} from "@zendeskgarden/react-buttons";
import React, {ChangeEvent, useContext, useState} from "react";
import styled from "styled-components";
import * as _ from "lodash";
import {Paragraph} from "@zendeskgarden/react-typography";
import {PALETTE} from "@zendeskgarden/react-theming";
import {Inline} from "@zendeskgarden/react-loaders";
import {CaptainScopeContext} from "../context/captain-scope.context";

type Props = {
    charity: Charity
    onDone?: (user: User) => void
    onClose?: () => void
}
export const CharityAdminCreationModal = (props: Props) => {
    const {charity, onDone, onClose} = props;
    const [user, setUser] = useState<User>(new User())
    const {actions} = useContext(CaptainScopeContext);

    const [error, setError] = useState('')
    const [isLoading, setLoading] = useState(false);

    const updateDriverEntry = (value: any, name: string) => {
        const d = new User();
        Object.assign(d, user);
        _.set(d, name, value);
        setUser(d);
    }
    const updateField = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value, name} = e.target;
        updateDriverEntry(value, name);
    }

    const submitUser = () => {
        setError('')
        setLoading(true);
        user.role = 'charity_admin'
        user.details.charityId = charity.id!;
        actions.createUser(user).then(onDone).catch(e => {
            setError("This username is already taken")
            setLoading(false);
        })
    }

    return (user) ? (
        <Modal onClose={onClose}>
            <Header>Add a charity admin profile</Header>
            <Body>
                Please provide necessary info in order to create a charity admin account.
                They will use the credentials provided to access their account.
                <Space size={10}/>
                <StyledField>
                    <Label>Charity admin name</Label>
                    <Input name={'details.name'} value={user.details.name} onChange={updateField}/>
                </StyledField>
                <StyledField>
                    <Label>Phone number</Label>
                    <ReactInputMask mask={'+19999999999'} name={'details.phone'} value={user.details.phone}
                                    onChange={updateField}>
                        <Input/>
                    </ReactInputMask>
                </StyledField>
                <StyledField>
                    <Label>Email</Label>
                    <Input name={'details.email'} value={user.details.email} onChange={updateField}/>
                </StyledField>
                <StyledField>
                    <Label>Username</Label>
                    <Input name={'username'} value={user.username} onChange={updateField}/>
                </StyledField>
                <StyledField>
                    <Label>Password</Label>
                    <Input type="password" name={'password'} value={user.password} onChange={updateField}/>
                </StyledField>

                <Paragraph color={PALETTE.red["400"]}>
                    {error}
                </Paragraph>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={onClose} isDanger isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        style={{minWidth: 200}}
                        disabled={isLoading}
                        isPrimary
                        onClick={submitUser}
                    >
                        {isLoading ? <Inline/> : ('Create user profile')}
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal"/>
        </Modal>
    ) : (<></>)
}
const StyledField = styled(Field)`
  margin-bottom: 15px;
`
