import React, {ChangeEvent, useContext, useState} from "react";
import styled from "styled-components";
import {XL} from "@zendeskgarden/react-typography";
import {Field, Input, InputGroup, Label} from "@zendeskgarden/react-forms";
import {Button} from "@zendeskgarden/react-buttons";
import {AppContext} from "../../context";
import {useHistory} from "react-router-dom";
import {Inline} from "@zendeskgarden/react-loaders";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";

import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import {Space} from "../../components";

export const LoginContainer = () => {
    const history = useHistory()
    const {actions} = useContext(AppContext)

    const [formData, setFormData] = useState<any>()
    const [isLoading, setLoading] = useState(false);
    const [hasAuthErrors, setAuthErrors] = useState(false);

    const updateField = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value, name} = e.target;
        setFormData({...formData, [name]: value})
    }

    const goToTruckSelection = () => history.replace("/truck-selection");

    const authenticationSuccessful = () => {
        setAuthErrors(false);
        setLoading(false)
        goToTruckSelection();
    }
    const authenticationFailed = () => {
        setAuthErrors(true);
        setLoading(false)
    }
    const authenticate = () => {
        setLoading(true);
        setAuthErrors(true);
        actions.authenticate('username', {...formData})
            .then(authenticationSuccessful)
            .catch(authenticationFailed)
    }


    const getButtonContent = () => {
        if (isLoading) {
            return <Inline/>
        }
        if (hasAuthErrors) {
            return "Credentials do not match. Please try again."
        }
        return "Sign in"
    }

    return (
        <Page>
            <Grid style={{height: 300}}>
                <Row>
                    <Col>
                        <Logo style={{width: 100, height: 100}}/>
                        <Space size={10}/>
                        <Title>Login to your driver account</Title>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <StyledField>
                                <Label>Username</Label>
                                <InputGroup>
                                    <Input name={"username"} autoCapitalize={'false'} onChange={updateField}/>
                                </InputGroup>

                            </StyledField>
                            <StyledField>
                                <Label>Password</Label>
                                <Input type={"password"} name={"password"} onChange={updateField}/>
                            </StyledField>
                            <Button
                                onClick={authenticate}
                                isStretched
                                isPrimary={!hasAuthErrors && !isLoading}
                                isDanger={hasAuthErrors && !isLoading}
                                disabled={isLoading}
                            >
                                {getButtonContent()}
                            </Button>
                        </Form>
                    </Col>
                </Row>

            </Grid>
        </Page>
    )
}

const Title = styled(XL)`
  margin-bottom: 30px;
`
const Form = styled.div`

`
const Page = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 10px
`
const StyledField = styled(Field)`
  margin-bottom: 15px;
  Input {
  font-size: 16px;
  }
`

