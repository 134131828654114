import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React, {ChangeEvent, useContext, useState} from "react";
import {Donation} from "../domain";
import {CaptainScopeContext} from "../context/captain-scope.context";
import {FormField} from "./form-field.c";
import {Space} from "./space";


type Props = {
    donation: Donation
    onDone?: (donation: Donation) => void
    onClose?: () => void
}

export const ExceptionResolutionDonationModal = (props: Props) => {
    const {onClose, donation} = props;

    const {actions} = useContext(CaptainScopeContext);
    const [exceptionMeta, setExceptionMeta] = useState<string>('')
    const onExceptionReasonChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setExceptionMeta(e.target.value)
    }

    const markAsException = () => {
        donation.eventType = `donation_exception_resolved_by_captain`;
        donation.isException = 'no'
        donation.exceptionResolutionMeta = exceptionMeta;
        actions.updateDonation(donation).then(onClose);
    }

    return (
        <Modal onClose={onClose}>
            <Header>Resolve exception</Header>
            <Body>
                Optionally provide information about the resolution.
                <Space size={10}/>
                <FormField label={'Exception resolution info'} name={'exceptionMeta'} onChange={onExceptionReasonChanged}/>
            </Body>
            <Footer>
                <FooterItem>
                    <Button isPrimary
                            style={{marginRight: 20}}
                            onClick={markAsException}>
                        Resolve exception
                    </Button>
                    <Button onClick={onClose} isDanger>
                        Close
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal"/>
        </Modal>
    )
}