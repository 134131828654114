import {BaseContainer} from "../base.container";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {FormField, Space, TableComponent} from "../../components";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Title, Well} from "@zendeskgarden/react-notifications";
import {Donation} from "../../domain";
import {field} from "../../utility/field";
import moment from 'moment';
import {DatePicker} from "../../components/date-picker.c";
import {Label} from "@zendeskgarden/react-forms";
import {Button} from "@zendeskgarden/react-buttons";
import styled from "styled-components";
import {TplScopeContext} from "../../context/tpl-scope.context";

type Filter = {
	charity: string,
	donor: string
}
export const DonationsFunnelContainer = () => {
	const {trucks, donations, actions, meta} = useContext(TplScopeContext);

	const [localFilter, setLocalFilter] = useState<Filter>({charity: '', donor: ''});
	const [dateFilter, setDateFilter] = useState<string | undefined>(moment(new Date()).format('MM-DD-YYYY'))

	const [matchedDonations, setMatchedDonations] = useState<Donation[]>(donations);

	const openDonationDetailsView = (data: Donation) => {
		window.open(`/donations/${data!.id}`)
	}

	const onDonationIdClicked = () => {
	}

	const fields = [
		field('donationCode', 'Donation ID', true, onDonationIdClicked),
		field('date', 'Donation date', true),
		field('charity.name', 'Charity name'),
		field('donor.name', 'Donor name'),
		field('donor.phone', 'phone'),
		field('donationStatus', 'Status', true),
		field('truckId', 'Truck', true, undefined, (value => trucks.find(t => t.id === value)?.name || 'Unassigned'))
	]


	const clearFilters = () => {
		setDateFilter(undefined);
		setLocalFilter({
			charity: '',
			donor: ''
		});
	}

	const donorNameFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const {value} = e.target
		setLocalFilter({
			...localFilter,
			donor: value
		})
	}

	const charityNameFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const {value} = e.target
		setLocalFilter({
			...localFilter,
			charity: value
		})
	}
	const donationDateFilterChanged = (name: string, date: string | undefined) => {
		setDateFilter(date);
	}

	const donorNameFilter = (donation: Donation): boolean => {
		if (donation.donor.name && localFilter) {
			return donation.donor.name.toLowerCase().includes(localFilter.donor.toLowerCase());
		}
		return true
	}

	const charityNameFilter = (donation: Donation): boolean => {
		if (donation.charity?.name && localFilter) {
			return donation.charity.name.toLowerCase().includes(localFilter.charity.toLowerCase());
		}
		return true
	}

	const showToday = () => {
		setDateFilter(moment(new Date()).format('MM-DD-YYYY'))
	}
	const showTomorrow = () => {
		setDateFilter(moment(new Date()).add(1, 'd').format('MM-DD-YYYY'))
	}

	const applyLocalFilter = () => {
		setMatchedDonations(
			donations
				.filter(donorNameFilter)
				.filter(charityNameFilter)
		)
	}


	useEffect(() => {
		if (dateFilter) {
			actions.getDonations(`date=${dateFilter}`)
		} else {
			actions.getDonations()
		}
	}, [dateFilter])

	useEffect(() => {
		applyLocalFilter();
	}, [donations, localFilter])

	return (
		<BaseContainer
			title={'Service requests'}
			subtitle={'List of all donation pickups assigned to you'}
		>
			<>
				<Well>
					<Row>
						<Col>
							<FormField value={localFilter.charity} label={"Search by charity"} name={"charity"}
									   onChange={charityNameFilterChanged}/>
						</Col>
						<Col>
							<Label>Donation date</Label>
							<DatePicker name={'date'} value={dateFilter} onChange={donationDateFilterChanged}/>
						</Col>
						<Col>
							<FormField value={localFilter.donor} label={"Search by donor"} name={"donor"}
									   onChange={donorNameFilterChanged}/>
						</Col>
					</Row>
					<Row>
						<VCenteredCol>
							<Title>Quick filters</Title>
							<Button size={"small"} onClick={showToday}>Show today</Button>
							<Button size={"small"} onClick={showTomorrow}>Show tomorrow</Button>
						</VCenteredCol>
						<RightAlignedCol>
							<Button size={'small'} isDanger onClick={clearFilters}>Remove all filters</Button>
						</RightAlignedCol>
					</Row>
				</Well>
				<Space size={30}/>
				<Well style={{opacity: meta.loading.isDonationsLoading ? '0.5' : '1.0'}}>
					<TableComponent fields={fields} onRowClicked={openDonationDetailsView} data={matchedDonations}/>
				</Well>
			</>
		</BaseContainer>
	)
}

const RightAlignedCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const VCenteredCol = styled(Col)`
  display: flex;
  align-items: center;

  Button {
    &:first-of-type {
      margin-left: 30px;
      margin-right: 10px;
    }
  }
`
