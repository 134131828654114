import {Charity, Donation, TPLOrganization} from "../domain";
import {createContext} from "react";
import {Truck} from '../domain/Truck';

export type DriverScopeDataLoading = {
    isDonationsLoading: boolean;
    isTrucksLoading: boolean
}

export type DriverScopeContextActionsType = {
    getDonations: (q?: string) => Promise<Donation[]>
    getTrucks: (q?: string) => Promise<Truck[]>

    getDonation: (id: string) => Promise<Donation>
    updateDonation: (data: Donation) => Promise<Donation>

    getCharity: (id: string) => Promise<Charity>

    getPartner: (id: string) => Promise<TPLOrganization>
    updatePartner: (data: TPLOrganization) => Promise<TPLOrganization>

    updateCharityAvailability: (basedOnDonation: Donation) => void
}
export type DriverScopeContextMetaType = {
    loading: DriverScopeDataLoading
}

export type DriverScopeContextType = {
    donations: Donation[]
    setDonations: (donations: Donation[]) => void

    trucks: Truck[]
    setTrucks: (trucks: Truck[]) => void

    selectedTruck: Truck | undefined
    setSelectedTruck: (truck: Truck) => void

    actions: DriverScopeContextActionsType
    meta: DriverScopeContextMetaType
}

export const DriverScopeContext = createContext<DriverScopeContextType>({} as DriverScopeContextType)
