import {Donation} from "../domain";
import {field} from "../utility/field";
import {TableComponent} from "./table.component";
import React from "react";
import moment from "moment";

const getDateFromFloatString = (value: string) => {
  return moment((parseFloat(value) * 1000)).format('MM-DD-yyyy hh:mm A')
}

const fieldConfigurations = {
  exceptions: [
    field('donationCode', 'Donation ID', false, () => {
    }, (value) => value.toUpperCase()),
    field('createdAt', 'Creation Date', true, undefined, getDateFromFloatString),
    field('date', 'Requested date', true),
    field('donor.name', 'Donor'),
    field('partOfDay', 'Time'),
    field('donor.zip', 'Zip'),
    field('donationStatus', 'Status'),
  ],
  full: [
    field('donationCode', 'Donation ID', true, () => {
    }, (value) => value.toUpperCase()),
    field('partOfDay', 'Time'),
    field('donor.name', 'Donor name'),
    field('donor.address', 'Address'),
    field('donor.zip', 'zip'),
    field('donor.phone', 'phone'),
  ],
  compact: [
    field('donationCode', 'Donation ID', true, () => {
    }, (value) => value.toUpperCase()),
    field('date', 'Date'),
    field('partOfDay', 'AM/PM'),
    field('charity.name', 'Charity name'),
    field('isException', 'Exception', true),
  ],
  masterSchedule: [
    field('donationCode', 'Donation ID', true, () => {
    }, (value) => value.toUpperCase()),
    field('date', 'Date'),
    field('partOfDay', 'AM/PM'),
    field('donor.name', 'Donor name'),
    field('isException', 'Exception', true),
    field('spec.additionalInformation', 'Special Instructions', false, undefined, (value) => Boolean(value.trim()) ? "yes" : "no")
  ]
}

type FieldConfiguration = 'exceptions' | 'full' | 'compact' | 'masterSchedule'

type Props = {
  donations: Donation[]
  onDonationSelected?: (donation: Donation) => void
  compact?: boolean
  configuration?: FieldConfiguration
}

export const DonationsTable = (props: Props) => {
  const {
    compact, donations, onDonationSelected = () => {
    }, configuration = 'full'
  } = props;

  let fieldConfiguration = compact ? fieldConfigurations.compact : fieldConfigurations[configuration]

  return (
    <TableComponent fields={fieldConfiguration} onRowClicked={onDonationSelected} data={donations}/>
  )
}
