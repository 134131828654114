import React, {useContext, useEffect, useState} from "react";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Form} from "../../../../components/form";
import {Button} from "@zendeskgarden/react-buttons";
import {Inline} from "@zendeskgarden/react-loaders";
import {useForm} from "react-hook-form";
import {TplOrganization} from "../../../../domain/schema";
import {CaptainScopeContext} from "../../../../context/captain-scope.context";
import {partnerDefaults} from "../partner.defaults";
import {partnerResolver} from "../partner.resolver";

type Props = {
	partner: TplOrganization
	onDone?: (partner: TplOrganization) => void
	onClose?: () => void
}
export const CapacityAdjustmentModal = (props: Props) => {
	const {
		onDone = () => {
		}, onClose = () => {
		},
		partner
	} = props;
	const {resources} = useContext(CaptainScopeContext);
	const [isLoading, setLoading] = useState(false);
	const {control, reset, handleSubmit, formState: {errors}} = useForm<TplOrganization>({
			defaultValues: partnerDefaults.capacityAdjustment,
			resolver: partnerResolver.capacityAdjustment
		}
	)
	const onSubmit = handleSubmit((data) => {
		setLoading(true);
		resources.tpls.update(partner.id, data).then(() => {
			setLoading(false);
			onDone(data);
		})

		console.log(data);
	})

	useEffect(() => {
		reset(partner);
	}, [partner])

	return (
		<Modal onClose={onClose} style={{opacity: isLoading ? 0.5 : 1}}>
			<form onSubmit={onSubmit}>
				<Header>Adjust capacity for {partner.name}</Header>
				<Body>
					<Form.Input control={control}
								type={'number'}
								label={"Capacity adjustment"}
								name={'captainCapacityAdjustment'}
								errors={errors}/>
				</Body>
				<Footer>
					<FooterItem>
						<Button onClick={onClose} isDanger isBasic>
							Cancel
						</Button>
					</FooterItem>
					<FooterItem>
						<Button
							type={'submit'}
							style={{minWidth: 200}}
							disabled={isLoading}
							isPrimary
						>
							{isLoading ? <Inline/> : ('Adjust capacity')}
						</Button>
					</FooterItem>
				</Footer>
			</form>
		</Modal>
	)
}
