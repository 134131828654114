import {BaseContainer} from "./base.container";
import {FlowContainer} from "./flow.container";
import {DonationInformationContainer} from "./donation-information.container";
import {ConfirmDonationContainer} from "./confirm-donation.container";
import {DonationSuccessContainer} from "./donation-success.container";
import {PaymentContainer} from "./payment.container";
import {PaymentOutcomeContainer} from "./payment-outcome.container";
import {HomeContainer} from './home.container';
import {DonationUpdateContainer} from "./donation-update.container";
import {DonationUpdateDateContainer} from "./donation-update-date.container";
import {DonationCancelContainer} from "./donation-cancel.container";
import {PaymentCaptureOutcomeContainer} from "./payment-capture-outcome.container";

export const Donor = {
    HomeContainer,
    BaseContainer,
    FlowContainer,
    DonationInformationContainer,
    ConfirmDonationContainer,
    DonationSuccessContainer,
    PaymentContainer,
    DonationUpdateContainer,
    PaymentCaptureOutcomeContainer,
    PaymentOutcomeContainer,
    DonationUpdateDateContainer,
    DonationCancelContainer
}
