import {Eventful} from "./Eventful";
import {Charity} from "./Charity";
import {Driver} from "./Driver";
import {Donor} from "./Donor";
import {defaultPricing, pricing} from "../utility/pricing";
import {TPLOrganization} from "./TPLOrganization";
import {DonationSpec} from "./DonationSpec";
import moment from "moment";
import {Pricing} from "./Pricing";
import {ItemDescription} from "./schema/item-description";

export enum DonationStatus {
    awaiting_card_on_file = 'awaiting_card_on_file',
    submitted = 'submitted',
    service_request_dispatched = 'service_request_dispatched',
    driver_assigned = 'driver_assigned',
    driver_en_route = 'driver_en_route',
    driver_arrived = 'driver_arrived',
    quote_sent = 'quote_sent',
    payment_successful = 'payment_successful',
    photos_taken = 'photos_taken',
    awaiting_charity_confirmation = 'awaiting_charity_confirmation',
    completed = 'completed',
    canceled = 'canceled',
    hauler_accepted = 'hauler_accepted',
    hauler_declined = 'hauler_declined'
}

export enum DonationItemType {
    xl = 'xl',
    lg = 'lg',
    md = 'md',
    sm = 'sm'
}

export class DonationItem {
    type?: DonationItemType
    photos: string[] = []

    constructor(type: DonationItemType) {
        this.type = type;
    }
}

export class DropOffOutcome {
    acceptedItems: DonationItem[] = []
    pocName?: string
    pocPhone?: string
    pocConfirmed?: boolean
}

export class Donation extends Eventful {
    versionNo: string = '1.0' // TODO: Think about this convention

    id?: string
    donationStatus: DonationStatus = DonationStatus.submitted
    donationCode: string = '';
    cancellationReason?: string = '';
    costEstimate?: number = pricing.base;
    coiRequired?: boolean = false;

    /**
     * Donation Request Fields
     */

    createdAt: string = '';

    private _date?: string      // Specified by the donor
    partOfDay: string = ''    // Specified by the donor
    spec: DonationSpec          // Specified by the donor

    formattedDate: string = ''  // Autogenerated based on date entry

    /** END-OF Donation Request Fields **/

    adjustedSpec: DonationSpec  // Donation specification adjusted by the driver

    timeSlot?: string           // Service request time slot assigned by the scheduler

    content?: DonationItem[]    // Driver created content based on the type and photos
    donor: Donor                // Donor information

    private _charity?: Charity          // Charity that Donor picked / Donation request originated from
    primaryDropOff?: Charity            // Same as charity field unless overwritten by the scheduler
    secondaryDropOff?: Charity          // Inferred from the primaryDropOff unless overwritten by the scheduler

    tplOrganization?: TPLOrganization   // 3PL Organization that is handling donation pickup
    tplOrganizationId?: string

    truckId?: string

    driver?: Driver                     // Driver that is assigned to the pickup
    driverId?: string

    primaryDropOffOutcome?: DropOffOutcome      // What items were accepted at the first charity
    secondaryDropOffOutcome?: DropOffOutcome    // What items were accepted at the second charity

    pricing?: Pricing = new Pricing()

    isException?: string = "no"
    exceptionMeta?: string
    exceptionResolutionMeta?: string
    requestedCharityId?: string
    stripeCustomer?: string

    donationItemsDescription: ItemDescription[] = []

    constructor() {
        super();
        this.donor = new Donor();
        this.spec = new DonationSpec()
        this.adjustedSpec = new DonationSpec()
        this.date = ''
    }

    /**
     * getEstimate(spec: {@link DonationSpec})
     *
     * Calculate the value of the donation pickup estimate based
     * on the specification provided.
     *
     * @public
     * @param spec
     * @param pricing
     * @returns number
     */

    public static getEstimate(spec: DonationSpec, pricing: Pricing): number {
        if (!pricing){
            pricing = defaultPricing;
        }
        pricing.base = parseFloat(pricing.base+"")

        pricing.xl = parseFloat((pricing.xl || defaultPricing.xl)+"")
        pricing.lg = parseFloat((pricing.lg || defaultPricing.lg)+"")
        pricing.md = parseFloat((pricing.md || defaultPricing.md)+"")
        pricing.sm = parseFloat((pricing.sm || defaultPricing.sm)+"")
        pricing.upcharge = parseFloat((pricing.upcharge || defaultPricing.upcharge)+"")




        pricing.largeItems = parseFloat(pricing.largeItems+"")
        pricing.smallItems = parseFloat(pricing.smallItems+"")
        pricing.boxes = parseFloat(pricing.boxes+"")
        pricing.bags = parseFloat(pricing.bags+"")
        pricing.appliances = parseFloat(pricing.appliances+"")
        pricing.hazardous = parseFloat(pricing.hazardous+"")
        pricing.staircases = parseFloat(pricing.staircases+"")
        pricing.disassembly = parseFloat(pricing.disassembly+"")


        const price = pricing.base
            + pricing.xl * (spec.xl || 0)
            + pricing.lg * (spec.lg || 0)
            + pricing.md * (spec.md || 0)
            + pricing.sm * (spec.sm || 0)

            + pricing.largeItems * (spec.largeItems || 0)
            + pricing.smallItems * (spec.smallItems || 0)
            + pricing.boxes * (spec.boxes || 0)
            + pricing.bags * (spec.bags || 0)
            + pricing.appliances * (spec.appliances || 0)
            + pricing.hazardous * (spec.hazardous || 0)
            + pricing.staircases * (spec.staircases || 0)
            + pricing.disassembly * (spec.disassembly || 0)
            + pricing.upcharge * (spec.upcharge || 0)
        return Math.round(price * 100) / 100
    }

    get date(): string | undefined {
        return this._date;
    }

    set date(value: string | undefined) {
        this._date = value;
        if (value) {
            this.formattedDate = moment(value).format('dddd MM/DD/yyyy')
        }
    }

    get charity(): Charity | undefined {
        return this._charity;
    }

    set charity(value: Charity | undefined) {
        this._charity = value;

        // TODO: Either verify that these fields are set during the charity creation or fail here

        /**
         * Define primary and secondary DropOffs
         */
        if (value){
            this.primaryDropOff = value
            this.secondaryDropOff = value.secondaryDropOff
            this.pricing = value.pricing || defaultPricing;
        }
    }
}


