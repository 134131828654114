import React from "react";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {MD, XXL} from "@zendeskgarden/react-typography";
import styled from "styled-components";
import {Button, IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as BackIcon} from '../assets/icons/long-arrow-left-light.svg';
import {useHistory} from "react-router-dom";
import {ExtraButton} from '../utility/extraButton';
import {Footer, FooterItem} from "@zendeskgarden/react-chrome";

type Props = {
    title: string;
    subtitle: string;
    children: any;
    showBackButton?: boolean;
    extraButtons?: ExtraButton[]
    footerButtons?: ExtraButton[]
    innerContainerTopSpacing?: number
}
export const BaseContainer = (props: Props) => {
    const history = useHistory();
    const {title, subtitle, children, extraButtons, footerButtons, showBackButton, innerContainerTopSpacing = 50} = props;

    const renderExtraButtons = () => {
        if (extraButtons) {
            return extraButtons.map(button => (
                <StyledButton key={`button-${button.title}`} onClick={button.onClick} disabled={button.disabled}>
                    {button.title}
                </StyledButton>
            ))
        }
    }

    const renderFooterExtraButtons = () => {
        if (footerButtons){
            return (
                <StyledFooter>
                    {footerButtons.map(button => (
                        <FooterItem key={`button-${button.title}`}>
                            <Button onClick={button.onClick} disabled={button.disabled}>{button.title}</Button>
                        </FooterItem>
                    ))}
                </StyledFooter>
            )
        }
    }

    const goBack = () => {
        if (history.length === 1) {
            window.close();
        } else {
            history.goBack()
        }

    }
    return (
        <>
            <StyledGrid>
                <Row>
                    <HorizontalOrientedColumn>
                        {showBackButton && (
                            <StyledIconButton
                                onClick={goBack}
                                aria-label="square back" isBasic={false} isPill={false}>
                                <BackIcon/>
                            </StyledIconButton>
                        )}
                        <ContainerTitle>
                            <XXL>{title}</XXL>
                            <MD>{subtitle}</MD>
                        </ContainerTitle>
                    </HorizontalOrientedColumn>
                    <Col>
                        <ExtraButtons>
                            {renderExtraButtons()}
                        </ExtraButtons>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InnerContainer style={{marginTop: innerContainerTopSpacing}}>
                            {children}
                        </InnerContainer>
                    </Col>
                </Row>
            </StyledGrid>
            {renderFooterExtraButtons()}
        </>
    )
}

const StyledGrid = styled(Grid)`
  padding-bottom: 100px;
`
const InnerContainer = styled.div`
  //margin-top: 50px;
`
const ContainerTitle = styled.div`
  margin-left: 20px;
`

const HorizontalOrientedColumn = styled(Col)`
  display: flex;
  flex-direction: row;
`
const ExtraButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const StyledButton = styled(Button)`
  margin-left: 20px;
`
const StyledIconButton = styled(IconButton)`
  margin-top: 6px;
`

const StyledFooter = styled(Footer)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`
