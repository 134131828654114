import {BaseContainer} from "../base.container";
import {useContext, useEffect} from "react";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {DonationsTable} from "../../components/donations-table.c";
import {Donation} from "../../domain";


export const ExceptionsContainer = () => {
    const {donations, actions} = useContext(CaptainScopeContext)

    useEffect(() => {
        actions.getDonations("is_exception=yes").then(d => {
            console.log(d);
        })
    }, [])

    const openDonationDetailsView = (data: Donation) => {
        window.open(`/donations/${data!.id}`)
    }

    return (
        <BaseContainer title={"Exceptions"} subtitle={"All donations needing attention"}>
            <DonationsTable donations={donations} onDonationSelected={openDonationDetailsView} configuration={"exceptions"}/>
        </BaseContainer>
    )
}
