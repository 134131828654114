import {Charity, Donation, TPLOrganization} from "../domain";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {Button} from '@zendeskgarden/react-buttons';
import {Body, Close, Footer, FooterItem, Header, Modal} from '@zendeskgarden/react-modals';
import {CaptainScopeContext} from "../context/captain-scope.context";
import {Col, Row} from "@zendeskgarden/react-grid";
import {PartnersTable} from "./partners-table.c";
import {Box} from "./box.c";
import {DonationStatus} from "../domain/Donation";
import {Space} from "./space";
import {FormField} from "./form-field.c";

type Props = {
    donation: Donation;
    charity: Charity | undefined
    onServiceRequestDispatched?: (partner: TPLOrganization) => void;
}
export const PartnerAssignment = (props: Props) => {
    const {actions, partners, zones} = useContext(CaptainScopeContext)
    const {
        charity, donation, onServiceRequestDispatched = () => {
        }
    } = props;
    const [availablePartners, setAvailablePartners] = useState<TPLOrganization[]>([])
    const [selectedPartner, setSelectedPartner] = useState<TPLOrganization>()
    const [selectedPartnerDonations, setSelectedPartnerDonations] = useState<Donation[]>([])
    const [visible, setVisible] = useState(false);

    const [matchedPartners, setMatchedPartners] = useState<TPLOrganization[]>([])

    const [partnerNameFilter, setPartnerNameFilter] = useState<string>('')

    const onPartnerSelected = (partner: TPLOrganization) => {
        setSelectedPartner(partner);
        setVisible(true);
    }
    const deselectPartner = () => {
        setSelectedPartner(undefined);
        setVisible(false);
    }
    const assignPartner = () => {
        if (selectedPartner){
            donation.tplOrganizationId = selectedPartner.id
            donation.donationStatus = DonationStatus.service_request_dispatched
            actions.updateDonation(donation).then(() => {
                onServiceRequestDispatched(selectedPartner);
            })
        }
    }

    const filterPartners = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPartnerNameFilter(e.target.value);
    }

    useEffect(() => {
        if (selectedPartner){
            actions.getDonations(`tpl_organization_id=${selectedPartner.id}&date=${donation.date}`)
                .then(setSelectedPartnerDonations)
        }
    }, [selectedPartner])

    useEffect(() => {
        setMatchedPartners([...partners.filter(partner => {
            return partnerNameFilter.split(' ').reduce((p:boolean,c:string) => {
                return p || partner.name.toLowerCase().includes(c.toLowerCase())
            }, false)
        })])
    }, [partnerNameFilter])


    useEffect(() => {
        if (charity && donation && zones.length > 0 && partners.length > 0) {
            const charityZones = charity.zones;
            const donorZones = zones.filter(zone => zone.zips.includes(donation.donor.zip!))
            const donorZoneIds = donorZones.map(zone => zone.id);

            const targetZones = charityZones.filter(zone => donorZoneIds.includes(zone.id))
            const targetZoneIds = targetZones.map(zone => zone.id);

            const inZonePartners = partners.filter(partner => {
                const partnerZoneIds = partner.zones.map(zone => zone.id);
                return partnerZoneIds.filter(id => targetZoneIds.includes(id)).length > 0
            })
            setAvailablePartners(inZonePartners)
        }
    }, [donation, zones, partners, charity])

    useEffect(() => {
        setMatchedPartners(partners)
    }, [partners])

    return (
        <>
            <Row>
                <Col>
                    <Box>
                        <FormField label={"3PL Name"} name={'name'} onChange={filterPartners}/>
                    </Box>
                    <Space size={10}/>
                    <Box title={"Available partners"}>
                        <PartnersTable partners={matchedPartners} onPartnerSelected={onPartnerSelected}/>
                    </Box>
                </Col>
            </Row>

            {visible && (
                <Modal onClose={deselectPartner}>
                    <Header>Assign this donation to {selectedPartner?.name}?</Header>
                    <Body>
                        This partner has {selectedPartnerDonations.length} donations assigned {donation.date}
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={deselectPartner} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary onClick={assignPartner}>
                                Assign
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </>
    )
}
