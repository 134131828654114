import React, {useContext} from 'react';
import {Redirect, Route} from "react-router-dom";
import {AppContext} from "../context";

export const GuardedRoute: React.FC<{
    component: React.FC;
    path: string;
    exact?: boolean;
}> = (props) => {

    const {isAuthenticated} = useContext(AppContext).meta;
    const condition = isAuthenticated();
    return condition ? (<Route path={props.path} exact={props.exact} component={props.component}/>) :
        (<Redirect to="/access"/>);
};
