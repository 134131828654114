import {BaseContainer} from "./base.container";
import React, {useContext, useEffect, useState} from "react";
import {CharityContext, DonationContext} from "../../context";
import {useHistory, useParams} from "react-router-dom";
import {Donation} from "../../domain";
import styled from "styled-components";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Button} from "@zendeskgarden/react-buttons";
import {
    Dropdown,
    Field as DropdownField, Hint,
    Item,
    Label as DropdownLabel,
    Menu,
    Select
} from '@zendeskgarden/react-dropdowns';
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {XXL} from "@zendeskgarden/react-typography";
import {Space} from "../../components";

const reasonOptions = [
    { label: 'Emergency', value: 'Emergency' },
    { label: 'Used another service', value: 'Used another service' },
    { label: 'Sold items', value: 'Sold items' },
    { label: 'Unaware of Cost', value: 'Unaware of Cost' },
    { label: 'Found Free service', value: 'Found Free service' },
    { label: 'Other', value: 'Other' },
];

export const DonationCancelContainer = () => {
    const history = useHistory();

    const params = useParams<{ donationCode: string }>()
    const {actions, donations} = useContext(DonationContext)
    const {donationCode} = params
    const charityContext = useContext(CharityContext)
    const [donation, setDonation] = useState<Donation>()
    const [selectedItem, setSelectedItem] = useState(reasonOptions[0]);
    const [otherReason, setOtherReason] = useState('')

    useEffect(() => {
        if (donationCode) {
            actions.getAllDonations(`donation_code=${donationCode}`)
        } else {
            window.location.href = "https://resupplyme.com"
        }
    }, [])

    useEffect(() => {
        if (donations && donations.length > 0) {
            const d = donations[0]
            setDonation(d)
        }
    }, [donations])

    const confirmCancellation = () => {
        const d = {
            ...donation,
            donationStatus: "canceled",
            eventType: "donation_canceled_by_donor",
            cancellationReason: selectedItem.value === 'Other' ? otherReason : selectedItem.value
        } as Donation
        actions.updateDonation(d as Donation).then((data) => {
            charityContext.actions.updateAvailability(data)
                .then(() => {
                    window.location.href = "https://resupplyme.com"
                })
        })
    }

    const isSubmissionDisabled = selectedItem.value === 'Other' && otherReason.trim().length < 5

    if (donation) {
        return (
            <Container>
                <Row>
                    <Col>
                        <XXL>Please provide cancellation reason</XXL>
                    </Col>
                </Row>
                <Space size={30}/>
                <Row>
                    <Col>
                        <Field>
                            <Dropdown
                                selectedItem={selectedItem}
                                onSelect={setSelectedItem}
                                downshiftProps={{ itemToString: (item: any) => item && item.label }}
                            >
                                <DropdownField>
                                    <DropdownLabel>Cancellation reason</DropdownLabel>
                                    <Hint>Please choose cancellation reason</Hint>
                                    <Select>{selectedItem.label}</Select>
                                </DropdownField>
                                <Menu>
                                    {reasonOptions.map(option => (
                                        <Item key={option.value} value={option}>
                                            {option.label}
                                        </Item>
                                    ))}
                                </Menu>
                            </Dropdown>
                        </Field>
                    </Col>
                </Row>
                <Space size={10}/>
                {selectedItem.value === "Other" && (
                    <Row>
                        <Col>
                            <Field>
                                <Label>Please describe your reason</Label>
                                <Input onChange={e => setOtherReason(e.target.value)}/>
                            </Field>
                        </Col>
                    </Row>
                )}
                <Space size={30}/>
                <Row>
                    <Col xs={6}>
                        <Button
                            isPill
                            onClick={history.goBack}
                            isStretched
                        >Go back</Button>
                    </Col>
                    <Col xs={6}>
                        <Button
                            isDanger
                            isPrimary
                            isPill
                            isStretched
                            disabled={isSubmissionDisabled}
                            onClick={confirmCancellation}
                        >Confirm</Button>
                    </Col>
                </Row>
            </Container>)
    } else {
        return <></>
    }

}

const Container = styled.div`
    max-width: 665px;
    width: 100%
`
