import {BaseContainer} from "../base.container";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Well} from "@zendeskgarden/react-notifications";
import {Field, Input, Label, Message} from "@zendeskgarden/react-forms";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {LG} from "@zendeskgarden/react-typography";
import {Button} from "@zendeskgarden/react-buttons";
import {Dropdown, Space} from "../../components";
import {useHistory, useParams} from "react-router-dom";
import {User} from "../../domain";
import _ from "lodash";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {extraButton, ExtraButton} from "../../utility/extraButton";

const roles = [
    {
        label: 'Regular',
        value: 'regular'
    },
    {
        label: 'Captain',
        value: 'captain'
    },
    {
        label: 'Scheduler',
        value: 'scheduler'
    },
    {
        label: 'Charity admin',
        value: 'charity_admin'
    }
]

export const CreateUserContainer = () => {
    const [mode, setMode] = useState<string>('new')
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(new User());

    const history = useHistory()
    const params = useParams<{ id: string }>();

    const {actions} = useContext(CaptainScopeContext)

    const {id} = params;

    const isEditMode = () => mode === 'edit';

    useEffect(() => {
        if (id) {
            setMode('edit');
            actions.getUser(id).then(setUser);
        } else {
            setUser(new User());
        }
    }, [])

    const updateUserEntry = (field: string, value: string | boolean) => {
        const u = new User();
        Object.assign(u, user);
        _.set(u, field, value);
        setUser(u);
    }
    const updateField = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value, name} = e.target;
        updateUserEntry(name, value)
    }

    const submitUser = () => {
        setError(false);
        setLoading(true);
        if (isEditMode()) {
            actions.updateUser(user).then(
                _ => history.goBack()
            )
        } else {
            actions.createUser(user).then(
                _ => history.goBack()
            ).catch((error) => {
                setError(true);
                setLoading(false);
            })
        }
    }


    const toggleUserStatus = () => {
        user.active = !user.active
        actions.updateUser(user).then(
            _ => history.goBack()
        )
    }
    const extraButtons: ExtraButton[] = [
        extraButton("Save user", submitUser),
        extraButton(user.active ? "Deactivate user" : 'Activate user', toggleUserStatus),
    ]


    return (
        <BaseContainer showBackButton title={'Create user'}
                       subtitle={'Create a user with a defined role'}
                       extraButtons={extraButtons}
        >
            <Row>
                <Col lg={6}>
                    <Well>
                        <FormTitle>User information</FormTitle>
                        <StyledField>
                            <Label>Name</Label>
                            <Input value={user.details ? user.details.name : ''} name={"details.name"} onChange={updateField}/>
                        </StyledField>
                        <StyledField>
                            <Label>Email</Label>
                            <Input disabled={isEditMode()} value={user.username} name={"username"} onChange={updateField}/>
                            {error && (<Message validation="error">User with this email already exists.</Message>)}
                        </StyledField>
                        <StyledField>
                            <Label>Password</Label>
                            <Input type='password' name={"password"} onChange={updateField}/>
                        </StyledField>
                        <StyledField>
                            <Dropdown
                                label={'Role'}
                                items={roles}
                                onChange={(e) => updateUserEntry('role', e.value)}
                                value={user.role}
                            />
                        </StyledField>

                    </Well>

                    <Space size={30}/>
                    <Well>
                        <FormTitle>User Details</FormTitle>
                        <StyledField>
                            <Label>Phone</Label>
                            <Input value={user.details ? user.details.phone : ''} name={"details.phone"} onChange={updateField}/>
                        </StyledField>
                        <StyledField>
                            <Label>Email</Label>
                            <Input value={user.details ? user.details.email : ''} name={"details.email"} onChange={updateField}/>
                        </StyledField>

                        {user.role === 'driver' && (
                            <StyledField>
                                <Label>3PL Organization</Label>
                                <Row>
                                    <Col xs={10}>
                                        <Input disabled value={user.details ? user.details.tplOrganizationId : ''} name={"details.tplOrganizationId"} onChange={updateField}/>
                                    </Col>
                                    <Col>
                                        <Button>View</Button>
                                    </Col>
                                </Row>
                            </StyledField>
                        )}

                        {user.role === 'charity_admin' && (
                            <StyledField>
                                <Label>Charity</Label>
                                <Row>
                                    <Col xs={10}>
                                        <Input disabled value={user.details ? user.details.charityId : ''} name={"details.charityId"} onChange={updateField}/>
                                    </Col>
                                    <Col>
                                        <Button>View</Button>
                                    </Col>
                                </Row>

                            </StyledField>
                        )}
                    </Well>
                    <Space size={30}/>
                </Col>
            </Row>
        </BaseContainer>
    )
}

const StyledField = styled(Field)`
  margin-bottom: 15px;
`

const FormTitle = styled(LG)`
  margin-bottom: 10px;
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0;
  }
`

const StyledButtonWrapper = styled(StyledField)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  Button {
    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }
`

