import React, {useContext} from 'react';
import {Body, Chrome, Content, Main, Nav, NavItem, NavItemIcon, NavItemText} from '@zendeskgarden/react-chrome';
import {DEFAULT_THEME, PALETTE, ThemeProvider} from '@zendeskgarden/react-theming';

import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import {ReactComponent as ProductIcon} from '../assets/icons/pictogram.svg';
import {ReactComponent as HomeIcon} from '../assets/icons/parachute-box-light.svg';
import {ReactComponent as TrucksIcon} from '../assets/icons/truck-light.svg';
import {ReactComponent as PaymentIcon} from '../assets/icons/usd-square-light.svg';

import styled from "styled-components";
import {DonationContainer, DonationsFunnelContainer, LoginContainer, TrucksContainer} from "../containers/tpl";
import {CaptainHeader} from "../components";
import {captainTheme} from "../themes";
import {AppContext} from "../context";
import {TplScopeProvider} from "../providers/tpl-scope-provider";
import {PaymentsContainer} from "../containers/tpl/payments/payments.container";

const TplApp = () => {
    const {isAuthenticated} = useContext(AppContext).meta;

    const MenuItem = (link: string, Icon: any, label: string) => (
        <StyledLink to={link}>
            <StyledNavItm>
                <NavItemIcon>
                    <Icon style={{color: '#ffffff'}}/>
                </NavItemIcon>
                <NavItemText>{label}</NavItemText>
            </StyledNavItm>
        </StyledLink>
    )

    return (
        <ThemeProvider focusVisibleRef={null} theme={captainTheme as any}>
            {isAuthenticated() ? (
                <Router>
                    <TplScopeProvider>
                        <StyledChrome hue={PALETTE.green["600"]} isFluid>
                            <Body>
                                <CaptainHeader/>
                                <Content>
                                    <StyledNav>
                                        {MenuItem('/', HomeIcon, 'Home')}
                                        {MenuItem('/trucks', TrucksIcon, 'Trucks')}
                                        {MenuItem('/payments', PaymentIcon, 'Payments')}

                                        <NavItem hasBrandmark title="ReSupply">
                                            <NavItemIcon>
                                                <ProductIcon/>
                                            </NavItemIcon>
                                            <NavItemText>ReSupply</NavItemText>
                                        </NavItem>
                                    </StyledNav>
                                    <Main>
                                        <div style={{padding: DEFAULT_THEME.space.lg}}>
                                            <Switch>
                                                <Route exact path={'/'} component={DonationsFunnelContainer}/>
                                                <Route exact path={'/donations/:id'} component={DonationContainer}/>
                                                <Route exact path={'/trucks'} component={TrucksContainer}/>
                                                <Route exact path={'/payments'} component={PaymentsContainer}/>
                                            </Switch>
                                        </div>
                                    </Main>
                                </Content>
                            </Body>
                        </StyledChrome>
                    </TplScopeProvider>
                </Router>
            ) : (
                <Router>
                    <Switch>
                        <Route exact path={'/'} component={LoginContainer}/>
                    </Switch>
                </Router>
            )}
        </ThemeProvider>

    );
}

export default TplApp;

const StyledChrome = styled(Chrome)`
  height: 100vh;
`

const StyledLink = styled(Link)`
  width: 100%;
`

const StyledNavItm = styled(NavItem)`
  width: 100%
`
const StyledNav = styled(Nav)`
`

