//Todo: Move pricing to the server
export const pricing = {

    // OLD PRICING
    largeItems: 24.99,
    smallItems: 9.99,
    boxes: 7.99,
    bags: 4.99,

    appliances: 34.99,      // Unused
    hazardous: 29.99,       // Unused

    // NEW PRICING
    xl:34.99,
    lg:24.99,
    md:9.99,
    sm:7.99,

    // COMMON PRICING
    base: 99.00,
    staircases: 19.99,
    disassembly: 29.99,
    upcharge: 10.00,
}

export const defaultPricing = pricing;
