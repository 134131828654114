import React, {useEffect, useState} from "react";
import {Authorization, AuthRequest, AuthResponse, User} from "../domain";
import {AppContext} from "../context";
import {AuthProvider} from "../services/auth-provider";
import Api, {method} from "../services/api.service";
import {routes} from "../services/api.routes";
import moment from "moment";

type Props = {
    children: React.ReactNode
}

const AUTH_TOKEN = 'rspl_auth_token';
const USER_DATA = 'rspl_user_data';

export const AppProvider = (props: Props) => {
    const [user, setUser] = useState<User>()
    const [authorization, setAuthorization] = useState<Authorization>()


    const saveTokenToLocalStorage = (token: string) => {
        localStorage.setItem(AUTH_TOKEN, token)
    }
    const getTokenFromLocalStorage = () => {
        return localStorage.getItem(AUTH_TOKEN);
    }

    const saveUserToLocalStorage = (user: User) => {
        return localStorage.setItem(USER_DATA, JSON.stringify(user));
    }

    const getUserFromLocalStorage = () => {
        const userData = localStorage.getItem(USER_DATA);
        if (userData) {
            return JSON.parse(userData)
        }
    }

    const isAuthenticated = () => {
        return authorization !== null && authorization !== undefined;
    }

    const logout = () => {
        setAuthorization(undefined);
        setUser(undefined);
        localStorage.clear();
    }

    const trackUserActivity = () => {
        if (user){
            const data = user;
            data.details.lastActive = moment(new Date()).format("MM-DD-YYYY");
            Api.$<User>(routes.users).update(user.username!, data);
        }

    }

    const authenticate = (provider: AuthProvider, data: any): Promise<User> =>  {
        const request = new AuthRequest(data.username, data.password, provider);
        return Api.$<AuthResponse>(routes.authenticate).call(method.post, request)
            .then((response) => {
                const user = new User(response.username, response.role, response.details);
                const token = response.authorization!.token;
                const authorization = new Authorization(token);
                setAuthorization(authorization)
                setUser(user);
                saveTokenToLocalStorage(token);
                saveUserToLocalStorage(user);
                return user;
            }).catch()
    }

    useEffect(() => {
        const token = getTokenFromLocalStorage();
        if (token){
            setAuthorization(new Authorization(token));
            Api.setToken(token)
        }
        const userData = getUserFromLocalStorage();
        if (userData) {
            setUser(new User(userData.username, userData.role, userData.details))
        }
    }, [])

    useEffect(() => {
        if (authorization){
            Api.setToken(authorization.token)
        }
    }, [authorization])

    const actions = {
        authenticate,
        logout,
        trackUserActivity
    }
    const meta = {
        isAuthenticated
    }
    return (
        <AppContext.Provider value={{user, setUser, actions, meta}}>
            {props.children}
        </AppContext.Provider>
    )
}
