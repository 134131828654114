import {FC, HTMLProps} from "react";
import {Title, Well} from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import {ExtraButton} from "../utility/extraButton";
import {Button} from "@zendeskgarden/react-buttons";

type Props = {
    title?: string,
    buttons?: ExtraButton[]
} & HTMLProps<HTMLDivElement>
export const Box: FC<Props> = (props) => {
    const {title, children, buttons, style} = props;
    return (
        <Well style={style}>
            {title && <StyledTitle>{title}</StyledTitle>}
            {children}

            {buttons && (
                <Footer>
                    {buttons.map(button => (
                        <Button isPrimary size={"small"} key={button.title} onClick={button.onClick}>{button.title}</Button>
                    ))}
                </Footer>
            )}
        </Well>
    )
}

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`

const Footer = styled.div`
  margin: 20px -40px -20px;
  height: 50px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
`
