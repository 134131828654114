import React, {useContext} from "react";
import {BaseContainer} from "./base.container";
import {Col, Row} from "@zendeskgarden/react-grid";

import styled from "styled-components";
import {Button} from "@zendeskgarden/react-buttons";
import {DonorContext} from "../../context/donor.context";
import {LG} from "@zendeskgarden/react-typography";
import {Space} from "../../components";
import {PALETTE} from "@zendeskgarden/react-theming";

export const DonationSuccessContainer = () => {
	const {donationData} = useContext(DonorContext)


	return <BaseContainer title={"Congratulations! Your Donation Pick-up has been Scheduled!"}
						  subtitle={`Thank you for using ReSupply`}>
		<ContentWrapper>
			<StyledRow>
				<Col lg={12}>
					<LG>
						Your Confirmation number is: {donationData.donationCode}
					</LG>
					<Space size={60}/>
					<LG>
						We will let you know 24-hours prior to your pick-up which driver will be bringing your donation
						to {donationData.charity?.name}.
					</LG>

					<Space size={50}/>
					<Line/>

					<StyledButton
						onClick={() => {
							window.location.href = "https://resupplyhelp.zendesk.com/hc/en-us";
						}}
						isStretched>Read our FAQ's</StyledButton>
					<Space size={20}/>
					<StyledButton
						onClick={() => {
							window.location.href = "https://resupplyme.com";
						}}
						isStretched>Go to Resupplyme.com</StyledButton>
				</Col>
			</StyledRow>
		</ContentWrapper>
	</BaseContainer>
}

const ContentWrapper = styled.div`
  color: #2c3b64;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${PALETTE.grey["200"]};
`

const StyledRow = styled(Row)`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`
