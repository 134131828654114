import React, {useContext, useEffect, useState} from "react";
import {Donation} from "../domain";
import Api from "../services/api.service";
import {routes} from "../services/api.routes";

import {actions as commonActions} from './actions';
import {useLocation} from "react-router-dom";
import {TplScopeDataLoading} from "../context/tpl-scope.context";
import {AppContext} from "../context";
import {DriverScopeContext} from "../context/driver-scope.context";
import {Truck} from '../domain/Truck';

type Props = {
    children: React.ReactNode
}


export const DriverScopeProvider = (props: Props) => {
    const location = useLocation()
    const {user} = useContext(AppContext)
    const [donations, setDonations] = useState<Donation[]>([])
    const [trucks, setTrucks] = useState<Truck[]>([])
    const [selectedTruck, setSelectedTruck] = useState<Truck>()

    const [loading, setLoading] = useState<TplScopeDataLoading>({
        isDonationsLoading: false,
        isTrucksLoading: false
    })

    const getDonations = (q?: string): Promise<Donation[]> => {
        setLoading({
            ...loading,
            isDonationsLoading: true
        })
        return Api.$<Donation>(routes.donations).getAll(`tpl_organization_id=${user?.details.tplOrganizationId}${q ? '&' + q : ''}`)
            .then(data => {
                setDonations(data);
                setLoading({
                    ...loading,
                    isDonationsLoading: false
                })
                return data;
            })
    }

    const getTrucks = (q?: string): Promise<Truck[]> => {
        setLoading({
            ...loading,
            isTrucksLoading: true
        })
        return Api.$<Truck>(routes.trucks).getAll(`tpl_organization_id=${user?.details.tplOrganizationId}${q ? '&' + q : ''}`)
            .then(data => {
                setTrucks(data);
                setLoading({
                    ...loading,
                    isTrucksLoading: false
                })
                return data;
            })
    }

    const updateCharityAvailability = (basedOnDonation: Donation) =>{
        return Api.$(routes.updateAvailability).create({donationId: basedOnDonation.id})
    }

    const actions = {
        getDonations,
        getTrucks,
        updateCharityAvailability,

        getDonation: commonActions.donations.getAction,
        updateDonation: commonActions.donations.updateAction,

        getCharity: commonActions.charities.getAction,

        getPartner: commonActions.partners.getAction,
        updatePartner: commonActions.partners.updateAction
    }
    const meta = {
        loading
    }

    return (
        <DriverScopeContext.Provider value={
            {
                donations,
                setDonations,

                trucks,
                setTrucks,
                selectedTruck,
                setSelectedTruck,

                actions,
                meta
            }
        }>
            {props.children}
        </DriverScopeContext.Provider>
    )
}
