import React, {useContext, useEffect, useState} from 'react';
import {BaseContainer} from "../base.container";
import {Col, Row} from "@zendeskgarden/react-grid";
import {useHistory, useParams} from "react-router-dom";
import {CharityWidget, DonorWidget, Space} from "../../components";

import {Tab, TabList, TabPanel, Tabs} from '@zendeskgarden/react-tabs';
import {Charity, Donation} from "../../domain";
import {DonationAddressWidget} from "../../components/donation-address-widget.c";
import {EstimateWidget} from "../../components/estimate-widget.c";
import {ServiceRequestTreeWidget} from "../../components/service-request-tree-widget.c";
import {EntityRouteParams} from "../../types";
import {DonationStatus} from "../../domain/Donation";
import {DonationDateWidget} from "../../components/donation-date-widget.c";
import {extraButton, ExtraButton} from '../../utility/extraButton';
import {CancelDonationModal} from '../../components/cancel-donation-modal.c';
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {defaultPricing} from "../../utility/pricing";
import {Alert, Title, Well} from "@zendeskgarden/react-notifications";
import {PartnerAssignment} from "../../components/partner-assignment.c";
import {PartnerWidget} from "../../components/partner-widget.c";
import {CodeBlock, Paragraph, Span} from "@zendeskgarden/react-typography";
import {ExceptionDonationModal} from "../../components/exception-donation-modal.c";
import styled from "styled-components";
import {ExceptionResolutionDonationModal} from "../../components/exception-resolution-donation-modal.c";
import {Box} from "../../components/box.c";
import {HistoryEvent} from "../../domain/schema/historyEvent";
import _ from "lodash";
import moment from "moment";
import {Accordion} from "@zendeskgarden/react-accordions";

export const DonationContainer = () => {
	const history = useHistory();
	const {id} = useParams<EntityRouteParams>()

	const {actions} = useContext(CaptainScopeContext)

	const [selectedTab, setSelectedTab] = useState('general');

	const [donation, setDonation] = useState<Donation>()
	const [charity, setCharity] = useState<Charity>()
	const [requestedCharity, setRequestedCharity] = useState<Charity>()

	const [isInCharityZone, setIsInCharityZone] = useState<boolean>()
	const [charityZoneCalculating, setCharityZoneCalculating] = useState<boolean>(true)

	const [cancelDonationModalVisible, setCancelDonationModalVisible] = useState<boolean>(false);
	const [exceptionModalVisible, setExceptionModalVisible] = useState<boolean>(false);
	const [exceptionResolutionModalVisible, setExceptionResolutionModalVisible] = useState<boolean>(false);


	const [logItems, setLogItems] = useState<HistoryEvent<Donation>[]>([])

	const openEditDonation = () => history.push(`/edit-donation/${donation!.id}`);
	const openCancelDonationModal = () => setCancelDonationModalVisible(true)
	const closeCancelDonationModal = () => setCancelDonationModalVisible(false);
	const openExceptionModal = () => setExceptionModalVisible(true);
	const openExceptionResolutionModal = () => setExceptionResolutionModalVisible(true);

	const unassignPartner = () => {
		donation!.tplOrganizationId = '';
		donation!.donationStatus = DonationStatus.submitted;
		actions.updateDonation(donation!).then(setDonation)
	}

	const markAsAccepted = () => {
		donation!.donationStatus = DonationStatus.hauler_accepted;
		donation!.eventType = 'hauler_accepted';
		actions.updateDonation(donation!).then(setDonation)
	}

	const markAsCompleted = () => {
		donation!.donationStatus = DonationStatus.completed;
		donation!.eventType = 'captain_marked_as_completed';
		actions.updateDonation(donation!).then(setDonation)
	}

	const isCancellationDisabled = () => {
		return donation && (donation.donationStatus === DonationStatus.canceled || donation.donationStatus === DonationStatus.completed)
	}

	const isMarkAsAcceptedDisabled = () => {
		return donation && donation.donationStatus === DonationStatus.hauler_accepted
	}
	const isMarkAsCompletedDisabled = () => {
		return donation && donation.donationStatus === DonationStatus.completed
	}

	const isException = () => {
		return donation && donation.isException === 'yes'
	}

	const onExceptionButtonClicked = () => {
		if (isException()) {
			openExceptionResolutionModal();
		} else {
			openExceptionModal()
		}
	}

	const extraButtons: ExtraButton[] = donation?.tplOrganizationId ? [
		extraButton("Mark as accepted by 3PL", markAsAccepted, isMarkAsAcceptedDisabled()),
		extraButton("Mark as completed", markAsCompleted, isMarkAsCompletedDisabled()),
		extraButton("Unassign 3PL", unassignPartner),
		extraButton(isException() ? "Resolve exception" : "Mark as exception", onExceptionButtonClicked),
		extraButton("Edit Donation", openEditDonation, isCancellationDisabled()),
		extraButton("Cancel Donation", openCancelDonationModal, isCancellationDisabled()),
	] : [
		extraButton(isException() ? "Resolve exception" : "Mark as exception", onExceptionButtonClicked),
		extraButton("Edit Donation", openEditDonation, isCancellationDisabled()),
		extraButton("Cancel Donation", openCancelDonationModal, isCancellationDisabled()),
	]

	useEffect(() => {
		if (id) {
			actions.getDonation(id).then(setDonation);
			actions.getDonationHistory(id).then(setLogItems);
		}
	}, [id])

	useEffect(() => {
		if (donation) {
			actions.getCharity(donation.charity?.id!).then(setCharity);
			if (donation.requestedCharityId) {
				actions.getCharity(donation.requestedCharityId).then(setRequestedCharity);
			}
		}
	}, [donation])

	useEffect(() => {
		if (charity) {
			const calc = charity.zones.reduce((p, c) => {
				return p || c.zips.includes(donation?.donor.zip!)
			}, false)

			setIsInCharityZone(calc)
			setCharityZoneCalculating(false)

		}
	}, [charity])

	const onServiceRequestDispatched = () => {
		actions.getDonation(id).then(setDonation);
	}

	const difference = (object: any, base: any) => {
		function changes(object: any, base: any) {
			return _.transform(object, function (result: any, value: any, key) {
				if (!_.isEqual(value, base[key])) {
					result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
				}
			});
		}

		return changes(object, base);
	}


	const render3PLAssignment = () => (<PartnerAssignment charity={charity} donation={donation!}
														  onServiceRequestDispatched={onServiceRequestDispatched}/>)
	const renderDriverDetails = () => {
		return <Row>
			<Col xs={6}>
				<PartnerWidget title={'Assigned 3PL'} partnerId={donation?.tplOrganizationId!}/>
				<Space size={30}/>
				<Well>
					<StiledTitle>Assigned driver</StiledTitle>
					<Paragraph>Partner did not assign this donation to a driver yet</Paragraph>
				</Well>
			</Col>
		</Row>
	}

	return (donation && donation.primaryDropOff) ? (
		<BaseContainer
			showBackButton
			title={`Request: ${donation.donationCode}`}
			subtitle={'Donation details'}
			extraButtons={extraButtons}>
			<Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
				<TabList>
					<Tab item="general">General</Tab>
					<Tab item="scheduling">Scheduling</Tab>
					<Tab item="history">History</Tab>
				</TabList>
				<TabPanel item="general">
					{donation.isException === 'yes' && (
						<>
							<Row>
								<Col xs={4}>
									<Alert type="info">
										<Title>Exception</Title>
										<Paragraph>This donation is marked as an exception by the system or by the
											captain. Resolve the exception by editing the donation. </Paragraph>
									</Alert>
								</Col>
							</Row>
							<Space size={10}/>
							<Row>
								<Col xs={4}>
									<Alert type="warning">
										<Title>Exception reason</Title>
										<Paragraph>{donation.exceptionMeta}</Paragraph>
									</Alert>
								</Col>
							</Row>
						</>
					)}
					<Space size={10}/>
					<Row>
						<Col xs={4}>
							{charityZoneCalculating ? (
									<Alert type="info">
										<Title>Zone</Title>
										Calculating zone overlap
									</Alert>
								) :
								isInCharityZone ? (
									<Alert type="success">
										<Title>Zone</Title>
										Donation is in the charity zone
									</Alert>
								) : (
									<Alert type="error">
										<Title>Zone Warning</Title>
										Donation is not in the charity zone!
									</Alert>
								)
							}


							<DonorWidget donor={donation.donor} spacing={10}/>
							{donation.isException === 'yes' ? (
								<>
									<CharityWidget
										title={"Requested charity info"}
										charity={requestedCharity || charity || donation.primaryDropOff}
										spacing={10}/>
									<CharityWidget
										title={"Assigned charity info"}
										charity={charity || donation.primaryDropOff}
										spacing={10}/>
								</>
							) : (
								<CharityWidget
									title={"Requested charity info"}
									charity={charity || donation.primaryDropOff}
									spacing={10}/>
							)}
						</Col>
						<Col xs={4}>
							<DonationDateWidget donation={donation} spacing={0}/>
							<DonationAddressWidget donation={donation} spacing={10}/>
							<EstimateWidget spec={donation.spec} pricing={donation.pricing || defaultPricing}
											spacing={10}/>
							<Space size={10}/>
							<Box title={"Donation description"}>
								{donation.donationItemsDescription?.map(d => (
									<div style={{marginBottom: 10}}>
										<Span isBold>{d.itemLabel}</Span>
										<p>{d.itemDescription}</p>
									</div>

								))}
							</Box>
						</Col>
						<Col xs={4}>
							<ServiceRequestTreeWidget donation={donation} spacing={0}/>
						</Col>
					</Row>
				</TabPanel>
				<TabPanel item='scheduling'>
					{donation.tplOrganizationId ? renderDriverDetails() : render3PLAssignment()}
				</TabPanel>
				<TabPanel item="history">
					<Accordion level={3} isBare>
						{_.sortBy(logItems, 'createdAt').map((item, index, array) => (
							<Box style={{marginBottom: 10}} key={item.createdAt}>

								<Accordion.Section>
									<Accordion.Header>
										<div>
											<Span isBold>{item.user || 'system'}</Span>
											<p>{item.eventType}</p>
											<p>{moment(item.createdAt).format('MM/DD/YYYY @ h:mm a ')}</p>
										</div>
									</Accordion.Header>
									<Accordion.Panel>
										<>
											<Space size={20}/>
											<StyledCodeBlock>
												{JSON.stringify(difference(item.eventData, index === 0 ? donation : array[index - 1].eventData), null, 2)}
											</StyledCodeBlock>
										</>
									</Accordion.Panel>
								</Accordion.Section>
							</Box>
						))}
					</Accordion>
				</TabPanel>
			</Tabs>

			{cancelDonationModalVisible && (
				<CancelDonationModal
					donation={donation}
					onClose={closeCancelDonationModal}
				/>
			)}

			{exceptionModalVisible && (
				<ExceptionDonationModal donation={donation} onClose={() => setExceptionModalVisible(false)}/>
			)}
			{exceptionResolutionModalVisible && (
				<ExceptionResolutionDonationModal donation={donation}
												  onClose={() => setExceptionResolutionModalVisible(false)}/>
			)}

		</BaseContainer>

	) : (
		donation === undefined ?
			(
				<>Loading donation data</>
			) :
			(
				<>Faulty donation</>
			)
	)
};

const StiledTitle = styled(Title)`
  margin-bottom: 20px;
`
const StyledCodeBlock = styled(CodeBlock)`
  background: whitesmoke;
  padding: 20px;
  border: 10px;
  overflow: hidden;
`
