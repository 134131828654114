import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React, {ChangeEvent, useContext, useState} from "react";
import {Donation} from "../domain";
import {Field} from '@zendeskgarden/react-forms';
import styled from 'styled-components';
import {CaptainScopeContext} from "../context/captain-scope.context";
import {FormField} from "./form-field.c";
import {Space} from "./space";


type Props = {
    donation: Donation
    onDone?: (donation: Donation) => void
    onClose?: () => void
}

export const ExceptionDonationModal = (props: Props) => {
    const {onClose, donation} = props;

    const {actions} = useContext(CaptainScopeContext);
    const [exceptionMeta, setExceptionMeta] = useState<string>('')
    const onExceptionReasonChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setExceptionMeta(e.target.value)
    }

    const markAsException = () => {
        donation.eventType = `donation_marked_as_exception_by_captain`;
        donation.isException = 'yes'
        donation.exceptionMeta = exceptionMeta;
        actions.updateDonation(donation).then(onClose);
    }

    return (
        <Modal onClose={onClose}>
            <Header>Mark donation as exception</Header>
            <Body>
                Provide a reason for marking this an exception
                <Space size={10}/>
                <FormField label={'Exception reason'} name={'exceptionMeta'} onChange={onExceptionReasonChanged}/>
            </Body>
            <Footer>
                <FooterItem>
                    <Button isPrimary
                            style={{marginRight: 20}}
                            onClick={markAsException}>
                        Mark as exception
                    </Button>
                    <Button onClick={onClose} isDanger>
                        Close
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal"/>
        </Modal>
    )
}

const StyledField = styled(Field)`
  margin-top: 70px;
  margin-bottom: 70px;
`
