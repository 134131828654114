import React from "react";
import {Truck} from "../../../../domain/schema";
import {TableComponent} from "../../../../components";
import {field} from "../../../../utility/field";
import {Box} from "../../../../components/box.c";

type Props = {
	trucks: Truck[]
	onTruckClicked?: (truck: Truck) => void
}

const trucksFields = [
	field('name', 'Name', true),
	field('make', 'Make', true),
	field('model', 'Model'),
	field('size', 'Size'),
	field('type', 'Type'),
	field('licensePlate', 'License Plate'),
]

export const PartnerTrucksTable = (props: Props) => {
	const {trucks, onTruckClicked = () => {}} = props;

	return (
		<Box>
			<TableComponent fields={trucksFields} onRowClicked={onTruckClicked} data={trucks}/>
		</Box>
	)
}
