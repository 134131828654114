import {BaseContainer} from "../base.container";
import React, {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {AutocompleteInput, FormField, TableComponent} from "../../components";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Well} from "@zendeskgarden/react-notifications";
import {Donation} from "../../domain";
import {field} from "../../utility/field";
import {useHistory} from "react-router-dom";
import {extraButton} from "../../utility/extraButton";
import moment from 'moment';
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {DatePicker} from "../../components/date-picker.c";
import {Label} from "@zendeskgarden/react-forms";
import {Button} from "@zendeskgarden/react-buttons";
import styled from "styled-components";

type Filter = {
    charity: string,
    donor: string
}
export const DonationsFunnelContainer = () => {
    const history = useHistory();

    const {donations, charities, actions, meta} = useContext(CaptainScopeContext);

    const [localFilter, setLocalFilter] = useState<Filter>({charity: '', donor: ''});
    const [dateFilter, setDateFilter] = useState<string | undefined>(moment(new Date).format('MM-DD-YYYY'))

    const [matchedDonations, setMatchedDonations] = useState<Donation[]>(donations);

    const openDonationDetailsView = (data: Donation) => {
        window.open(`/donations/${data!.id}`)
    }

    const onDonationIdClicked = (donationCode: string, data: Donation) => {
    }

    const openAddDonation = () => history.push('create-donation');

    const getDateFromFloatString = (value: string) => {
        return moment((parseFloat(value) * 1000)).format('MM-DD-yyyy hh:mm A')
    }

    const fields = [
        field('donationCode', 'Donation ID', true, onDonationIdClicked),
        field('createdAt', 'Creation date', true, undefined, getDateFromFloatString),
        field('date', 'Donation date', true),
        field('charity.name', 'Charity name'),
        field('donor.name', 'Donor name'),
        field('donor.phone', 'phone'),
        field('donationStatus', 'Status', true)
    ]

    const extraButtons = [
        extraButton('Add donation', openAddDonation)
    ]

    const clearFilters = () => {
        setDateFilter(undefined);
        setLocalFilter({
            charity: '',
            donor: ''
        });
    }

    const donorNameFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target
        setLocalFilter({
            ...localFilter,
            donor: value
        })
    }

    const charityNameFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target
        setLocalFilter({
            ...localFilter,
            charity: value
        })
    }
    const donationDateFilterChanged = (name: string, date: string | undefined) => {
        setDateFilter(date);
    }

    const donorNameFilter = (donation: Donation): boolean => {
        if (donation.donor.name && localFilter) {
            return donation.donor.name.toLowerCase().includes(localFilter.donor.toLowerCase());
        }
        return true
    }

    const charityNameFilter = (donation: Donation): boolean => {
        if (donation.charity?.name && localFilter) {
            return donation.charity.name.toLowerCase().includes(localFilter.charity.toLowerCase());
        }
        return true
    }

    const applyLocalFilter = () => {
        setMatchedDonations(
            donations
                .filter(donorNameFilter)
                .filter(charityNameFilter)
        )
    }


    useEffect(() => {
        if (dateFilter){
            actions.getDonations(`date=${dateFilter}`)
        }else{
            actions.getDonations()
        }
    }, [dateFilter])

    useEffect(() => {
        applyLocalFilter();
    }, [donations, localFilter])

    return (
        <BaseContainer
            title={'Donations funnel'}
            subtitle={'List of all donations in the system'}
            extraButtons={extraButtons}
        >
            <>
                <Well>
                    <Row>
                        <Col>
                            <FormField value={localFilter.charity} label={"Search by charity"}  name={"charity"} onChange={charityNameFilterChanged}/>
                        </Col>
                        <Col>
                            <Label>Donation date</Label>
                            <DatePicker name={'date'} value={dateFilter} onChange={donationDateFilterChanged}/>
                        </Col>
                        <Col>
                            <FormField value={localFilter.donor} label={"Search by donor"}  name={"donor"} onChange={donorNameFilterChanged}/>
                        </Col>
                    </Row>
                    <Row>
                        <RightAlignedCol>
                            <Button isDanger onClick={clearFilters}>Remove all filters</Button>
                        </RightAlignedCol>
                    </Row>
                </Well>
                <div style={{height: 30}}/>
                <Well style={{opacity: meta.loading.isDonationsLoading ? '0.5' : '1.0'}}>
                    <TableComponent fields={fields} onRowClicked={openDonationDetailsView} data={matchedDonations}/>
                </Well>
            </>
        </BaseContainer>
    )
}

const RightAlignedCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  Button{
    &:first-of-type{
      margin: 0 10px;
    }
  }
`
