import React from "react";
import {Trailer} from "../../../../domain/schema";
import {TableComponent} from "../../../../components";
import {field} from "../../../../utility/field";
import {Box} from "../../../../components/box.c";

type Props = {
	trailers: Trailer[]
	onTrailerClicked?: (trailer: Trailer) => void
}

const trailersFields = [
	field('name', 'Name', true),
	field('size', 'Size'),
	field('type', 'Type'),
	field('licensePlate', 'License Plate'),
]

export const PartnerTrailersTable = (props: Props) => {
	const {
		trailers, onTrailerClicked = () => {
		}
	} = props;

	return (
		<Box>
			<TableComponent fields={trailersFields} onRowClicked={onTrailerClicked} data={trailers}/>
		</Box>
	)
}
