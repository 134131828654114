import {defaultPricing} from "../utility/pricing";
import {autoserialize} from "cerialize";

export class Pricing {
    @autoserialize
    base: number
    @autoserialize
    largeItems: number
    @autoserialize
    smallItems: number
    @autoserialize
    boxes: number
    @autoserialize
    bags: number
    @autoserialize
    appliances: number
    @autoserialize
    hazardous: number
    @autoserialize
    staircases: number
    @autoserialize
    disassembly: number
    @autoserialize
    upcharge: number

    // NEW PRICING
    @autoserialize
    xl: number
    @autoserialize
    lg: number
    @autoserialize
    md: number
    @autoserialize
    sm: number

    constructor() {
        this.base = defaultPricing.base;

        this.xl = defaultPricing.xl;
        this.lg = defaultPricing.lg;
        this.md = defaultPricing.md;
        this.sm = defaultPricing.sm;

        this.largeItems = defaultPricing.largeItems;
        this.smallItems = defaultPricing.smallItems;
        this.boxes = defaultPricing.boxes;
        this.bags = defaultPricing.bags;

        this.staircases = defaultPricing.staircases;
        this.disassembly = defaultPricing.disassembly;

        this.appliances = defaultPricing.appliances;
        this.hazardous = defaultPricing.hazardous;
        this.upcharge = defaultPricing.upcharge;

    }

    public static OnDeserialized(instance : Pricing, json : any) : void {
        instance.base = parseFloat(json.base);

        instance.xl = parseFloat(json.xl);
        instance.lg = parseFloat(json.lg);
        instance.md = parseFloat(json.md);
        instance.sm = parseFloat(json.sm);

        instance.largeItems = parseFloat(json.large_items);
        instance.smallItems = parseFloat(json.small_items);
        instance.boxes = parseFloat(json.boxes);
        instance.bags = parseFloat(json.bags);
        instance.appliances = parseFloat(json.appliances);
        instance.hazardous = parseFloat(json.hazardous);
        instance.staircases = parseFloat(json.staircases);
        instance.disassembly = parseFloat(json.disassembly);



    }

}
