import React from "react";

import {
	Dropdown as GardenDropdown, Field, Item, Label, Menu, Message, Select,
} from '@zendeskgarden/react-dropdowns';
import { Control, Controller } from 'react-hook-form';
import {Inline} from "@zendeskgarden/react-loaders";

type Props = {
	name: string
	control: Control<any>
	error?: any,
	label: string,
	items: any[],
	resolveItemLabel: (item: any) => string,
	resolveItemValue: (item: any) => string,
	itemKey: (item: any) => string,
}

export const FormDropdown = (props: Props) => {
	const {
		name,
		control,
		error,
		label,
		items,
		resolveItemLabel,
		resolveItemValue,
		itemKey,
	} = props;

	return items.length ? (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<GardenDropdown
					selectedItem={items.find(i => i.value === field.value || i.id === field.value)}
					onSelect={(data) => {
						field.onChange(resolveItemValue(data));
					}}
					downshiftProps={{
						itemToString: (item: any) => {
							return item ? resolveItemLabel(item) : '';
						},
					}}
				>
					<Field style={{ marginBottom: 20 }}>
						<Label>{label}</Label>
						<Select>{resolveItemLabel(items.find(i => i.value === field.value || i.id === field.value))}</Select>
						{error && (<Message validation="error">{error.message}</Message>)}
					</Field>
					<Menu>
						{items.map(item => (
							<Item key={itemKey(item)} value={item}>{resolveItemLabel(item)}</Item>
						))}
					</Menu>
				</GardenDropdown>
			)}
		/>
	) : <Inline />;
};
