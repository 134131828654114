import {BaseContainer} from "./base.container";
import {useContext, useEffect, useState} from "react";
import {DonationContext} from "../../context";
import {useParams} from "react-router-dom";
import {EstimateComponent} from "../../components";
import {Anchor, Button} from "@zendeskgarden/react-buttons";
import {Well} from "@zendeskgarden/react-notifications";
import {SM} from "@zendeskgarden/react-typography";
import Api from "../../services/api.service";
import {donationStatus} from "../../utility/donation-status";
import {Donation} from "../../domain";
import {loadStripe} from "@stripe/stripe-js";
import {Inline} from "@zendeskgarden/react-loaders";
import {routes} from "../../services/api.routes";

const stripeKey = 'pk_live_51IbPzLION1witdQTAnASC69EjzZgS48HlVTFEleX2KSAZEbmng5Mo16WjaMk4LQ0BmXLniqxd90cDzeG5YXuL3vL00pG9KD8Hq';

export const PaymentContainer = () => {
    const {donationCode} = useParams<{ donationCode: string }>()
    const {actions} = useContext(DonationContext)
    const [donation, setDonation] = useState<Donation>()
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (donationCode) {
            actions.getAllDonations(`donation_code=${donationCode}`).then(data =>{
                if (data && data.length > 0){
                    setDonation(data[0]);
                }
            })
        } else {
            window.location.href = "https://resupplyme.com"
        }
    }, [])


    // Todo: this actions should be moved to DonorContext with everything else that should form that context.
    const setupPayment = () => {
        if (donation) {
            setLoading(true);
            Api.$(routes.stripeCheckout).create({
                success_url: `https://donate.resupplyme.com/p/${donation.id}/success`,
                cancel_url: `https://donate.resupplyme.com/p/${donation.id}/error`,
                donation_id: donation.id
            }).then((result: any) => {
                loadStripe(stripeKey, {
                    stripeAccount: result.account
                })
                    .then((value) => {
                        value!.redirectToCheckout({sessionId: result.id})
                        setLoading(false);
                    })

            })
                .catch(_ => {
                    setLoading(false);
                    //Todo: Dispatch this event somewhere and inform the support team about it.
                    console.log("Error");
                })
        }
    }

    const isPaymentCompleted = () => {
        if (donation) {
            return donationStatus.indexOf(donation.donationStatus!) >= donationStatus.indexOf('payment_successful')
        }
        return false
    }

    const getButtonContent = () => {
        if (isLoading) {
            return (<Inline/>)
        }
        if (isPaymentCompleted()) {
            return "Payment Completed"
        }
        return "Continue to payment"
    }

    return (<BaseContainer title={"Confirm the donation"}
                           subtitle={"Please follow the instruction to complete the payment"}>
        <>
            {donation && (
                <>
                    <Well>
                        <EstimateComponent spec={donation.adjustedSpec} pricing={donation.pricing!}/>
                        <Button
                            disabled={isPaymentCompleted() || isLoading}
                            onClick={setupPayment}
                            style={{marginTop: 70}}
                            isStretched>
                            {getButtonContent()}
                        </Button>
                        <SM style={{textAlign: 'center', marginTop: 10}}>By proceeding you agree with ReSupply's <Anchor
                            target="_blank" href={"https://resupplyme.com/utility/terms"}>Terms of Service</Anchor></SM>
                    </Well>
                </>
            )}
        </>
    </BaseContainer>)
}
