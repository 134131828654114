import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {CharityContext, DonationContext} from "../../context";

import {BaseContainer} from "./base.container";
import {Button} from "@zendeskgarden/react-buttons";
import {Well} from "@zendeskgarden/react-notifications";
import {Inline} from "@zendeskgarden/react-loaders";
import {Donation, DonationStatus} from "../../domain/Donation";
import Api from "../../services/api.service";
import {routes} from "../../services/api.routes";
import {loadStripe} from "@stripe/stripe-js";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Space} from "../../components";
import styled from "styled-components";
import {PALETTE} from "@zendeskgarden/react-theming";
import {LG} from "@zendeskgarden/react-typography";

const stripeKey = 'pk_live_51IbPzLION1witdQTAnASC69EjzZgS48HlVTFEleX2KSAZEbmng5Mo16WjaMk4LQ0BmXLniqxd90cDzeG5YXuL3vL00pG9KD8Hq';

export const PaymentOutcomeContainer = () => {
    const params = useParams<{ donationId: string, outcome: string }>();
    const {donationId, outcome} = params;
    const {actions} = useContext(DonationContext)
    const charityContext = useContext(CharityContext)
    const [donation, setDonation] = useState<Donation>()
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (!donationId) {
            proceedToReSupplyWebsite()
        } else {
            actions.getDonation(donationId).then(setDonation);
        }
    }, [])

    useEffect(() => {
        if (donation) {
            if (donation.donationStatus !== DonationStatus.quote_sent) {
                setLoading(false);
                // handleStatusUpdate()
            } else {
                handleStatusUpdate()
            }

        }
    }, [donation])

    const setupPayment = (donation: Donation) => {
        if (donation) {
            Api.$(routes.stripeCheckout).create({
                success_url: `https://donate.resupplyme.com/p/${donation.id}/success`,
                cancel_url: `https://donate.resupplyme.com/p/${donation.id}/error`,
                // success_url: `http://localhost:3000/c/${donation.id}/success`,
                // cancel_url: `http://localhost:3000/c/${donation.id}/error`,
                donation_id: donation.id
            }).then((result: any) => {
                loadStripe(stripeKey, {
                    stripeAccount: result.account
                })
                    .then((value) => {
                        value!.redirectToCheckout({sessionId: result.id})
                    })

            })
                .catch(_ => {
                    //Todo: Dispatch this event somewhere and inform the support team about it.
                    console.log("Error");
                })
        }
    }

    const handleStatusUpdate = () => {
        if (outcome === 'success') {
            actions.updateDonation({
                ...donation,
                donationStatus: DonationStatus.payment_successful,
                // TODO: This is temporary since we want Zapier to be working properly
                eventType: 'donation_created'
            } as Donation).then((data) => {
                charityContext.actions.updateAvailability(data)
                    .then(() => {
                        setLoading(false);
                    })

            })
        }
    }

    const getTitle = () => {
        if (outcome === "success") {
            return "Thank you for using ReSupply!"
        } else {
            return "To complete your donation please please use the button below to finalize the payment"
        }
    }

    const getSubtitle = () => {
        if (outcome === "success") {
            return "Your donation is on it's way to the charity!"
        } else {
            return "Please try again using the button below"
        }
    }

    const tryAgain = () => {
        if (donation) {
            setupPayment(donation);
        }
    }

    const proceedToReSupplyWebsite = () => {
        window.location.href = 'https://resupplyme.com'
    }

    return (
        <>
            {donation && (
                <BaseContainer title={getTitle()} subtitle={getSubtitle()}>
                    <>
                        <StyledRow style={{padding: 10}}>
                            <Col xs={12}>
                                <img style={{margin: 20, maxWidth: 250}} src={donation.charity?.logoUrl}
                                     alt={donation.charity?.name}/>
                                <Space size={10}/>
                            </Col>
                        </StyledRow>

                        {donation.donationStatus === 'submitted' && (
                            <ContentWrapper>
                                <StyledRow>
                                    <Col lg={12}>
                                        <LG>
                                            Your Confirmation number is: {donation.donationCode}
                                        </LG>
                                        <Space size={60}/>
                                        <LG>
                                            We will let you know 24-hours prior to your pick-up which driver will be
                                            bringing your donation to {donation.charity?.name}.
                                        </LG>

                                        <Space size={50}/>
                                        <Line/>

                                        <StyledButton
                                            onClick={() => {
                                                window.location.href = "https://resupplyhelp.zendesk.com/hc/en-us";
                                            }}
                                            isStretched>Read our FAQ's</StyledButton>
                                        <Space size={20}/>
                                        <StyledButton
                                            onClick={() => {
                                                window.location.href = "https://resupplyme.com";
                                            }}
                                            isStretched>Go to Resupplyme.com</StyledButton>
                                        <Space size={20}/>
                                    </Col>
                                </StyledRow>
                            </ContentWrapper>
                        )}

                        {outcome !== 'success' && (
                            <Well>
                                <Button
                                    disabled={isLoading}
                                    onClick={tryAgain}
                                    isStretched
                                    isDanger>
                                    {isLoading ? (<Inline/>) : 'Try again'}
                                </Button>
                            </Well>
                        )}
                    </>
                </BaseContainer>
            )}
        </>
    )
}


const StyledRow = styled(Row)`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`
const ContentWrapper = styled.div`
  color: #2c3b64;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${PALETTE.grey["200"]};
`

const StyledButton = styled(Button)`
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

