import {Charity, Donation, TPLOrganization, User, Zone} from "../domain";
import {createContext} from "react";
import {CharitySlots} from "../domain/CharitySlots";
import {ArchitectResourceService} from "architect-sdk";
import {TplOrganization, Trailer, Truck} from "../domain/schema";
import {HistoryEvent} from "../domain/schema/historyEvent";

export type CaptainScopeDataLoading = {
    isCharitiesLoading: boolean,
    isDonationsLoading: boolean
}

export type CaptainScopeContextActionsType = {
    getDonations: (q?: string) => Promise<Donation[]>
    getCharities: () => Promise<Charity[]>
    getUsers: () => Promise<User[]>
    getPartners: () => Promise<TPLOrganization[]>

    getDonation: (id: string) => Promise<Donation>
    getDonationHistory: (id: string) => Promise<HistoryEvent<Donation>[]>
    createDonation: (data: Donation) => Promise<Donation>
    updateDonation: (data: Donation) => Promise<Donation>

    getCharity: (id: string) => Promise<Charity>
    createCharity: (data: Charity) => Promise<Charity>
    updateCharity: (data: Charity) => Promise<Charity>
    deleteCharity: (data: Charity) => Promise<void>

    getUser: (id: string) => Promise<User>
    createUser: (data: User) => Promise<User>
    updateUser: (data: User) => Promise<User>

    getPartner: (id: string) => Promise<TPLOrganization>
    createPartner: (data: TPLOrganization) => Promise<TPLOrganization>
    updatePartner: (data: TPLOrganization) => Promise<TPLOrganization>

    getSlots: (q: string) => Promise<CharitySlots[]>
    createSlot: (data: CharitySlots) => Promise<CharitySlots>
    updateSlot: (data: CharitySlots) => Promise<CharitySlots>

    getZones: () => Promise<Zone[]>
    getZone: (id: string) => Promise<Zone>
    createZone: (data: Zone) => Promise<Zone>
    updateZone: (data: Zone) => Promise<Zone>

    updateCharityAvailability: (basedOnDonation: Donation) => void

    createTruck: (data: Partial<Truck>) => Promise<Truck>
    updateTruck: (data: Partial<Truck>) => Promise<Truck>
    createTrailer: (data: Partial<Trailer>) => Promise<Trailer>
    updateTrailer: (data: Partial<Trailer>) => Promise<Trailer>

    deleteTruck: (data: Partial<Truck>) => void
    deleteTrailer: (data: Partial<Trailer>) => void
}
export type CaptainScopeContextMetaType = {
    loading: CaptainScopeDataLoading
}

export type CaptainResourcesType = {
    trucks: ArchitectResourceService<Truck>
    trailers: ArchitectResourceService<Trailer>
    tpls: ArchitectResourceService<TplOrganization>
}

export type CaptainScopeContextType = {
    charities: Charity[]
    setCharities: (charities: Charity[]) => void

    donations: Donation[]
    setDonations: (donations: Donation[]) => void

    users: User[] | []
    setUsers: (users: User[]) => void

    partners: TPLOrganization[]
    setPartners: (partners: TPLOrganization[]) => void

    zones: Zone[]

    actions: CaptainScopeContextActionsType
    resources: CaptainResourcesType
    meta: CaptainScopeContextMetaType
}

export const CaptainScopeContext = createContext<CaptainScopeContextType>({} as CaptainScopeContextType)
