import {FC} from "react";
import {Well} from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import _ from "lodash";
import {Donation} from "../domain";
import {DonationStatus} from "../domain/Donation";

type Props = {
    title?: string
    allocated: number
    filled: Donation[]
    onSlotClicked: (donation: Donation) => void
    onSlotAboutToBeRendered?: (index: number) => ItemPropType
}

const MAX = 30;

type ItemPropType = 'filled' | 'empty' | 'blocked' | 'error' | 'warning' | 'assigned' | 'accepted'
type ItemProps = {
    type: ItemPropType
    onClick: () => void
}
const Item: FC<ItemProps> = (props) => {
    const getColor = () => {
        switch (props.type) {
            case "filled":
                return '#5aad53';
            case "empty":
                return '#ececec';
            case "blocked":
                return '#717171'
            case "error":
                return '#ff0000';
            case "warning":
                return "#ffb700"
            case "assigned":
                return "#f659b8"
            case "accepted":
                return "#099bc7"
        }
    }
    return (
        <ItemInner style={{backgroundColor: getColor()}} onClick={props.onClick}/>
    )
}
export const AvailabilityBox: FC<Props> = (props) => {
    const {title, children, allocated, filled, onSlotClicked, onSlotAboutToBeRendered} = props;
    const filledCount = filled ? filled.length : 0
    const itemType = (index: number) => {
        const target = index + 1
        if (target <= filledCount) {
            if (filled[index].isException === 'yes'){
                return 'warning'
            }
            if (Boolean(filled[index].tplOrganizationId && filled[index].donationStatus !== DonationStatus.hauler_accepted)){
                return 'assigned'
            }
            if (Boolean(filled[index].donationStatus === DonationStatus.hauler_accepted)) {
                return 'accepted'
            }
            if (target > allocated) {
                return 'error'
            } else {
                return 'filled'
            }
        }
        if (target > allocated) return 'blocked'

        return 'empty'
    }

    const slotClicked = (index: number) => {
        if (index < filledCount) {
            onSlotClicked(filled[index])
        }
    }

    return (
        <StyledWell>
            {_.times(MAX, (i) => (
                <Item type={itemType(i)} key={i} onClick={() => slotClicked(i)}/>
            ))}
        </StyledWell>
    )
}

const StyledWell = styled(Well)`
  padding: 0;
  height: 80px;
  display: flex;
  border: none;
  border-radius: 8px;
  overflow: hidden;
`
const ItemInner = styled.div`
  height: 100%;
  flex: 1;
  border: 0.25px solid #ffffff;
  cursor: pointer;

  &:hover {
    border: 2px solid #ffffff;
  }
`
