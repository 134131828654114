import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

const capacityAdjustmentSchema = yup.object()
	.shape({
		captainCapacityAdjustment: yup.number().required('This is required field'),
	});

const adminSchema = yup.object()
	.shape({
		username: yup.string().required('Username is required'),
		password: yup.string().required('Password is required'),
	})
export const partnerResolver = {
	capacityAdjustment: yupResolver(capacityAdjustmentSchema),
	admin: yupResolver(adminSchema)
}
