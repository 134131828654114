import {BaseContainer} from "../base.container";
import {Well} from "@zendeskgarden/react-notifications";
import {Dropdown, FormField, Space, TableComponent} from "../../components";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {field} from "../../utility/field";
import {User} from "../../domain";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {Col, Row} from "@zendeskgarden/react-grid";
import {DropdownItem} from "../../components/dropdown.c";

const fields = [
    field('details.name', 'Name'),
    field('username', 'Username', false),
    field('role', 'Role', true),
]

const roles = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'All with Internal access',
        value: 'internal'
    },
    {
        label: 'Captain',
        value: 'captain'
    },
    {
        label: 'Scheduler',
        value: 'scheduler'
    },
    {
        label: 'Charity admin',
        value: 'charity_admin'
    },
    {
        label: 'Driver',
        value: 'driver'
    },
    {
        label: '3PL admin',
        value: 'tpl_admin'
    }
]

type Props = {};
export const UsersContainer = (props: Props) => {
    const {users, actions} = useContext(CaptainScopeContext)
    const [matchedUsers, setMatchedUsers] = useState<User[]>([])

    const [filter, setFilter] = useState({
        text: '',
        roles: ['captain', 'scheduler', 'charity_admin', 'driver']
    })

    const history = useHistory();

    const extraButtons: { title: string, onClick: () => void }[] = [
        {
            title: 'Add a user',
            onClick: () => {
                history.push('create-user')
            }
        }
    ]

    const openUserDetails = (user: User) => {
        history.push(`/edit-user/${user.userId || user.username}`)
    }


    const changeRole = (selection: DropdownItem) => {
        let roles = []
        switch (selection.value) {
            case 'all':
                roles = ['captain', 'scheduler', 'charity_admin', 'driver'];
                break;
            case 'internal':
                roles = ['captain', 'scheduler'];
                break;
            default:
                roles = [selection.value];
        }
        setFilter({
            ...filter,
            roles: roles
        })
    }

    useEffect(() => {
        setMatchedUsers(users);
    }, [users])

    useEffect(() => {
        setMatchedUsers([
            ...users.filter(u =>
                u.username?.toLocaleLowerCase().includes(filter.text.toLocaleLowerCase())
                &&
                filter.roles.includes(u.role!))
        ])
    }, [filter])

    return (
        <BaseContainer title={"Users"} subtitle={"List of all users"} extraButtons={extraButtons}>
            <>
                <Well>
                    <Row>
                        <Col>
                            <FormField
                                placeholder={'Name or username'}
                                label={'Name or username'}
                                name={'filter.name'}
                                onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        text: e.target.value
                                    })
                                }}
                            />
                        </Col>
                        <Col>
                            <Dropdown
                                label={"Role"}
                                items={roles}
                                onChange={changeRole}
                            />
                        </Col>
                    </Row>
                </Well>
                <Space size={30}/>
                <Well>
                    <TableComponent
                        fields={fields}
                        onRowClicked={openUserDetails}
                        data={matchedUsers}
                        rowStyle={((index, row) => {
                            if (!row.active) {
                                return {opacity: 0.3}
                            } else {
                                return {opacity: 1.0}
                            }
                        })}
                    />
                </Well>
            </>

        </BaseContainer>
    )
}
