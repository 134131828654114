import {autoserialize} from "cerialize";

export class AuthRequest {
    @autoserialize
    username: string
    @autoserialize
    password: string
    @autoserialize
    provider: string

    constructor(username?: string, password?: string, provider: string = 'username') {
        this.username = username || '';
        this.password = password || '';
        this.provider = provider;
    }
}

