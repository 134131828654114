import React, {ChangeEvent, useContext, useState} from "react";
import styled from "styled-components";
import {XXXL} from "@zendeskgarden/react-typography";
import {Button} from "@zendeskgarden/react-buttons";
import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import {AppContext} from "../../context";
import {useHistory} from "react-router-dom";
import {Inline} from "@zendeskgarden/react-loaders";
import {FormField, Space} from "../../components";

export const LoginContainer = () => {
    const history = useHistory()
    const {actions} = useContext(AppContext)

    const [formData, setFormData] = useState<any>()
    const [isLoading, setLoading] = useState(false);
    const [hasAuthErrors, setAuthErrors] = useState(false);

    const updateField = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value, name} = e.target;
        setFormData({...formData, [name]: value})
    }

    const goToHome = () => history.replace("/");

    const authenticationSuccessful = () => {
        setAuthErrors(false);
        setLoading(false)
        goToHome();
    }
    const authenticationFailed = () => {
        setAuthErrors(true);
        setLoading(false)
    }
    const authenticate = () => {
        setLoading(true);
        setAuthErrors(true);
        actions.authenticate('username', {...formData})
            .then(authenticationSuccessful)
            .catch(authenticationFailed)
    }

    const getButtonContent = () => {
        if (isLoading) {
            return <Inline/>
        }
        if (hasAuthErrors) {
            return "Credentials do not match. Please try again."
        }
        return "Sign in"
    }

    return (
        <Page>
            <Form>
                <Title isBold>Sign in to ReSupply Logistics Partner Portal</Title>
                <Space size={30}/>
                <FormField label={'Username'} name={'username'} onChange={updateField}/>
                <FormField label={'Password'} name={'password'} onChange={updateField} type={'password'}/>

                <Button
                    onClick={authenticate}
                    isStretched
                    isPrimary={!hasAuthErrors && !isLoading}
                    isDanger={hasAuthErrors && !isLoading}
                    disabled={isLoading}
                >
                    {getButtonContent()}
                </Button>
            </Form>
            <Logo style={{padding: 80}}/>
        </Page>
    )
}

const Title = styled(XXXL)``

const Form = styled.div`
  margin-right: 50px;
  max-width: 400px;
`
const Page = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
