import {BaseContainer} from "../../base.container";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {EntityRouteParams} from "../../../types";
import {Tab, TabList, TabPanel, Tabs} from "@zendeskgarden/react-tabs";
import {extraButton} from "../../../utility/extraButton";
import {navigationRoutes} from "../../../utility/navigationRoutes";
import {DriverCreationModal} from "../../../components/driver-creation-modal.c";
import {Space, TableComponent} from "../../../components";
import {field} from "../../../utility/field";
import {MerchantModal} from "../../../components/merchant-modal.c";
import {CaptainScopeContext} from "../../../context/captain-scope.context";
import {User} from "../../../domain";
import {PartnerGeneralInfo} from "./components/partner.general-info";
import {Layout} from "../../../components/layout";
import {TplOrganization, Trailer, Truck} from "../../../domain/schema";
import {PartnerTrucksTable} from "./components/partner.trucks-table";
import {PartnerTrailersTable} from "./components/partner.trailers-table";
import {Box} from "../../../components/box.c";
import {Span, XXXL} from "@zendeskgarden/react-typography";
import {CapacityAdjustmentModal} from "./modals/capacity-adjustment-modal";
import {TplAdminCreationModal} from "./modals/tpl-admin-creation-modal.c";

export const PartnerContainer = () => {
	const [selectedTab, setSelectedTab] = useState('general')
	const [driverModalVisible, setDriverModalVisible] = useState<boolean>(false);
	const [adminModalVisible, setAdminModalVisible] = useState<boolean>(false);
	const [merchantModalVisible, setMerchantModalVisible] = useState<boolean>(false);
	const [capacityAdjustmentModalVisible, setCapacityAdjustmentModalVisible] = useState<boolean>(false);

	const [partner, setPartner] = useState<TplOrganization>()
	const [drivers, setDrivers] = useState<User[]>([])
	const [trucks, setTrucks] = useState<Truck[]>([])
	const [trailers, setTrailers] = useState<Trailer[]>([])

	const {users, resources} = useContext(CaptainScopeContext)
	const history = useHistory()
	const {id} = useParams<EntityRouteParams>()


	useEffect(() => {
		if (id) {
			resources.tpls.get(id).then(setPartner);
			resources.trucks.search(`tpl_organization_id=${id}`).then(setTrucks)
			resources.trailers.search(`tpl_organization_id=${id}`).then(setTrailers)
			setDrivers([
				...users.filter(u => u.details?.tplOrganizationId === id)
			])
		}
	}, [id, users])




	const toggleAddDriverModal = () => {
		setDriverModalVisible(!driverModalVisible)
	}
	const toggleAddAdminModal = () => {
		setAdminModalVisible(!adminModalVisible)
	}
	const openMerchantModal = () => {
		setMerchantModalVisible(true)
	}
	const closeMerchantModal = () => {
		setMerchantModalVisible(false)
	}
	const openAdjustCapacityModal = () => {
		setCapacityAdjustmentModalVisible(true)
	}
	const closeAdjustCapacityModal = () => {
		setCapacityAdjustmentModalVisible(false)
	}

	const extraButtons = [
		extraButton("Get Merchant URL", () => openMerchantModal()),
		extraButton("Adjust capacity", () => openAdjustCapacityModal()),
		extraButton("Edit 3PL", () => history.push(navigationRoutes.editTPL(partner!))),
		extraButton("Add driver", () => toggleAddDriverModal()),
		extraButton("Add admin", toggleAddAdminModal)
	]

	const fields = [
		field('details.name', 'Name'),
		field('username', 'Username'),
		field('details.email', 'Email'),
		field('details.phone', 'Phone'),
	]

	const availability = useMemo(() => {
		return {
			default: trucks.length * 3 + trailers.length * 2,
			adjustment: partner ? partner.captainCapacityAdjustment/1 : 0,
			total: trucks.length * 3 + trailers.length * 2 + (partner ? partner.captainCapacityAdjustment/1 : 0)
		}
	}, [trucks, trailers, partner])


	return partner ? (
		<BaseContainer
			showBackButton
			title={`${partner.name}`}
			extraButtons={extraButtons}
			subtitle={`Showing profile information for ${partner.name}`}
		>
			<Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
				<TabList>
					<Tab item="general">General</Tab>
					<Tab item="trucks">Trucks</Tab>
					<Tab item="trailers">Trailers</Tab>
					<Tab item="drivers">Driver management</Tab>
				</TabList>

				<TabPanel item="general">
					<Layout.Col>
						<Layout.ThreeCol>
							<Box title="Default availability">
								<XXXL>{availability.default}</XXXL>
								Based on <Span isBold>{trucks.length} truck{trucks.length === 1 ? ' ' : 's'}</Span>
								{' '}
								and <Span isBold>{trailers.length} trailer{trailers.length === 1 ? ' ' : 's'}</Span>
							</Box>
							<Box title="Captain adjustment">
								<XXXL>{partner.captainCapacityAdjustment || 0}</XXXL>
								Additional pickups available
							</Box>
							<Box title={"Total availability"}>
								<XXXL>{availability.total || 0}</XXXL>
								Pickups per day
							</Box>
						</Layout.ThreeCol>
						<Space size={20}/>
						<PartnerGeneralInfo partner={partner}/>
					</Layout.Col>
				</TabPanel>
				<TabPanel item="trucks">
					<Layout.Col>
						<PartnerTrucksTable trucks={trucks}/>
					</Layout.Col>
				</TabPanel>
				<TabPanel item="trailers">
					<Layout.Col>
						<PartnerTrailersTable trailers={trailers}/>
					</Layout.Col>
				</TabPanel>


				<TabPanel item="drivers">
					<TableComponent fields={fields} onRowClicked={(row) => {
						history.push(`/edit-user/${row.username}`)
					}} data={drivers}/>
				</TabPanel>
			</Tabs>
			{driverModalVisible && (
				<DriverCreationModal
					partner={partner}
					onClose={toggleAddDriverModal}
				/>
			)}

			{adminModalVisible && (
				<TplAdminCreationModal
					partner={partner}
					onClose={toggleAddAdminModal}
				/>
			)}

			{merchantModalVisible && (
				<MerchantModal
					partner={partner}
					onClose={closeMerchantModal}
				/>
			)}
			{capacityAdjustmentModalVisible && (
				<CapacityAdjustmentModal
					partner={partner}
					onDone={() => {
						resources.tpls.get(id).then(setPartner)
						closeAdjustCapacityModal();
					}}
					onClose={closeAdjustCapacityModal}
				/>
			)}

		</BaseContainer>
	) : (<></>)
}
