import {TPLOrganization} from "../domain";
import {field} from "../utility/field";
import {TableComponent} from "./table.component";
import React from "react";

const fields = [
    field('name', '3PL name'),
    field('accountManagerName', 'Account manager'),
    field('phone', 'Phone')
]
type Props = {
    partners: TPLOrganization[]
    onPartnerSelected?: (partner: TPLOrganization) => void
}
export const PartnersTable = (props: Props) => {
    const {partners, onPartnerSelected = () => {} } = props
    return (
        <TableComponent fields={fields} onRowClicked={onPartnerSelected} data={partners}/>
    )
}
