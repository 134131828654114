import {getAction} from "./get.action";
import {updateAction} from "./update.action";
import {createAction} from "./create.action";
import {deleteAction} from "./delete.action";

export const charities = {
    getAction,
    updateAction,
    createAction,
    deleteAction
}
