import {DonationSpec} from "../domain/DonationSpec";
import {Row} from "@zendeskgarden/react-grid";
import {Row as TRow, Body, Cell, GroupRow, Table} from "@zendeskgarden/react-tables";
import {MD} from "@zendeskgarden/react-typography";
import {Title, Well} from "@zendeskgarden/react-notifications";
import React from "react";
import styled from "styled-components";
import {Tag} from "@zendeskgarden/react-tags";


type Props = {
    spec: DonationSpec
    title?: string
    spacing?: number
}

export const DonationSpecWidget = (props: Props) => {
    const {spec, title = "Service request info", spacing = 5} = props;

    return (
        <Well style={{marginTop: spacing}}>
            <StiledTitle>{title}</StiledTitle>
            <Row>
                <Table>
                    <Body>
                        <GroupRow>
                            <Cell>Donation items</Cell>
                            <Cell style={{textAlign: "right"}}>Quantity</Cell>
                        </GroupRow>
                        <TRow isReadOnly>
                            <Cell>Large items</Cell>
                            <Cell
                                style={{textAlign: "right"}}>{parseFloat((spec.largeItems || 0) + "")}</Cell>
                        </TRow>
                        <TRow isReadOnly>
                            <Cell>Small items</Cell>
                            <Cell
                                style={{textAlign: "right"}}>{parseFloat((spec.smallItems || 0) + "")}</Cell>
                        </TRow>
                        <TRow isReadOnly>
                            <Cell>Bags</Cell>
                            <Cell
                                style={{textAlign: "right"}}>{parseFloat((spec.bags || 0) + "")}</Cell>
                        </TRow>
                        <TRow isReadOnly>
                            <Cell>Boxes</Cell>
                            <Cell
                                style={{textAlign: "right"}}>{parseFloat((spec.boxes || 0) + "")}</Cell>
                        </TRow>
                        <TRow isReadOnly>
                            <Cell>Staircases</Cell>
                            <Cell
                                style={{textAlign: "right"}}>{parseFloat((spec.staircases || 0) + "")}</Cell>
                        </TRow>
                        <TRow isReadOnly>
                            <Cell>Disassembly</Cell>
                            <Cell
                                style={{textAlign: "right"}}>{parseFloat((spec.disassembly || 0) + "")}</Cell>
                        </TRow>
                    </Body>
                </Table>
            </Row>
        </Well>
    )
}


const StiledTitle = styled(Title)`
  margin-bottom: 20px;
`
