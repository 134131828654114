import {Col, Row} from "@zendeskgarden/react-grid";
import {Title} from "@zendeskgarden/react-notifications";
import {DayPicker, Space} from "../../components";
import {Dropdown, Field, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {Button} from "@zendeskgarden/react-buttons";
import React, {useContext, useEffect, useState} from "react";
import {closedDays} from "../../utility/closedDays";
import moment from "moment";
import {CharityContext, DonationContext} from "../../context";
import {Donation} from "../../domain";
import styled from "styled-components";
import {useHistory, useParams} from "react-router-dom";
import {MD, XXL} from "@zendeskgarden/react-typography";
import {CharitySlots} from "../../domain/CharitySlots";

interface IItem {
	label: string;
	value: string;
}

const items = [
	{label: 'AM', value: 'am'},
	{label: 'PM', value: 'pm'},
];

export const DonationUpdateDateContainer = () => {
	const params = useParams<{ donationCode: string }>()
	const {donationCode} = params

	const history = useHistory()
	const {actions} = useContext(DonationContext)
	const charityContext = useContext(CharityContext)
	const charity = charityContext.charity;
	const charityActions = charityContext.actions;

	const [donation, setDonation] = useState<Donation>()
	const [selectedItem, setSelectedItem] = useState(items[0]);
    const [slots, setSlots] = useState<CharitySlots[]>([])
    const [slotsCalculating, setSlotsCalculating] = useState(false);

    const [partOfDayOptions, setPartOfDayOptions] = useState<IItem[]>(items)

	useEffect(() => {
		if (donationCode) {
			actions.getAllDonations(`donation_code=${donationCode}`).then((donations) => {
				const d = donations[0]
                d.date = '';
			    setDonation(d)
                charityActions.getCharity(d.charity?.id!);
			})
		} else {
			window.location.href = "https://resupplyme.com"
		}
	}, [])

    useEffect(() => {
        if (charity) {
            charityActions.getSlots(charity.id!).then(setSlots)
            const d = new Donation();
            Object.assign(d, donation);
            d.charity = charity;
            setDonation(d);
        }
    }, [charity])

    useEffect(() => {
        setSlotsCalculating(true);

        if (charity && charity.id && donation && donation.date) {
            charityActions.getSlotsMeta(charity.id, donation.date)
                .then((slotsMeta) => {
                    const slot = slots.find(s => s.date === donation.date);
                    const availablePartOfDayOptions = []
                    if (slotsMeta.amAvailable) {
                        availablePartOfDayOptions.push({label: 'AM', value: 'am'})
                    }
                    if (slotsMeta.pmAvailable) {
                        availablePartOfDayOptions.push({label: 'PM', value: 'pm'})
                    }

                    if (slotsMeta.amAvailable && slotsMeta.pmAvailable) {
                        const amRemaining = parseInt(slot?.slotsAm + "") - parseInt(slotsMeta.amCount + "")
                        const pmRemaining = parseInt(slot?.slotsPm + "") - parseInt(slotsMeta.pmCount + "")

                        if (amRemaining > pmRemaining) {
                            setSelectedItem(availablePartOfDayOptions[0])
                        } else {
                            setSelectedItem(availablePartOfDayOptions[1])
                        }
                    } else {
                        if (availablePartOfDayOptions.length === 0) {
                            availablePartOfDayOptions.push({label: 'Not available', value: 'not-available'})
                        }
                        setSelectedItem(availablePartOfDayOptions[0])

                    }

                    setPartOfDayOptions(availablePartOfDayOptions);
                    setSlotsCalculating(false)
                })
        }

    }, [donation?.date])


	const closedDates: Date[] = (charity.closedDates?.map((date) => new Date(date)) as Date[]) || [];
	const disabledDays = [
		...closedDates,
		{
			daysOfWeek: closedDays(charity.daysOfOperation)
		},
		{
			before: moment(new Date()).toDate()
		}
	];

	const updateDonationDate = (name: string, value: string) => {
		const d = new Donation();
		Object.assign(d, donation);
		d.date = value
		setDonation(d);
	}
	useEffect(() => {
		const d = new Donation();
		Object.assign(d, donation);
		d.partOfDay = selectedItem.value
		setDonation(d);
	}, [selectedItem])

	const confirmChange = () => {
		const d = {
			...donation,
			eventType: "donation_rescheduled_by_donor"
		}
		actions.updateDonation(d as Donation).then((data) => {
			charityContext.actions.updateAvailability(data)
				.then(() => {
					window.location.href = "https://resupplyme.com"
				})
		})
	}

	return (
		<Container>
			<Row>
				<Col>
					<XXL>Reschedule your donation</XXL>
					<MD>Pick a new date and reschedule your donation pickup</MD>
				</Col>
			</Row>
            <Space size={30}/>
			<Row>
				<Col>
					<Title>Pick new date</Title>
					<Space size={10}/>
				</Col>
			</Row>
			<Row>
				<Col sm={9} style={{marginBottom: 15}}>
					<DayPicker
						onChange={updateDonationDate}
						placeholder={'Choose a date'}
						numberOfMonths={2}
						name={'date'}
						disabledDays={disabledDays}/>
				</Col>
				<Col sm={3}>
					<Dropdown
						selectedItem={selectedItem}
						onSelect={setSelectedItem}
						downshiftProps={{itemToString: (item: IItem) => item && item.label}}
					>
						<Field>
							<Select disabled={slotsCalculating || !Boolean(donation?.date)}>{selectedItem.label}</Select>
						</Field>
						<Menu>
							{partOfDayOptions.map(option => (
								<Item key={option.value} value={option}>
									{option.label}
								</Item>
							))}
						</Menu>
					</Dropdown>
				</Col>
			</Row>
            <Space size={30}/>
			<Row>
				<Col>
					<Button
						isPill
                        isStretched
						onClick={history.goBack}>Go back</Button>
				</Col>
				<Col>
					<Button
						isPrimary
						isPill
                        isStretched
                        disabled={slotsCalculating || !Boolean(donation?.date)}
						onClick={confirmChange}>Confirm</Button>
				</Col>
			</Row>

		</Container>
	)
}

const Container = styled.div`
  max-width: 665px;
  width: 100%
`
const ButtonWrapper = styled.div`
  text-align: center;

  Button {
    width: 300px;
    margin-top: 10px;
  }
`
