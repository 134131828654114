import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import styled from "styled-components";

import {Col, Row} from "@zendeskgarden/react-grid";
import {LG} from "@zendeskgarden/react-typography";
import {Field, FileUpload, Hint, Input, Label, Textarea, Toggle} from "@zendeskgarden/react-forms";
import {Well} from "@zendeskgarden/react-notifications";
import {Button} from "@zendeskgarden/react-buttons";

import ReactInputMask from "react-input-mask";

import {BaseContainer} from "../base.container";
import {useDropzone} from "react-dropzone";
import {Charity, Zone} from "../../domain";

import * as _ from 'lodash';
import {AutocompleteField} from "../../components/auto-complete-field";
import {DayString, EntityRouteParams} from "../../types";
import {Datepicker} from '@zendeskgarden/react-datepickers';
import {Space} from '../../components';
import {Tag} from '@zendeskgarden/react-tags';
import {PALETTE} from '@zendeskgarden/react-theming';
import moment from "moment";
import Api from '../../services/api.service';
import NoImagePlaceholder from "../../assets/images/no-img-placeholder.png";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {MultiSelectField} from "../../components/multi-select-field";

const weekDays: DayString[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const CreateCharityContainer = () => {
    const history = useHistory()
    const {charities, actions, zones} = useContext(CaptainScopeContext)

    const [charity, setCharity] = useState<Charity>(new Charity());

    const [logoFile, setLogoFile] = useState<File[]>([])
    const [taxReceiptFile, setTaxReceiptFile] = useState<File[]>([])

    // Used if Component is in EDIT mode
    const [mode, setMode] = useState('new')
    const params = useParams<EntityRouteParams>()
    const {id} = params;

    useEffect(() => {
        if (id) {
            setMode('edit')
            actions.getCharity(id).then(setCharity)
        } else {
            setCharity(new Charity());
        }
    }, [])

    const goBack = () => history.goBack();

    const updateCharityEntry = (value: any, name: string) => {
        const c = new Charity();
        Object.assign(c, charity);
        _.set(c, name, value);
        setCharity(c);
    }
    const updateField = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value, name} = e.target;
        updateCharityEntry(value, name);
    }
    const updateDaysOfOperation = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        updateCharityEntry(checked, name)
    }

    const updateZones = (zones: Zone[]) => {
        updateCharityEntry(zones, 'zones')
    }

    const updateSecondaryDropOff = (charity: Charity) => {
        updateCharityEntry(charity, 'secondaryDropOff')
    }

    const createCharity = () => actions.createCharity(charity).then(goBack)
    const updateCharity = () => actions.updateCharity(charity).then(goBack)

    const getTitle = () => mode === 'new' ? 'Create charity' : 'Update charity'

    const resolveZoneName = (value: Zone) => value ? (value.name ? value.name : '') : ''
    const resolveCharityName = (value: Charity) => value ? (value.name ? value.name : '') : ''


    const renderDayToggle = (day: DayString) => (
        <Col key={day}>
            <StyledField>
                <Toggle name={`daysOfOperation.${day}`} checked={charity.daysOfOperation[day]}
                        onChange={updateDaysOfOperation}>
                    <StyledLabel>{day}
                    </StyledLabel>
                </Toggle>
            </StyledField>
        </Col>
    )

    const onDropLogo = React.useCallback((acceptedFiles: File[]) => {
        setLogoFile([...logoFile, ...acceptedFiles]);
    }, []);

    const onDropTaxReceipt = React.useCallback((acceptedFiles: File[]) => {
        setTaxReceiptFile([...taxReceiptFile, ...acceptedFiles]);
    }, []);

    const logoDropzone = useDropzone({
        accept: ['image/jpeg', 'image/png', 'image/gif'],
        onDrop: onDropLogo
    });

    const taxReceiptDropzone = useDropzone({
        accept: ['application/pdf'],
        onDrop: onDropTaxReceipt
    });

    useEffect(() => {
        if (logoFile.length) {
            uploadFile('logoUrl', logoFile[0]);
        }
    }, [logoFile])

    useEffect(() => {
        if (taxReceiptFile.length) {
            uploadFile('taxReceiptPdfUrl', taxReceiptFile[0]);
        }
    }, [taxReceiptFile])

    const uploadFile = (fileType: string, fileToUpload: File) => {
        if (fileToUpload) {
            const formData = new FormData()
            formData.append('file', fileToUpload)

            Api.$('files').upload(formData).then(
                result => {
                    updateCharityEntry(result.url, fileType)
                }
            )
        }
    }

    return charity ? (
        <BaseContainer showBackButton title={getTitle()}
                       subtitle={"Please specify charity information"}>
            <Row style={{marginTop: 10}}>
                <Col xs={12} xl={8}>
                    <Well>
                        <FormTitle>Basic information</FormTitle>
                        <Row>
                            <Col xs={10}>
                                <StyledField>
                                    <Label>Charity name</Label>
                                    <Input
                                        name={"name"}
                                        onChange={updateField}
                                        value={charity.name}
                                    />
                                </StyledField>
                            </Col>
                            <Col>
                                <StyledField>
                                    <Label>CODE</Label>
                                    <Input
                                        type={"number"}
                                        name={"code"}
                                        onChange={updateField}
                                        value={charity.code}
                                    />
                                </StyledField>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StyledField>
                                    <Label>EIN</Label>
                                    <ReactInputMask
                                        mask={'99-9999999'}
                                        name={"ein"}
                                        onChange={updateField}
                                        value={charity.ein}
                                    >
                                        <Input/>
                                    </ReactInputMask>
                                </StyledField>
                            </Col>
                        </Row>

                        <StyledField>
                            <Row>
                                <Col sm={6}>
                                    <Label>Charity logo</Label>
                                    <Hint>
                                        Drag the photo from your computer or click here to pick. Acceptable formats are JPG and
                                        PNG.
                                    </Hint>
                                    <FileUpload {...logoDropzone.getRootProps()} isDragging={logoDropzone.isDragActive}>
                                        {logoDropzone.isDragActive ? (
                                            <span>Drop files here</span>
                                        ) : (
                                            <span>Choose a file or drag and drop here</span>
                                        )}
                                        <Input {...logoDropzone.getInputProps()} />
                                    </FileUpload>
                                </Col>
                                <Col sm={6}>
                                    <Label>Logo preview</Label>
                                    <Hint>
                                        This image will be shown on the top of the charity's donation webflow, on the Tax Receipt etc.
                                    </Hint>
                                    <Space size={8}/>
                                    <Well style={{textAlign: 'center'}}>
                                        <img src={charity.logoUrl ? charity.logoUrl : NoImagePlaceholder} alt="Logo"/>
                                    </Well>
                                </Col>
                            </Row>
                        </StyledField>
                        <StyledField>
                            <Label>Point of contact</Label>
                            <Input name={"pocName"} value={charity.pocName} onChange={updateField}/>
                        </StyledField>
                        <StyledField>
                            <Label>Phone number</Label>
                            <ReactInputMask mask={'+19999999999'} name={"phone"} value={charity.phone}
                                            onChange={updateField}>
                                <Input/>
                            </ReactInputMask>
                        </StyledField>
                        <StyledField>
                            <Label>Email address</Label>
                            <Input name={"email"} value={charity.email} onChange={updateField}/>
                        </StyledField>
                        <StyledField>
                            <Label>Address</Label>
                            <Input name={"address"} value={charity.address} onChange={updateField}/>
                        </StyledField>
                        <Row>
                            <Col>
                                <StyledField>
                                    <Label>City</Label>
                                    <Input name={"city"} value={charity.city} onChange={updateField}/>
                                </StyledField>
                            </Col>
                            <Col>
                                <StyledField>
                                    <Label>State</Label>
                                    <ReactInputMask mask={'aa'} name={"state"} value={charity.state}
                                                    onChange={updateField}>
                                        <Input/>
                                    </ReactInputMask>
                                </StyledField>
                            </Col>
                            <Col>
                                <StyledField>
                                    <Label>Zip</Label>
                                    <ReactInputMask mask={'99999'} name={"zip"} value={charity.zip}
                                                    onChange={updateField}>
                                        <Input/>
                                    </ReactInputMask>
                                </StyledField>
                            </Col>
                        </Row>

                        <FormTitle>Operations information</FormTitle>
                        <StyledField>
                            <Label>Days of operation</Label>
                        </StyledField>
                        <Row>
                            {weekDays.map(renderDayToggle)}
                        </Row>
                        <StyledField>
                            <ClosedDates name={'closedDates'} onChange={updateCharityEntry} values={charity.closedDates || []}/>
                        </StyledField>
                        <StyledField>
                            <Label>Closing time</Label>
                            <Hint>
                                Enter the time when this store stops accepting pickups at the loading dock
                            </Hint>
                            <Input name={"closingBy"} value={charity.closingBy} onChange={updateField}/>
                        </StyledField>
                        <StyledField>
                            <MultiSelectField
                                label={"Zones"}
                                options={zones}
                                value={charity.zones}
                                valueResolver={resolveZoneName}
                                onValueSelected={updateZones}
                            />
                        </StyledField>
                        <FormTitle>Tax Receipt</FormTitle>
                        <Row>
                            <Col sm={6}>
                                <Label>Tax Receipt Template</Label>
                                <Hint>
                                    Drag the PDF document from your computer or click here to pick.
                                </Hint>
                                <FileUpload style={{padding: 30}} {...taxReceiptDropzone.getRootProps()} isDragging={taxReceiptDropzone.isDragActive}>
                                    {taxReceiptDropzone.isDragActive ? (
                                        <span>Drop file here</span>
                                    ) : (
                                        <span>Choose a file or drag and drop here</span>
                                    )}
                                    <Input {...taxReceiptDropzone.getInputProps()} />
                                </FileUpload>
                            </Col>
                            {charity.taxReceiptPdfUrl ? <Col sm={6}>
                                <Label>Preview</Label>
                                <Hint>
                                    Click on a button bellow to preview the Tax Receipt file.
                                </Hint>
                                <Space size={8}/>
                                <Well style={{textAlign: 'center'}}>
                                    <Button onClick={() => window.open(charity.taxReceiptPdfUrl)}>SHOW TAXRECEIPT FILE</Button>
                                </Well>
                            </Col> : <></>}
                        </Row>
                        <FormTitle>Other</FormTitle>
                        <StyledField>
                            <Label>Notes</Label>
                            <Textarea name={"notes"} value={charity.notes} onChange={updateField}/>
                        </StyledField>
                        <StyledField>
                            <AutocompleteField
                                label={"Secondary Drop-Off location"}
                                options={charities}
                                value={charity.secondaryDropOff}
                                valueResolver={resolveCharityName}
                                onValueSelected={updateSecondaryDropOff}
                            />
                        </StyledField>
                        <StyledField>
                            <Label>Salesforce ID</Label>
                            <Hint>
                                Enter the Account ID displayed at the end of the Salesforce Account URL
                            </Hint>
                            <Input name={"salesforceId"} value={charity.salesforceId} onChange={updateField}/>
                        </StyledField>

                        <StyledField>
                            <Label>Reference ID</Label>
                            <Hint>
                                Enter the Charity ID from the existing system
                            </Hint>
                            <Input name={"refId"} onChange={updateField} value={charity.refId}/>
                        </StyledField>

                        <StyledButtonWrapper>
                            <Button
                                onClick={mode === 'new' ? createCharity : updateCharity}>{mode === 'new' ? 'Create charity' : 'Save Changes'}</Button>
                        </StyledButtonWrapper>

                    </Well>
                </Col>

            </Row>
        </BaseContainer>
    ) : (<></>)
}

type Props = {
    onChange: (value: string[], name: string) => void,
    name: string,
    values: string[];
}

export const ClosedDates = (props: Props) => {
    const {
        onChange,
        name,
        values
    } = props;
    const [dates, setDates] = useState<string[]>(values);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())

    useEffect(() => {
        onChange(dates, name);
    }, [dates])

    useEffect(() => {
        setDates(values);
    }, [values])

    const addDate = () => {
        if (!dates.includes(moment(selectedDate).format('MM-DD-YYYY'))) {
            setDates([...dates, moment(selectedDate).format('MM-DD-YYYY')].sort());
        }
    }

    const removeDate = (index: number) => {
        let closedDates = [...dates]
        closedDates.splice(index, 1);
        setDates(closedDates);
    }

    return (
        <Row>
            <Col sm={12}>
                <StyledField>
                    <Label>Closed days</Label>
                    <Hint>
                        These dates will be disabled for donors to pick in calendar
                    </Hint>
                    <Space size={8}/>
                    <Row>
                        <Col sm={8}>
                            <Datepicker value={selectedDate} onChange={setSelectedDate}>
                                <Input readOnly={true}/>
                            </Datepicker>
                        </Col>
                        <Col sm={4}>
                            <Button
                                onClick={addDate}>Add</Button>
                        </Col>
                    </Row>

                </StyledField>
                <StyledField>
                    <TagContainer>
                        {dates.map((date, index) => (
                            <StyledTag onClick={() => removeDate(index)} key={`${date}-${index}`}>
                                <span>{moment(date).format("MM-DD-YYYY")}</span>
                                <Tag.Close/>
                            </StyledTag>
                        ))}
                    </TagContainer>
                </StyledField>
            </Col>
        </Row>
    );
}


const StyledField = styled(Field)`
  margin-bottom: 15px;
`

const StyledLabel = styled(Label)`
  text-transform: capitalize;
`

const FormTitle = styled(LG)`
  margin-bottom: 10px;
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0;
  }
`
const StyledButtonWrapper = styled(StyledField)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const TagContainer = styled(Well)`
  padding: 5px;
  min-height: 40px;
`
const StyledTag = styled(Tag)`
  margin: 5px;
  transition: all 100ms ease-in-out;
  cursor: pointer !important;
  border: 1px solid transparent;

  &:hover {
    border-color: ${PALETTE.red["600"]};
  }
`
