import {BaseContainer} from "../base.container";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {Well} from "@zendeskgarden/react-notifications";
import {useHistory} from "react-router-dom";
import {extraButton} from "../../utility/extraButton";
import {FormField, Space, TableComponent} from "../../components";
import {Zone} from "../../domain";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {field} from "../../utility/field";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Box} from "../../components/box.c";

const fields = [
    field('name', "Name"),
]


export const ZonesContainer = () => {
    const history = useHistory()
    const {zones} = useContext(CaptainScopeContext)

    const [filter, setFilter] = useState<string>('')
    const [matchedZones, setMatchedZones] = useState<Zone[]>([])

    const openCreateZone = () => history.push('create-zone');
    const openEditZone = (zone: Zone) => history.push(`edit-zone/${zone.id}`)
    const extraButtons = [
        extraButton('Create new zone', openCreateZone)
    ]

    const filterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(e.target.value)
    }

    useEffect(() => {
        setMatchedZones([
            ...zones.filter(zone => zone.name?.toLowerCase().includes(filter.toLowerCase()))
        ])
    }, [zones, filter])

    return (
        <BaseContainer title={"Zone list"} subtitle={"List of all zones"} extraButtons={extraButtons}>
            <>
                <Box>
                    <Row>
                        <Col>
                            <FormField label={"Zone filter"} onChange={filterChanged} name={'charity'}/>
                        </Col>
                    </Row>
                </Box>
                <Space size={10}/>
                <Well>
                    <TableComponent fields={fields} onRowClicked={openEditZone} data={matchedZones}/>
                </Well>
            </>
        </BaseContainer>
    )
}
