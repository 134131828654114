import _ from 'lodash';
import {DaysOfOperation} from '../domain/Charity';

const DaysEnum: {[key: string]: number} = {
	"sun": 0,
	"mon": 1,
	"tue": 2,
	"wed": 3,
	"thu": 4,
	"fri": 5,
	"sat": 6,
}

export const closedDays = (operationDays?: DaysOfOperation) => {
	if (operationDays) {
		const disabledDayNames = _.keys(_.pickBy(operationDays, x => !x));

		return _.map(disabledDayNames, (day: string) => DaysEnum[day]);
	} else {
		return [];
	}
}
