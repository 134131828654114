import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {Inline} from "@zendeskgarden/react-loaders";
import {Paragraph} from "@zendeskgarden/react-typography";
import {Alert, Title} from "@zendeskgarden/react-notifications";
import {Box} from "../../components/box.c";
import {Col, Row} from "@zendeskgarden/react-grid";
import {EstimateComponent, Space} from "../../components";
import {ReactComponent as ReSupplyLogo} from "../../assets/images/logo.svg";
import styled from "styled-components";
import ReactToPrint from 'react-to-print';
import {Button} from "@zendeskgarden/react-buttons";
import {Donation} from "../../domain";

type Params = {
    tplId: string
    date: string
}
export const ServiceRequestContainer = () => {
    const {actions} = useContext(CaptainScopeContext)
    const {tplId, date} = useParams<Params>()
    const history = useHistory();

    const [serviceRequestDonations, setServiceRequestDonations] = useState<Donation[]>()

    const componentRef = useRef(null)

    useEffect(() => {
        if (tplId && date) {
            actions.getDonations(`tpl_organization_id=${tplId}&date=${date}`).then(setServiceRequestDonations)
        }
    }, [tplId, date])

    if (!Boolean(serviceRequestDonations)) {
        return (<Inline/>)
    } else {
        return (
            <>
                <Document>
                    <Page ref={componentRef}>
                        {
                            serviceRequestDonations!.map((donation) => (
                                <>
                                    <ReSupplyLogo width={50} height={50} style={{marginTop: 30}}/>
                                    <Space size={20}/>
                                    <Row>
                                        <PrintableItem xs={12}>
                                            <Box title={donation.donationCode}>
                                                <Space size={5}/>
                                                <Paragraph>
                                                    Donor name: {donation.donor.name}
                                                    <Space size={3}/>
                                                    Donor phone: {donation.donor.phone}
                                                    <Space size={3}/>
                                                    Donor email: {donation.donor.email}
                                                    <Space size={3}/>
                                                    Donor address: {donation.donor.address}
                                                    <Space size={3}/>
                                                    Donor address: {donation.donor.city}
                                                    <Space size={3}/>
                                                    Donor zip: {donation.donor.zip}
                                                    <Space size={3}/>
                                                    Time: {donation.partOfDay.toUpperCase()}
                                                    <Space size={10}/>
                                                    Special instructions:
                                                    <br/>
                                                    {donation.spec.additionalInformation || 'None'}
                                                </Paragraph>
                                                <Space size={20}/>

                                                <Box title={'Estimate'}>
                                                    <EstimateComponent spec={donation.spec}
                                                                       pricing={donation.pricing!}/>
                                                </Box>
                                                <Space size={20}/>
                                                <Alert type={'info'}>
                                                    Must take all donations to primary location first, then alternate
                                                    drop
                                                    points at the driver’s discretion
                                                </Alert>
                                                <Space size={20}/>
                                                <Row>
                                                    <Col>
                                                        <Box>
                                                            <Title>Primary Drop Off</Title>
                                                            <Paragraph>
                                                                {donation.primaryDropOff?.name}
                                                                <Space size={3}/>
                                                                {donation.primaryDropOff?.address} {donation.primaryDropOff?.state} {donation.primaryDropOff?.zip}
                                                            </Paragraph>
                                                        </Box>
                                                    </Col>
                                                    <Col>
                                                        <Box>
                                                            <Title>Secondary Drop Off</Title>
                                                            <Paragraph>
                                                                {donation.secondaryDropOff?.name}
                                                                <Space size={3}/>
                                                                {donation.secondaryDropOff?.address} {donation.secondaryDropOff?.state} {donation.secondaryDropOff?.zip}
                                                            </Paragraph>
                                                        </Box>
                                                    </Col>
                                                </Row>


                                            </Box>
                                        </PrintableItem>
                                    </Row>
                                    <PageBreak>.</PageBreak>
                                </>
                            ))
                        }
                        <Explanations>
                            <StyledTitle>
                                Pricing Chart
                            </StyledTitle>
                            <Paragraph>
                                Use the pricing chart below to charge donors that add/subtract items during the pickup
                                and adjust the above quote accordingly.
                                <Space size={10}/>
                                Base Charge: $99.00
                                <Space size={3}/>
                                Per/Extra Large Item $35.99
                                <Space size={3}/>
                                Per/Large Item: $27.99
                                <Space size={3}/>
                                Per/Medium Item: $10.99
                                <Space size={3}/>
                                Stairs per flight: $19.99
                                <Space size={3}/>
                                Disassembly per Item: $29.99
                                <Space size={3}/>
                                Per/Box or Bag: $7.99

                            </Paragraph>
                            <StyledTitle>
                                What is an Extra Large Item?
                            </StyledTitle>
                            <Paragraph>
                                An extra-large item is a piece of furniture that can be carried (or disassembled to be
                                carried), safely by two or more drivers. Examples of extra-large items are:
                                entertainment centers, sectionals, counter-tops, china cabinets, dining room table for 6
                                of more. All mattresses/box springs and sleeper sofas are charged as extra-large items
                                and there is an upcharge per item added as charities do not accept these items.
                            </Paragraph>
                            <StyledTitle>
                                What is a large item?
                            </StyledTitle>
                            <Paragraph>
                                A large item is a piece of furniture that can be carried (or disassembled to be
                                carried), safely, by two drivers. Examples of large items would be: dressers, dining
                                room tables, couches, upholstered chairs, recliners, desks, entryway tables, love seats,
                                large coffee tables, large area rugs, bookshelves, hutches, armoires, flatscreen or
                                curved televisions over 40’, bureaus, buffets, bed frames, filing cabinets, kitchen
                                cabinets, and countertops.
                            </Paragraph>
                            <StyledTitle>
                                What is a medium item?
                            </StyledTitle>
                            <Paragraph>
                                A medium item is a piece of furniture, accessory, or device that can be carried, safely,
                                by one driver. Examples of a medium item would be: end tables, microwaves, stools,
                                dining room chairs, office chairs, accent chairs, small coffee tables, radios,
                                flatscreen or curved televisions under 40’, Mirrors, lamps and lighting items, art work
                                pieces.
                            </Paragraph>
                            <StyledTitle>
                                What counts as a “staircase”?
                            </StyledTitle>
                            <Paragraph>
                                Stairs, a stairway, a staircase, a stairwell, or a flight of stairs is a construction
                                designed to bridge a large vertical distance by dividing it into smaller vertical
                                distances, called steps. Stairs may be straight, round, or may consist of two or more
                                straight pieces connected at angles.
                            </Paragraph>
                            <StyledTitle>
                                What counts as “disassembly”?
                            </StyledTitle>
                            <Paragraph>
                                Any donation needing tools in order for it to be taken apart.
                            </Paragraph>
                            <StyledTitle>

                                What counts as a box?
                            </StyledTitle>
                            <Paragraph>
                                A box is any item or set of items that is in a cardboard or plastic container that can
                                be carried, safely, by one driver. We do ask that you help us ensure the quality of the
                                donation by checking for any breaks, cracks, or rips in the box prior to the driver’s
                                arrival. Also, if there are glass wares or fragile items, you are more than welcome to
                                wrap the items, mark the box, or even let your driver know! The base price of our
                                service allows for three boxes before any additional charges!
                            </Paragraph>
                            <StyledTitle>
                                What counts as a bag?
                            </StyledTitle>
                            <Paragraph>
                                A bag is any item or set of items that is in a sack, satchel, or bag that can be
                                carried, safely, by one driver. We want to make sure that your donation arrives at the
                                charity in the same condition that it left your home, so please let your driver know if
                                there are any rips or tears in the bag that could harm your donation!
                            </Paragraph>
                            <StyledTitle>
                                Notes:
                            </StyledTitle>
                            <StyledTitle>
                                Donor Communications:
                            </StyledTitle>
                            <Paragraph>
                                Call Donor when you are on the way to the pickup site (Number found on service request)
                                Resupply
                            </Paragraph>

                            <StyledTitle>
                                Resupply Communications:
                            </StyledTitle>
                            <Paragraph>

                                Text Resupply @ 781-355-9453 when the following actions occur:
                                Arrival at donor home- “Arrived @ Donor Name”
                                Drop off at charity- “Dropped Donor Name @ Charity Name”
                                When any issues arise “ Donor Name- Issue encountered”

                            </Paragraph>
                        </Explanations>
                    </Page>
                </Document>

                <Controls>
                    <ReactToPrint
                        trigger={() => {
                            return (<Button isPrimary>Print</Button>)
                        }}
                        content={() => componentRef.current}
                    />
                    <Button isDanger style={{marginLeft: 10}} onClick={history.goBack}>Close</Button>
                </Controls>
            </>
        )
    }

}


const Document = styled.div`
  position: fixed;
  background-color: white;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;
  min-height: 100vh;
  overflow: scroll;
  height: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
`
const Page = styled.div`
  width: 900px;
  padding: 0 50px;
`
const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-top: 20px;
`
const Explanations = styled.div`
  padding: 20px
`

const Controls = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10000;
  padding: 30px;
`

const PrintableItem = styled(Col)`
  margin-bottom: 30px;
`

const PageBreak = styled.p`
  color: white;
  @media print {
    page-break-after: always;
  }
`
