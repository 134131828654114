import {BaseContainer} from "./base.container";
import {Well} from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import {MD, Span, XL, XXL} from "@zendeskgarden/react-typography";
import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "@zendeskgarden/react-grid";
import {useHistory} from "react-router-dom";
import {Skeleton} from "@zendeskgarden/react-loaders";
import {DriverScopeContext} from "../../context/driver-scope.context";
import {extraButton} from "../../utility/extraButton";
import {Space} from '../../components';
import {PALETTE} from '@zendeskgarden/react-theming';
import moment from 'moment';
import {Truck} from '../../domain/Truck';

export const TruckSelectionContainer = () => {
    const history = useHistory()

    const {trucks, actions, meta, setSelectedTruck} = useContext(DriverScopeContext)


    const getTrucks = () => {
        actions.getTrucks()
    }

    const selectTruck = (truck: Truck) => {
        setSelectedTruck(truck);
        history.push('/');
    }

    useEffect(getTrucks, [])

    const renderPlaceholder = () => (
        <Row justifyContent="center">
            <Col sm={5}>
                <XXL>
                    <Skeleton/>
                </XXL>
                <MD>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </MD>
            </Col>
        </Row>
    )

    const extraButtons = [
        extraButton("Refresh", getTrucks)
    ]

    return (
        <BaseContainer title={"Trucks"} subtitle={"Select your truck for today"} extraButtons={extraButtons}>
            <>
                {meta.loading.isTrucksLoading ? (
                    renderPlaceholder()
                ) : (
                    trucks.length === 0 ? (
                        <CenteredRow>
                            <>No trucks available</>
                        </CenteredRow>

                    ) : (
                        <>
                            {trucks.map(truck => (
                                <StyledWell key={truck.id} onClick={() => {
                                    selectTruck(truck)
                                }}>
                                    <StyledWellTop>
                                        <XL>{truck.name}</XL>
                                        <Space size={30}/>
                                        <p>
                                            <Span isBold>Make: </Span>
                                            <Span>{truck.make}</Span>
                                        </p>
                                        <p>
                                            <Span isBold>Model: </Span>
                                            <Span>{truck.model}</Span>
                                        </p>
                                        <p>
                                            <Span isBold>Licencse plate: </Span>
                                            <Span>{truck.licensePlate}</Span>
                                        </p>
                                    </StyledWellTop>
                                </StyledWell>
                            ))}
                        </>
                    )
                )}
            </>

        </BaseContainer>
    )
}

const StyledWellTop = styled.div`
`

const StyledWell = styled(Well)`
  padding: 20px;
  margin-bottom: 10px;
  &:hover {
    background-color: ${PALETTE.grey["200"]};
  }
`

const CenteredRow = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BottomControls = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  margin-top: 10px;
`


const VCenteredCol = styled(Col)`
  display: flex;
  align-items: center;

  Button {
    &:first-of-type {
      margin-left: 30px;
      margin-right: 10px;
    }
  }
`
