import {TplOrganization} from "../../../domain/schema";
import {User} from "../../../domain";

export const partnerDefaults = {
	capacityAdjustment: {captainCapacityAdjustment: 0} as TplOrganization,
	admin: {
		username: '',
		password: '',
		role: 'tpl_admin',
		details: {
			name: '',
			email: '',
			phone: '',
			tplOrganizationId: '',
		}
	} as User
}
