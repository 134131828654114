import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React, {useContext, useMemo, useState} from "react";
import {Truck} from "../../../domain/schema";
import {TplScopeContext} from "../../../context/tpl-scope.context";
import {Dropdown, Space} from "../../../components";

type Props = {
	onDone?: (truck: Truck) => void
	onClose?: () => void
}

export const TruckAssignmentModal = (props: Props) => {
	const {trucks} = useContext(TplScopeContext)
	const [selectedTruckId, setSelectedTruckId] = useState<string>()
	const {
		onDone = () => {
		},
		onClose = () => {
		},
	} = props;

	const onDoneClicked = () => {
		if (selectedTruckId){
			onDone(trucks.find(t => t.id === selectedTruckId)!)
		}
	}

	const items = useMemo(() => { return trucks.map(t => ({value: t.id, label: t.name}))}, [trucks])

	return (
		<Modal onClose={onClose}>
			<Header>Please pick the truck</Header>
			<Body>
				Please select one of the trucks from the list
				<Space size={10}/>
				<Dropdown
					label={"Available trucks"}
					items={items}
					onChange={(item) => setSelectedTruckId(item.value)}
				/>
			</Body>
			<Footer>
				<FooterItem>
					<Button onClick={onClose} isDanger isBasic>
						Cancel
					</Button>
				</FooterItem>
				<FooterItem>
					<Button
						type={'submit'}
						style={{minWidth: 200}}
						isPrimary
						onClick={onDoneClicked}
					>
						Yes
					</Button>
				</FooterItem>
			</Footer>
			<Close aria-label="Close modal"/>
		</Modal>
	)
}
