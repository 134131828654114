import {createContext} from "react";
import {AuthRequest, AuthResponse, User} from "../domain";

export type UserContextType = {
    authData: AuthResponse
    setAuthData: (data: AuthResponse) => void

    user: User
    setUser: (user: User) => void

    users: User[]
    setUsers: (users: User[]) => void

    actions: {
        authenticate: (data: AuthRequest, restrict?: string[]) => Promise<AuthResponse>
        logout: () => void,
        getUserData: () => void,

        getUsers: () => void
        createUser: (user: User) => Promise<User>

        getUser: (id: string) => void
        resetUser: () => void
    }

}

export const UserContext = createContext<UserContextType>(
    {} as UserContextType
)
