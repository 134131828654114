export const trailerSizeOptions = [
	{label: '12ft', value: '12ft'},
	{label: '14ft', value: '14ft'},
	{label: '16ft', value: '16ft'},
	{label: '18ft', value: '18ft'},
	{label: '26ft', value: '26ft'}
]



