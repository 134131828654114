import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React, {useEffect, useMemo, useState} from "react";
import {Inline} from "@zendeskgarden/react-loaders";
import {CaptainScopeContextType} from "../context/captain-scope.context";
import {TplScopeContextType} from "../context/tpl-scope.context";
import {useForm} from "react-hook-form";
import {Trailer} from "../domain/schema";
import {Form} from "./form";
import {Space} from "./space";
import {trailerResolver} from "../containers/tpl/trucks/trailer.resolver";
import {trailerDefaults} from "../containers/tpl/trucks/trailer.defaults";
import {trailerSizeOptions} from "../containers/tpl/trucks/trailer.size.options";
import {trailerTypeOptions} from "../containers/tpl/trucks/trailer.type.options";
import {Paragraph} from "@zendeskgarden/react-typography";
import styled from "styled-components";
import {PALETTE} from "@zendeskgarden/react-theming";

type Props = {
	onDone?: (trailer: Trailer) => void
	onClose?: () => void
	context: CaptainScopeContextType | TplScopeContextType
	tplOrganizationId: string,
	trailer?: Trailer
}

export const TrailerCreationModal = (props: Props) => {
	const {
		onDone = () => {
		}, onClose = () => {
		},
		context,
		trailer,
		tplOrganizationId
	} = props;
	const {actions} = context;

	const [isLoading, setLoading] = useState(false);

	const {control, handleSubmit, reset, setValue, formState: {errors}} = useForm<Trailer>({
			defaultValues: trailerDefaults,
			resolver: trailerResolver
		}
	)

	useEffect(() => {
		if (tplOrganizationId) {
			setValue('tplOrganizationId', tplOrganizationId)
		}
	}, [tplOrganizationId])

	useEffect(() => {
		if (trailer){
			reset(trailer);
		}
	}, [trailer])

	const buttonText = useMemo(() => trailer ? 'Update trailer' : 'Create trailer', [trailer])
	const formTitle = useMemo(() => trailer ? 'Update trailer' : 'Add a trailer', [trailer])

	const onSubmit = handleSubmit((data) => {
		setLoading(true);
		if(trailer){
			actions.updateTrailer(data).then((trailer) => {
				setLoading(false);
				onDone(trailer);
			})
		}else{
			actions.createTrailer(data).then((trailer) => {
				setLoading(false);
				onDone(trailer);
			})
		}
	})

	const onDelete = () =>{
		if (trailer){
			actions.deleteTrailer(trailer);
			onClose();
		}
	}

	return (
		<Modal onClose={onClose} style={{opacity: isLoading ? 0.5 : 1}}>
			<form onSubmit={onSubmit}>
				<Header>{formTitle}</Header>
				<Body>
					Please provide necessary info in order to create/modify a trailer.
					Adding a truck will adjust your daily availability

					<Space size={20}/>
					<Form.Input control={control} label={"Trailer name"} name={'name'} errors={errors}/>
					<Form.Input control={control} label={"License plate"} name={'licensePlate'} errors={errors}/>
					<Form.Dropdown
						control={control}
						label={"Trailer size"}
						name={'size'}
						itemKey={i => i.value}
						resolveItemLabel={i => i?.label}
						resolveItemValue={i => i?.value}
						items={trailerSizeOptions}/>
					<Form.Dropdown
						control={control}
						label={"Is trailer open?"}
						name={'type'}
						itemKey={i => i.value}
						resolveItemLabel={i => i?.label}
						resolveItemValue={i => i?.value}
						items={trailerTypeOptions}/>
				</Body>
				<Footer>
					<FooterItem>
						<Button onClick={onClose} isDanger isBasic>
							Cancel
						</Button>
					</FooterItem>
					<FooterItem>
						<Button
							type={'submit'}
							style={{minWidth: 200}}
							disabled={isLoading}
							isPrimary
						>
							{isLoading ? <Inline/> : buttonText}
						</Button>
					</FooterItem>
				</Footer>
				{trailer && (
					<RedFooter>
						<FooterItem>
							<Paragraph>
								Deleting a truck is not reversible.
							</Paragraph>
						</FooterItem>
						<FooterItem>
							<Button isDanger isPrimary onClick={onDelete}>Delete truck</Button>
						</FooterItem>
					</RedFooter>
				)}
				<Close aria-label="Close modal"/>
			</form>
		</Modal>
	)
}

const RedFooter = styled(Footer)`
  border-top: 1px solid ${PALETTE.crimson.M400};
  padding-bottom: 20px
`
