import {Donation, StaticContent} from "../../domain";
import {BaseContainer} from "./base.container";
import React, {useContext, useEffect, useState} from "react";
import {DonationContext} from "../../context";
import {useHistory, useParams} from "react-router-dom";
import {Button} from "@zendeskgarden/react-buttons";
import {DonationItem, DonationItemType, DonationStatus} from "../../domain/Donation";
import _ from "lodash";
import {LG, Paragraph} from "@zendeskgarden/react-typography";
import {Input} from "@zendeskgarden/react-forms";
import Api from "../../services/api.service";
import {Space} from "../../components";
import imageCompression from 'browser-image-compression';

export const PictureGaleryDonationContainer = () => {
    const history = useHistory();
    const [donation, setDonation] = useState<Donation>()
    const [donationContent, setDonationContent] = useState<DonationItem[]>([])
    const {actions} = useContext(DonationContext)

    const [blocked, setBlocked] = useState(true);
    const [uploading, setUploading] = useState(false);

    const params = useParams<{ id: string }>()
    const {id} = params;

    const createDonationItems = (n: number, type: DonationItemType): DonationItem[] => {
        if (n > 0) {
            return _.times(n, () => new DonationItem(type))
        } else {
            return []
        }
    }

    useEffect(() => {
        actions.getDonation(id).then(setDonation);
    }, [])

    useEffect(() => {
        if (donation) {

            const spec = donation.adjustedSpec;
            const items: DonationItem[] = []
            items.push(...createDonationItems(spec.xl, DonationItemType.xl))
            items.push(...createDonationItems(spec.lg, DonationItemType.lg))
            items.push(...createDonationItems(spec.md, DonationItemType.md))
            items.push(...createDonationItems(spec.sm, DonationItemType.sm))

            setDonationContent(items);
        }
    }, [donation])

    useEffect(() => {
        const picturesAvailable = donationContent.reduce((current,item) => current + item.photos.length, 0)
        setBlocked(picturesAvailable < donationContent.length);
        console.log(donationContent);
    }, [donationContent])

    const progress = () => {
        if(donation){
            donation.content = donationContent;
            donation.donationStatus = DonationStatus.photos_taken;
            donation.eventType = `donation_${DonationStatus.photos_taken}`
            actions.updateDonation(donation!).then(() => {
                history.push(`/donations/${id}/load-up-and-move-out`)
            })
        }
    }


    const uploadToServer = (item: DonationItem, file: File, index: number) => {
        setUploading(true)
        const formData = new FormData();
        formData.append('file', file)
        Api.$<StaticContent>('files').upload(formData).then((result) => {
            item.photos.push(result.url);
            donationContent[index] = item;
            setDonationContent([...donationContent])
            setUploading(false)
        })

    }

    function onFileAdded(item: DonationItem, files: FileList | null, index: number) {
        if (files && files[0]) {
            var imageFile = files[0];
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

            var options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 800,
                useWebWorker: true,
                initialQuality: 0.6
            }
            imageCompression(imageFile, options)
                .then(function (compressedFile: File) {
                    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
                    return uploadToServer(item, compressedFile, index);
                })
                .catch(function (error: any) {
                    console.log(error.message);
                });
        }
    }


    return (
        <BaseContainer title={"Pictures!"} showBackButton showAsModal>
            <>
                {donationContent.map((item, index) => (
                    <div key={index}>
                        <LG>{item.type?.toUpperCase()}</LG>
                        <Space size={10}/>
                        <Input type={'file'} name={index+""} onChange={(e) => {
                            onFileAdded(item, e.target.files, index);
                        }}/>
                        <Space size={20}/>
                    </div>
                ))}
                <Button
                    onClick={progress}
                    // disabled={blocked}
                    isStretched>
                    Save
                </Button>
                <Space size={10}/>
                {blocked && uploading &&(<Paragraph style={{fontStyle: "italic", textAlign: "center"}}>Waiting for the photos to be uploaded...</Paragraph>)}
            </>
        </BaseContainer>
    )
}
