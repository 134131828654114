import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React, {useContext, useEffect, useState} from "react";
import {Donation} from "../domain";
import {DonationStatus} from '../domain/Donation';
import {Field, Input, Label} from '@zendeskgarden/react-forms';
import styled from 'styled-components';
import {
	Dropdown,
	Field as DropdownField,
	Hint,
	Item,
	Label as DropdownLabel,
	Menu,
	Select
} from '@zendeskgarden/react-dropdowns';
import {CaptainScopeContext} from "../context/captain-scope.context";
import {DriverScopeContext} from '../context/driver-scope.context';


type Props = {
	donation: Donation
	onDone?: (donation: Donation) => void
	onClose?: () => void
}

const cancelationResons = [
	{label: 'Donor is not here', value: 'Donor is not here'},
	{label: 'I cannot complete the donation', value: 'I cannot complete the donation'}
];

export const DriverCancelDonationModal = (props: Props) => {
	const {onClose, donation} = props;

	const {actions} = useContext(DriverScopeContext);
	const [selectedItem, setSelectedItem] = useState(cancelationResons[0]);
	const [otherReason, setOtherReason] = useState('')

	const cancelDonation = () => {
		donation.donationStatus = DonationStatus.canceled;
		donation.eventType = `donation_${DonationStatus.canceled}`;
		donation.cancellationReason = selectedItem.value === "I cannot complete the donation" ? otherReason : selectedItem.value;
		actions.updateDonation(donation)
			.then(actions.updateCharityAvailability)
			.then(onClose);
	}

	const isFormSubmissionDisabled = selectedItem.label === "I cannot complete the donation" && otherReason.trim().length < 5;

	return (
		<StyledModal onClose={onClose}>
			<Header>Cancel donation from {donation.donor.name} for {donation.date}</Header>
			<CancellationModalBody>
				<StyledField>
					<Dropdown
						selectedItem={selectedItem}
						onSelect={setSelectedItem}
						downshiftProps={{itemToString: (item: any) => item && item.label}}
					>
						<DropdownField>
							<DropdownLabel>Cancellation reason</DropdownLabel>
							<Hint>Please choose cancellation reason</Hint>
							<Select>{selectedItem.label}</Select>
						</DropdownField>
						<Menu>
							{cancelationResons.map((option: any) => (
								<Item key={option.value} value={option}>
									{option.label}
								</Item>
							))}
						</Menu>
					</Dropdown>
				</StyledField>
                {selectedItem.value === "I cannot complete the donation" && (
                    <Field>
                        <Label>Please provide the reason</Label>
                        <Input onChange={(e) => setOtherReason(e.target.value)}/>
                    </Field>
                )}

			</CancellationModalBody>
			<Footer>
				<FooterItem>
					<Button isPrimary
                            disabled={isFormSubmissionDisabled}
							style={{marginRight: 20}}
							onClick={cancelDonation}>
						Cancel Donation
					</Button>
					<Button onClick={onClose} isDanger>
						Close
					</Button>
				</FooterItem>
			</Footer>
			<Close aria-label="Close modal"/>
		</StyledModal>
	)
}

const StyledModal = styled(Modal)`
  left: auto;
  bottom: auto;
  max-width: 90%;
  * {
  	max-width: 100%;
  }
`

const StyledField = styled(Field)`
  margin-top: 70px;
  margin-bottom: 10px;
`
const CancellationModalBody = styled(Body)`
  min-height: 400px;
`
