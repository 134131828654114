import React, {FC, useContext, useEffect} from "react";
import {DEFAULT_THEME, PALETTE} from "@zendeskgarden/react-theming";
import {Body, Chrome, Content, Main, Nav, NavItem, NavItemIcon, NavItemText} from "@zendeskgarden/react-chrome";
import {CharityHeader} from "../../components";
import {ReactComponent as HomeIcon} from "../../assets/icons/parachute-box-light.svg";
import {ReactComponent as ProductIcon} from "../../assets/icons/pictogram.svg";
import {Link, Route, Switch, useHistory} from "react-router-dom";
import {GuardedRoute} from "../../components/guarded-route.c";
import {Charity} from "./index";
import styled from "styled-components";
import ReactGA from 'react-ga';
import {useIntercom} from 'react-use-intercom';
import {AppContext} from '../../context';



export const HomeContainer: FC = () => {

    const history = useHistory();
    const { boot } = useIntercom();
    const {user} = useContext(AppContext);

    const trackEvent = (location: any) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }
    useEffect(() => {
        trackEvent(history.location);
        history.listen(trackEvent);
        boot({
            name: user?.details.name,
            customAttributes: { charityId: user?.details.charityId }
        });
    }, [])

    return (
        <StyledChrome hue={PALETTE.blue["600"]} isFluid>
            <Body>
                <CharityHeader/>
                <Content>
                    <StyledNav>
                        <StyledLink to={"/"}>
                            <StyledNavItm>
                                <NavItemIcon>
                                    <HomeIcon style={{color: '#ffffff'}}/>
                                </NavItemIcon>
                                <NavItemText>Home</NavItemText>
                            </StyledNavItm>
                        </StyledLink>

                        <NavItem hasBrandmark title="ReSupply">
                            <NavItemIcon>
                                <ProductIcon/>
                            </NavItemIcon>
                            <NavItemText>ReSupply</NavItemText>
                        </NavItem>
                    </StyledNav>
                    <Main>
                        <div style={{padding: DEFAULT_THEME.space.lg}}>
                            <Switch>
                                <GuardedRoute
                                    exact
                                    path={'/'}
                                    component={Charity.DonationsFunnelContainer}/>
                                <Route exact path={'/donations/:id'}>
                                    <Charity.DonationContainer/>
                                </Route>
                            </Switch>
                        </div>
                    </Main>
                </Content>
            </Body>
        </StyledChrome>
    )
}
const StyledChrome = styled(Chrome)`
  height: 100vh;
`

const StyledLink = styled(Link)`
  width: 100%;
`

const StyledNavItm = styled(NavItem)`
  width: 100%
`
const StyledNav = styled(Nav)`
`

