import React, {useContext, useEffect, useState} from "react";
import {BaseContainer} from "./base.container";
import {Title, Well} from "@zendeskgarden/react-notifications";
import styled from "styled-components";
import {Paragraph, SM, Span, XXL} from "@zendeskgarden/react-typography";
import {Button} from '@zendeskgarden/react-buttons';
import {Col, Row} from "@zendeskgarden/react-grid";
import {CharityContext, DonationContext} from "../../context";
import {useHistory, useParams} from "react-router-dom";

import {EstimateComponent, Space} from "../../components";
import {totalItems, totalPrice} from "../../components/estimate.component";
import {Modal} from "@zendeskgarden/react-modals";
import {Donation} from "../../domain";
import {CancelDonationModal} from '../../components/cancel-donation-modal.c';
import {DriverCancelDonationModal} from '../../components/driver-cancel-donation-modal.c';
import {Box} from "../../components/box.c";

export const DonationContainer = () => {
    const history = useHistory();
    const [donation, setDonation] = useState<Donation>()
    const {actions} = useContext(DonationContext)
    const cContext = useContext(CharityContext)
    const {charity} = cContext;
    const [cancelDonationModalVisible, setCancelDonationModalVisible] = useState<boolean>(false);
    const openCancelDonationModal = () => setCancelDonationModalVisible(true)
    const closeCancelDonationModal = () => setCancelDonationModalVisible(false);

    const params = useParams<{ id: string }>()
    const {id} = params;

    useEffect(() => {
        actions.getDonation(id).then(setDonation);
    }, [])

    useEffect(() => {
        console.log(donation?.charity);
        if (donation) {
            cContext.actions.getCharity(donation.charity?.id!)
        }
    }, [donation])

    const fullAddress = (location: any) => {
        return location.address + ', ' + location.city + ', ' + location.state  + ', ' + location.zip;
    }

    if (donation) {
        return (
            <>
                <BaseContainer showBackButton title={donation.donationCode!} subtitle={"Donation details"}>
                    <Row>
                        <Col>
                            <StyledWell>
                                <StyledTitle>Primary Charity drop-off information</StyledTitle>
                                <SM>Name: </SM>
                                <Paragraph>{donation.primaryDropOff?.name}</Paragraph>
                                <SM>Address: </SM>
                                <Paragraph>{fullAddress(donation.primaryDropOff)}</Paragraph>
                                <SM>Primary contact name: </SM>
                                <Paragraph>{donation.primaryDropOff?.pocName}</Paragraph>
                                <SM>Phone:</SM>
                                <Paragraph><a href={"sms:" + donation.primaryDropOff?.phone}>{donation.primaryDropOff?.phone}</a></Paragraph>
                                <Paragraph>Closing by: <Span isBold>{donation.primaryDropOff?.closingBy}</Span></Paragraph>
                            </StyledWell>
                            <StyledWell>
                                <StyledTitle>Secondary drop-off information</StyledTitle>
                                <SM>Name: </SM>
                                <Paragraph>{donation.secondaryDropOff?.name}</Paragraph>
                                <SM>Address: </SM>
                                <Paragraph>{fullAddress(donation.secondaryDropOff)}</Paragraph>
                                <SM>Primary contact name: </SM>
                                <Paragraph>{donation.secondaryDropOff?.pocName}</Paragraph>
                                <SM>Phone:</SM>
                                <Paragraph><a href={"sms:" + donation.secondaryDropOff?.phone}>{donation.secondaryDropOff?.phone}</a></Paragraph>
                                <Paragraph>Closing by: <Span isBold>{donation.secondaryDropOff?.closingBy}</Span></Paragraph>
                            </StyledWell>
                            <StyledWell>
                                <StyledTitle>Donor contact information</StyledTitle>
                                <SM>Name: </SM>
                                <Paragraph>{donation.donor.name}</Paragraph>
                                <SM>Phone number: </SM>
                                <Paragraph><a href={"sms:" + donation.donor?.phone}>{donation.donor?.phone}</a></Paragraph>
                            </StyledWell>

                            <StyledWell>
                                <StyledTitle>Donation information</StyledTitle>
                                <Paragraph>Number of items : <Span isBold>{totalItems(donation.spec)}</Span></Paragraph>
                                <SM>Address: </SM>
                                <Paragraph>{fullAddress(donation.donor)}</Paragraph>
                                <SM>Special instructions: </SM>
                                <Paragraph>{donation.spec.additionalInformation || '/'}</Paragraph>
                            </StyledWell>

                            <StyledWell>
                                <StyledTitle>Donation description</StyledTitle>
                                {donation.donationItemsDescription?.map(d => (
                                    <div style={{marginBottom: 10}}>
                                        <Span isBold>{d.itemLabel}</Span>
                                        <p>{d.itemDescription}</p>
                                    </div>

                                ))}
                            </StyledWell>
                            <StyledWell>
                                <Row>
                                    <Col>Pickup estimate <small>Based on donor's input</small></Col>
                                    <Col style={{textAlign: "right"}}><XXL>${totalPrice(donation.spec, donation.pricing!)}</XXL></Col>
                                </Row>
                            </StyledWell>

                            <EstimateComponent spec={donation.spec} pricing={donation.pricing!}/>

                            <BottomControls>
                                <Row>
                                    <Col xs={6}><Button isStretched isDanger onClick={openCancelDonationModal}>Cancel Donation</Button></Col>
                                    <Col xs={6}><Button isStretched>FAQ's</Button></Col>
                                </Row>
                            </BottomControls>
                        </Col>
                        {cancelDonationModalVisible && (
                            <DriverCancelDonationModal
                                donation={donation}
                                onClose={closeCancelDonationModal}
                            />
                        )}
                    </Row>
                </BaseContainer>
            </>
        )
    } else {
        return (<></>)
    }

}

const StyledTitle = styled(Title)`
  margin-bottom: 10px
`
const StyledWell = styled(Well)`
  padding: 20px;
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
  }
`
const BottomControls = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
`
const StyledModal = styled(Modal)`
  left: 0;
  bottom: 0;
  max-width: calc(100%)
`
