import {autoserialize} from "cerialize";
import {Authorization} from "./Authorization";
import {UserDetails} from './User';

export class AuthResponse {
    @autoserialize
    id?: string
    @autoserialize
    authorization?: Authorization

    @autoserialize
    role?: string

    @autoserialize
    username?: string

    @autoserialize
    details?: UserDetails
}
