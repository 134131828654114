import React, {useContext, useEffect, useState} from "react";
import {DonationContext} from "../../context";
import {useHistory, useParams} from "react-router-dom";
import {Space} from "../../components";
import {Title, Well} from "@zendeskgarden/react-notifications";
import {Donation} from "../../domain";
import styled from "styled-components";
import {Col, Row} from "@zendeskgarden/react-grid";
import {LG, MD, XL, XXL} from "@zendeskgarden/react-typography";
import moment from "moment";
import {Button} from "@zendeskgarden/react-buttons";
import {FauxInput, Field, Input, Label} from "@zendeskgarden/react-forms";


export const DonationUpdateContainer = () => {
	const history = useHistory();

	const params = useParams<{ donationCode: string }>()
	const {actions, donations} = useContext(DonationContext)
	const {donationCode} = params
	const [donation, setDonation] = useState<Donation>()


	useEffect(() => {
		if (donationCode) {
			actions.getAllDonations(`donation_code=${donationCode}`)
		} else {
			window.location.href = "https://resupplyme.com"
		}
	}, [])

	useEffect(() => {
		if (donations && donations.length > 0) {
			const d = donations[0]
			setDonation(d)
		}
	}, [donations])

	if (donation) {
		if (donation.donationStatus !== 'submitted') {
			return (
				<Row>
					<Col>
						<StyledWell>
							<Space size={20}/>
							<LG style={{textAlign: 'center'}}>This donation is not eligible for editing</LG>
							<Space size={20}/>
						</StyledWell>
					</Col>
				</Row>
			)
		} else {
			return (
				<Container>
					<Row>
						<Col>
							<XXL>Manage your donation</XXL>
							<MD>If you need to reschedule or cancel your donation please use the form below</MD>
						</Col>
					</Row>
                    <Space size={30}/>
					<Row>
						<Col>
							<Label>Donor information</Label>
                            <Field>
                                <FauxInput>{donation.donor.name}</FauxInput>
                            </Field>
						</Col>
					</Row>
					<Space size={10}/>

					<Row>
                        <Col>
                            <Label>Requested date</Label>
                            <Field>
                                <FauxInput>{moment(donation.date).format("MM/DD/YYYY")}</FauxInput>
                            </Field>
                        </Col>
                        <Col>
                            <Label>Part of day</Label>
                            <Field>
                                <FauxInput>{donation.partOfDay.toUpperCase() || 'Not specified'}</FauxInput>
                            </Field>
                        </Col>
					</Row>

					<Space size={30}/>

					<Row>
						<Col>
							<ButtonWrapper>
								<Button
                                    isPill
									onClick={() => history.push(`/i/${donationCode}/reschedule`)}
								>Update requested date</Button>
							</ButtonWrapper>
						</Col>
						<Col>
							<ButtonWrapper>
								<Button
									isDanger
                                    isPill
									isPrimary
									onClick={() => history.push(`/i/${donationCode}/cancel`)}
								>Cancel donation</Button>
							</ButtonWrapper>
						</Col>
					</Row>
				</Container>
			)
		}
	} else {
		return <></>
	}

}

const Container = styled.div`
  max-width: 665px;
  width: 100%
`
const StyledWell = styled(Well)`
  padding: 5px;
  margin-top: 10px;
`

const ButtonWrapper = styled.div`
  text-align: center;

  Button {
    width: 300px;
    margin-top: 10px;
  }
`
