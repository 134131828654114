import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../context/";
import Api from "../services/api.service";
import {routes} from "../services/api.routes";
import {Donation} from "../domain";
import {CharityScopeContext, CharityScopeDataLoading} from "../context/charity-scope.context";
import {actions as commonActions} from './actions';

type Props = {
    children: any
}
export const CharityScopeProvider = (props: Props) => {
    const {user} = useContext(UserContext);
    const [donation, setDonation] = useState<Donation>(new Donation())
    const [donations, setDonations] = useState<Donation[]>([])

    const [loading, setLoading] = useState<CharityScopeDataLoading>({
        isDonationsLoading: false
    })

    useEffect(() => {
        getAllDonations()
    }, [user])

    const getAllDonations = (): Promise<Donation[]> => {
        setLoading({
            ...loading,
            isDonationsLoading: true
        })
        return Api.$<Donation>(routes.donations).getAll()
            .then(data => {
                setDonations(data);
                setLoading({
                    ...loading,
                    isDonationsLoading: false
                })
                return data;
            })
    }

    const actions = {
        getAllDonations,
        getDonation: commonActions.donations.getAction,
    }

    const meta = {
        loading
    }

    return (
        <CharityScopeContext.Provider value={
            {
                donations,
                setDonations,
                donation,
                setDonation,
                actions,
                meta
            }
        }>
            {props.children}
        </CharityScopeContext.Provider>
    )
}
