import {getAction, getByCharityAction} from "./get.action";
import {updateAction} from "./update.action";
import {createAction} from "./create.action";

export const slots = {
    getAction,
    getByCharityAction,
    updateAction,
    createAction,
}
