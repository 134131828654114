import {BaseContainer} from "../../base.container";
import React, {useContext, useEffect, useState} from "react";
import {Space, TableComponent} from "../../../components";
import {Well} from "@zendeskgarden/react-notifications";
import {field} from "../../../utility/field";
import {TplScopeContext} from "../../../context/tpl-scope.context";
import {extraButton} from "../../../utility/extraButton";
import {TruckCreationModal} from "../../../components/truck-creation-modal.c";
import {Trailer, Truck} from "../../../domain/schema";
import {AppContext} from "../../../context";
import {Box} from "../../../components/box.c";
import {TrailerCreationModal} from "../../../components/trailer-creation-modal.c";

export const TrucksContainer = () => {
	const context = useContext(TplScopeContext);
	const {user} = useContext(AppContext)
	const {trucks, trailers} = context;

	const [showTruckCreateModal, setShowTruckCreateModal] = useState(false)
	const [showTrailerCreateModal, setShowTrailerCreateModal] = useState(false)

	const [truck, setTruck] = useState<Truck>()
	const [trailer, setTrailer] = useState<Trailer>()

	const trucksFields = [
		field('name', 'Name', true),
		field('make', 'Make', true),
		field('model', 'Model'),
		field('size', 'Size'),
		field('type', 'Type'),
		field('licensePlate', 'License Plate'),
	]

	const trailersFields = [
		field('name', 'Name', true),
		field('size', 'Size'),
		field('type', 'Type'),
		field('licensePlate', 'License Plate'),
	]

	const extraButtons = [
		extraButton("Add a truck", () => {
			setShowTruckCreateModal(true)
		}),
		extraButton("Add a trailer", () => {
			setShowTrailerCreateModal(true)
		})
	]

	const onTruckAdded = (truck: Truck) => {
		closeModal();
	}
	const onTrailerAdded = (trailer: Trailer) => {
		closeModal();
	}
	const closeModal = () => {
		setShowTrailerCreateModal(false);
		setShowTruckCreateModal(false);
		setTruck(undefined)
		setTrailer(undefined)
	}

	useEffect(() => {
		console.log(user);
	}, [])

	useEffect(() => {
		setShowTruckCreateModal(Boolean(truck));
	}, [truck])

	useEffect(() => {
		setShowTrailerCreateModal(Boolean(trailer));
	}, [trailer])

	return (
		<BaseContainer
			title={'Trucks & trailers'}
			subtitle={'Manage your fleet'}
			extraButtons={extraButtons}
		>
			<>
				<Well>

				</Well>
				<Space size={30}/>
				<Box title={"Trucks"}>
					<TableComponent fields={trucksFields} onRowClicked={setTruck} data={trucks}/>
				</Box>
				<Space size={30}/>
				<Box title={"Trailers"}>
					<TableComponent fields={trailersFields} onRowClicked={setTrailer} data={trailers}/>
				</Box>

				{showTruckCreateModal && (
					<TruckCreationModal
						tplOrganizationId={user?.details.tplOrganizationId || ''}
						context={context}
						truck={truck}
						onClose={closeModal}
						onDone={onTruckAdded}/>)
				}
				{showTrailerCreateModal && (
					<TrailerCreationModal
						tplOrganizationId={user?.details.tplOrganizationId || ''}
						context={context}
						trailer={trailer}
						onClose={closeModal}
						onDone={onTrailerAdded}/>)
				}
			</>
		</BaseContainer>
	)
}
