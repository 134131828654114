import {BaseContainer} from "./base.container";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {Dropdown, FormField, TableComponent} from "../../components";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Well} from "@zendeskgarden/react-notifications";
import {field} from "../../utility/field";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {useHistory} from "react-router-dom";
import {CSVLink} from "react-csv";
import {Checkbox, Field, Label} from "@zendeskgarden/react-forms";
import {Donation} from "../../domain";
import {CharityScopeContext} from "../../context/charity-scope.context";
import {DropdownItem, dropdownItem} from "../../components/dropdown.c";
import {ExtraButton} from "../../utility/extraButton";
import {AppContext} from '../../context';

const fieldsAvailableForExport = [
    {
        key: 'donationCode',
        description: 'Donation ID',
        selected: false
    },
    {
        key: 'donationStatus',
        description: "Donation Status",
        selected: false
    },
    {
        key: 'date',
        description: "Date",
        selected: false
    },
    {
        key: 'donor.address',
        description: "Donor Address",
        selected: false
    },
    {
        key: 'donor.name',
        description: "Donor Name",
        selected: false
    },
    {
        key: 'donor.phone',
        description: "Donor Phone",
        selected: false
    },
    {
        key: 'donor.city',
        description: "Donor City",
        selected: false
    },
    {
        key: 'donor.state',
        description: "Donor State",
        selected: false
    },
    {
        key: 'donor.zip',
        description: "Donor Zip",
        selected: false
    },

]
const filterOptions = [
    dropdownItem("All", 'all'),
    dropdownItem("Active", 'active'),
    dropdownItem("History", 'history')
]

type Filter = {
    status: string,
    donorName: string,
    donationCode: string
}


export const DonationsFunnelContainer = () => {
    const history = useHistory();
    const {donations} = useContext(CharityScopeContext);
    const {user} = useContext(AppContext);

    const [matchedDonations, setMatchedDonations] = useState<Donation[]>(donations)

    const [selectedFields, setSelectedFields] = useState(fieldsAvailableForExport)
    const [exportData, setExportData] = useState<Partial<Donation>[]>(donations)
    const [exportModalVisible, setExportModalVisible] = useState(false);

    const [filter, setFilter] = useState<Filter>({} as Filter)


    const onDonationIdClicked = () => {
    } // For now this serves as a placeholder so the table could render the anchor instead of span
    const openDonationDetailsView = (donation: Donation) => {
        history.push(`/donations/${donation.id}`)
    }
    const onFieldForExportChanged = (index: number) => {
        const _selectedFields = [...selectedFields];
        _selectedFields[index].selected = !_selectedFields[index].selected;
        setSelectedFields(_selectedFields);
        setExportData(donations.map((donation) => {
            const item = {} as Donation;
            _selectedFields.forEach((field) => {
                if (field.selected) {
                    // @ts-ignore
                    item[field.key] = donation[field.key]
                }
            })
            return item
        }))
    }
    const tableFields = [
        field('donationCode', 'Donation ID', true, onDonationIdClicked),
        field('date', 'Donation date', true),
        field('donor.name', 'Donor name'),
        field('donor.email', 'Email'),
        field('donor.zip', 'Zip'),
        field('donationStatus', 'Status', true)
    ]
    const extraButtons: ExtraButton[] = [
        {
            title: 'Export to CSV',
            onClick: () => {
                setExportModalVisible(true)
            }
        }
    ]
    const donationFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target
        setFilter({
            ...filter,
            donationCode: value
        })
    }

    const donorNameFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target
        setFilter({
            ...filter,
            donorName: value
        })
    }
    const statusFilterChanged = (item: DropdownItem) => {
        setFilter({
            ...filter,
            status: item.value
        })
    }

    const applyFilter = () => {
        setMatchedDonations(donations
            .filter((donation) => donation.charity?.id === user?.details.charityId)
            .filter(donorNameFilter)
            .filter(statusFilter)
            .filter(donationFilter)
        )
    }

    const statusFilter = (donation: Donation): boolean => {
        switch (filter.status) {
            case 'active':
                return donation.donationStatus !== 'completed'
            case 'history':
                return donation.donationStatus === 'completed'
            default:
                return true
        }
    }
    const donorNameFilter = (donation: Donation): boolean => {
        if (donation.donor.name && filter.donorName) {
            return donation.donor.name.toLowerCase().includes(filter.donorName.toLowerCase());
        }
        return true
    }

    const donationFilter = (donation: Donation): boolean => {
        if (donation.donationCode && filter.donationCode) {
            return donation.donationCode.toLowerCase().includes(filter.donationCode.toLowerCase());
        }
        return true
    }

    useEffect(() => {
        applyFilter();
    }, [donations, filter])

    return (
        <BaseContainer
            title={'Donations funnel'}
            subtitle={'List of all donations in the system'}
            extraButtons={extraButtons}>
            <>
                <Well>
                    <Row>
                        <Col>
                            <FormField label={"Search by donation ID"} name={"donationCode"} onChange={donationFilterChanged}/>
                        </Col>
                        <Col>
                            <FormField label={"Search by donor"} name={"donor"} onChange={donorNameFilterChanged}/>
                        </Col>
                        <Col>
                            <Dropdown label={"Filter"} items={filterOptions} onChange={statusFilterChanged}/>
                        </Col>
                    </Row>
                </Well>
                <div style={{height: 30}}/>
                <Well>
                    <TableComponent fields={tableFields} onRowClicked={openDonationDetailsView}
                                    data={matchedDonations}/>
                </Well>

                {exportModalVisible && (
                    <Modal onClose={() => setExportModalVisible(false)}>
                        <Header>Select fields to export</Header>
                        <Body>
                            {selectedFields.map((field, index) => (
                                <Field key={index}>
                                    <Checkbox checked={field.selected} onChange={() => {
                                        onFieldForExportChanged(index)
                                    }}>
                                        <Label>{field.description}</Label>
                                    </Checkbox>
                                </Field>
                            ))}
                        </Body>
                        <Footer>
                            <FooterItem>
                                <Button onClick={() => setExportModalVisible(false)} isBasic>
                                    Cancel
                                </Button>
                            </FooterItem>
                            <FooterItem>
                                <CSVLink filename={"resupply-data-export.csv"} data={exportData}>Export data</CSVLink>
                            </FooterItem>
                        </Footer>
                        <Close aria-label="Close modal"/>
                    </Modal>
                )}
            </>
        </BaseContainer>
    )
}
