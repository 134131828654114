import {Charity, Donation, TPLOrganization} from "../domain";
import {createContext} from "react";
import {ArchitectResourceService} from "architect-sdk";
import {Trailer, Truck, Zone} from "../domain/schema";

export type TplScopeDataLoading = {
    isDonationsLoading: boolean;
    isTrucksLoading: boolean;
}

export type TplScopeContextActionsType = {
	getDonations: (q?: string) => Promise<Donation[]>

	getDonation: (id: string) => Promise<Donation>
	updateDonation: (data: Donation) => Promise<Donation>

	getCharity: (id: string) => Promise<Charity>

	getPartner: (id: string) => Promise<TPLOrganization>
	updatePartner: (data: TPLOrganization) => Promise<TPLOrganization>

	createTruck: (data: Partial<Truck>) => Promise<Truck>
	updateTruck: (data: Partial<Truck>) => Promise<Truck>
	createTrailer: (data: Partial<Trailer>) => Promise<Trailer>
	updateTrailer: (data: Partial<Trailer>) => Promise<Trailer>

	deleteTruck: (data: Partial<Truck>) => void
	deleteTrailer: (data: Partial<Trailer>) => void
}
export type TplScopeContextMetaType = {
	loading: TplScopeDataLoading
}

export type TplResourcesType = {
	trucks: ArchitectResourceService<Truck>
	trailers: ArchitectResourceService<Trailer>
}

export type TplScopeContextType = {
	donations: Donation[] | []
	trucks: Truck[]
	trailers: Trailer[]
	setDonations: (donations: Donation[]) => void
	zones: Zone[]

	actions: TplScopeContextActionsType
	meta: TplScopeContextMetaType
	resources: TplResourcesType
}

export const TplScopeContext = createContext<TplScopeContextType>({} as TplScopeContextType)
