import React, {useContext, useEffect, useState} from "react";
import {Donation} from "../domain";
import Api from "../services/api.service";
import {routes} from "../services/api.routes";

import {actions as commonActions} from './actions';
import {useLocation} from "react-router-dom";
import {TplScopeContext, TplScopeDataLoading} from "../context/tpl-scope.context";
import {AppContext} from "../context";
// @ts-ignore
import {architect} from "../services/architect.service";
import {Trailer, Truck, Zone} from "../domain/schema";

type Props = {
	children: React.ReactNode
}


export const TplScopeProvider = (props: Props) => {
	const location = useLocation()
	const {user} = useContext(AppContext)
	const [donations, setDonations] = useState<Donation[]>([])
	const [trucks, setTrucks] = useState<Truck[]>([])
	const [trailers, setTrailers] = useState<Trailer[]>([])
	const [zones, setZones] = useState<Zone[]>([])

	const [loading, setLoading] = useState<TplScopeDataLoading>({
		isDonationsLoading: false,
		isTrucksLoading: false
	})

	const getTrucks = () => {
		architect.trucks.search(`tpl_organization_id=${user?.details.tplOrganizationId}`).then(setTrucks);
	}
	const getTrailers = () => {
		architect.trailers.search(`tpl_organization_id=${user?.details.tplOrganizationId}`).then(setTrailers);
	}

	const getDonations = (q?: string): Promise<Donation[]> => {
		setLoading({
			...loading,
			isDonationsLoading: true
		})
		return Api.$<Donation>(routes.donations).getAll(`tpl_organization_id=${user?.details.tplOrganizationId}${q ? '&' + q : ''}`)
			.then(data => {
				setDonations(data);
				setLoading({
					...loading,
					isDonationsLoading: false
				})
				return data;
			})
	}

	const getZones = () => {
		architect.zones.getAll().then(setZones)
	}

	const actions = {
		getDonations,

		getDonation: commonActions.donations.getAction,
		updateDonation: commonActions.donations.updateAction,

		getCharity: commonActions.charities.getAction,

		getPartner: commonActions.partners.getAction,
		updatePartner: commonActions.partners.updateAction,

		createTruck: (data: Partial<Truck>) => {
			return architect.trucks.create(data)
				.finally(getTrucks)
		},

		updateTruck: (data: Partial<Truck>) => {
			return architect.trucks.update(data.id!, data)
				.finally(getTrucks)
		},

		createTrailer: (data: Partial<Trailer>) => {
			return architect.trailers.create(data)
				.finally(getTrailers)
		},
		updateTrailer: (data: Partial<Trailer>) => {
			return architect.trailers.update(data.id!, data)
				.finally(getTrailers)
		},
		deleteTruck: (data: Partial<Truck>) => {
            architect.trucks.delete(data.id!)
                .finally(getTrucks)
        },
		deleteTrailer: (data: Partial<Trailer>) => {
            architect.trailers.delete(data.id!)
                .finally(getTrailers)
        }
	}
	const meta = {
		loading
	}

	useEffect(() => {
		getTrucks();
		getTrailers();
		getZones();
	}, [])

	useEffect(() => {
		switch (location.pathname) {
			case '':
				getDonations();
				return;
		}
	}, [location])

	return (
		<TplScopeContext.Provider value={
			{
				donations,
				trucks,
				trailers,
				zones,

				setDonations,

				actions,
				meta,
				resources: architect
			}
		}>
			{props.children}
		</TplScopeContext.Provider>
	)
}
