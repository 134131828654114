import React, {FC} from "react";
import { Col, Row } from '@zendeskgarden/react-grid';

export const ThreeCol:FC = (props) => {
	const { children } = props;

	return (
		<Row>
			<Col>
				{React.Children.toArray(children)[0]}
			</Col>
			<Col>
				{React.Children.toArray(children)[1]}
			</Col>
			<Col>
				{React.Children.toArray(children)[2]}
			</Col>
		</Row>
	);
};
