import React, {useContext, useEffect, useState} from "react";
import {BaseContainer} from "./base.container";
import {DayPicker, DonationCategoryTileComponent, Space} from "../../components";
import {Col, Row} from "@zendeskgarden/react-grid";

import {ReactComponent as SofaIcon} from "../../assets/icons/couch-light.svg";
import {ReactComponent as ChairIcon} from "../../assets/icons/chair-light.svg";
import {ReactComponent as BoxIcon} from "../../assets/icons/box-light.svg";
import {ReactComponent as StairsIcon} from "../../assets/icons/walking-light.svg";
import {ReactComponent as ToolsIcon} from "../../assets/icons/tools-light.svg";
import {ReactComponent as DollyIcon} from "../../assets/icons/person-dolly-light.svg";
import {ReactComponent as PoweredByResupply} from "../../assets/images/new_resupply_logo.svg";

import styled from "styled-components";
import {MD, Paragraph, SM, Span, XL, XXXL} from "@zendeskgarden/react-typography";
import {Field} from "@zendeskgarden/react-forms";
import {Dropdown, Field as DropdownField, Item, Menu, Select} from '@zendeskgarden/react-dropdowns';
import {Button} from "@zendeskgarden/react-buttons";
import {useHistory, useParams} from "react-router-dom";
import {DonorContext} from "../../context/donor.context";
import {CharityContext, DonationContext} from "../../context";
import moment from "moment";
import {mediaQuery, PALETTE} from "@zendeskgarden/react-theming";
import {Well} from "@zendeskgarden/react-notifications";
import _ from "lodash";
import {Donation} from "../../domain";
import {closedDays} from "../../utility/closedDays";

import {Pricing} from "../../domain/Pricing";
import {defaultPricing} from "../../utility/pricing";
import {Inline} from "@zendeskgarden/react-loaders";
import {CharitySlots} from "../../domain/CharitySlots";
import {ItemDescription} from "../../domain/schema/item-description";

interface IItem {
    label: string;
    value: string;
}

const items = [
    {label: 'No preference', value: ''},
    {label: 'AM', value: 'am'},
    {label: 'PM', value: 'pm'},
];

export const FlowContainer = () => {
    const params = useParams<{ charityId: string }>()

    const {donationData, setDonationData} = useContext(DonorContext)
    const {charity, actions} = useContext(CharityContext)
    const [slots, setSlots] = useState<CharitySlots[]>([])

    const [slotsCalculating, setSlotsCalculating] = useState(false);

    const [pricing, setPricing] = useState<Pricing>(defaultPricing as Pricing)
    const [partOfDayOptions, setPartOfDayOptions] = useState<IItem[]>(items)

    const history = useHistory();
    const [selectedItem, setSelectedItem] = useState(_.filter(items, {'value': donationData.partOfDay})[0]);

    const {charityId} = params;

    const closedDates: Date[] = (charity.closedDates?.map((date) => new Date(date)) as Date[]) || [];

    useEffect(() => {
        actions.getCharity(charityId);
    }, [])

    useEffect(() => {
        if (charity) {
            actions.getSlots(charity.id!).then(setSlots)
            setPricing(charity.pricing);
            const d = new Donation();
            Object.assign(d, donationData);
            d.charity = charity;
            setDonationData(d);
        }
    }, [charity])

    useEffect(() => {
        updateDonation('partOfDay', selectedItem.value)
    }, [selectedItem])

    useEffect(() => {
        console.log(donationData);
    }, [donationData])

    useEffect(() => {
        setSlotsCalculating(true);

        if (charity && charity.id && donationData.date) {
            actions.getSlotsMeta(charity.id, donationData.date)
                .then((slotsMeta) => {
                    const slot = slots.find(s => s.date === donationData.date);
                    const availablePartOfDayOptions = []
                    if (slotsMeta.amAvailable) {
                        availablePartOfDayOptions.push({label: 'AM', value: 'am'})
                    }
                    if (slotsMeta.pmAvailable) {
                        availablePartOfDayOptions.push({label: 'PM', value: 'pm'})
                    }

                    if (slotsMeta.amAvailable && slotsMeta.pmAvailable) {
                        const amRemaining = parseInt(slot?.slotsAm + "") - parseInt(slotsMeta.amCount + "")
                        const pmRemaining = parseInt(slot?.slotsPm + "") - parseInt(slotsMeta.pmCount + "")

                        if (amRemaining > pmRemaining) {
                            setSelectedItem(availablePartOfDayOptions[0])
                        } else {
                            setSelectedItem(availablePartOfDayOptions[1])
                        }
                    } else {
                        if (availablePartOfDayOptions.length === 0) {
                            availablePartOfDayOptions.push({label: 'Not available', value: 'not-available'})
                        }
                        setSelectedItem(availablePartOfDayOptions[0])

                    }

                    setPartOfDayOptions(availablePartOfDayOptions);
                    setSlotsCalculating(false)
                })
        }

    }, [donationData.date])


    const updateDonation = (key: string, value: any) => {
        const d = new Donation();
        Object.assign(d, donationData);
        _.set(d, key, value)
        d.costEstimate = Donation.getEstimate(donationData.spec, donationData.pricing!);
        d.donationItemsDescription = prepareItemsDescriptionsArray();
        setDonationData(d);
    }

    const prepareItemsDescriptionsArray = () => {
        const itemDescriptions: ItemDescription[] = [];
        _.times(donationData.spec.xl, (i) => {
            itemDescriptions.push({
                itemType: "xl",
                itemId: `xl-${i + 1}`,
                itemLabel: "Extra Large Item",
                itemDescription: ''
            })
        })
        _.times(donationData.spec.lg, (i) => {
            itemDescriptions.push({
                itemType: "lg",
                itemId: `lg-${i + 1}`,
                itemLabel: "Large Item",
                itemDescription: ''
            })
        })
        _.times(donationData.spec.md, (i) => {
            itemDescriptions.push({
                itemType: "md",
                itemId: `md-${i + 1}`,
                itemLabel: "Medium Item",
                itemDescription: ''
            })
        })
        return itemDescriptions
    }

    const disabledDays = [
        ...closedDates,
        {
            daysOfWeek: closedDays(charity.daysOfOperation)
        },
        {
            before: moment(new Date()).add(2, 'd').toDate()
        }
    ];

    return (donationData) ? (
        <StyledContainer>
            <StyledRow style={{padding: 10}}>
                <Col xs={12}>
                    <img style={{margin: 20, maxWidth: 280}} src={charity.logoUrl} alt={charity.name}/>
                    <Question>
                        {charity && (<Span isBold> {charity.name}</Span>)}
                    </Question>
                    <Line/>
                    <Space size={10}/>
                </Col>
            </StyledRow>
            <BaseContainer title={"Tell us about your donation"}
                           subtitle={"Click or Tap the information Icon on each category to see more details"}>
                <ContentWrapper>
                    <StyledRow>
                        <Col xs={6} sm={4}>
                            <DonationCategoryTileComponent
                                value={donationData.spec.xl}
                                field={"spec.xl"}
                                name={"Extra Large items"}
                                description={'2+ person lift / special equipment'}
                                explanation={'Appliances, entertainment centers, china cabinets, wardrobes, large slabs of counter top, sectionals, dining room tables for 8 or more people and other items that should be carried by more than 2 people or require special equipment.'}
                                onValueChanged={updateDonation}
                                icon={() => (<DollyIcon/>)}
                            />
                        </Col>
                        <Col xs={6} sm={4}>
                            <DonationCategoryTileComponent
                                value={donationData.spec.lg}
                                field={"spec.lg"}
                                name={"Large items"}
                                description={'2-person lift'}
                                explanation={'Dinner tables, couches, dressers, side boards, executive desks, tall book cases and other items that should be carried by 2 people.'}
                                onValueChanged={updateDonation}
                                icon={() => (<SofaIcon/>)}
                            />
                        </Col>
                        <Col xs={6} sm={4}>
                            <DonationCategoryTileComponent
                                value={donationData.spec.md}
                                field={"spec.md"}
                                name={"Medium items"}
                                description={'1-person lift'}
                                explanation={'Dining room and desk chairs, end tables, stools, small rugs and other light items that should be carried by one person.'}
                                onValueChanged={updateDonation}
                                icon={() => (<ChairIcon/>)}
                            />
                        </Col>
                        <Col xs={6} sm={4}>
                            <DonationCategoryTileComponent
                                value={donationData.spec.sm}
                                field={"spec.sm"}
                                name={"Bags and Boxes"}
                                description={'1-person lift'}
                                explanation={'Bags, or bins of household goods, or small items that are properly packed and closed if possible. These items must be able to be carried by one person!'}
                                onValueChanged={updateDonation}
                                icon={() => (<BoxIcon/>)}
                            />
                        </Col>
                        <Col xs={6} sm={4}>
                            <DonationCategoryTileComponent
                                value={donationData.spec.staircases}
                                field={"spec.staircases"}
                                name={"Stair cases"}
                                description={'If no elevator'}
                                explanation={'If your donations must be moved up or down stairs and no elevator is available, please select the number of staircases that must be traversed!'}
                                onValueChanged={updateDonation}
                                icon={() => (<StairsIcon/>)}
                            />
                        </Col>
                        <Col xs={6} sm={4}>
                            <DonationCategoryTileComponent
                                value={donationData.spec.disassembly}
                                field={"spec.disassembly"}
                                name={"Disassembly"}
                                description={'Requiring tooling / upcharge items'}
                                explanation={'Please add this for each item that requires tools for disassembly in order to remove!'}
                                onValueChanged={updateDonation}
                                icon={() => (<ToolsIcon/>)}
                            />
                        </Col>
                    </StyledRow>

                    <Line/>
                    <StyledRow>
                        <Col>
                            <XL>Schedule your Priority Pick-up</XL>
                            <Paragraph>Choose the day and time slot of your pick-up!</Paragraph>
                        </Col>
                    </StyledRow>

                    <StyledRow>
                        <Col sm={12}>
                            <Row>
                                <Col sm={9}>
                                    <StyledField>
                                        <MD>Pick a date</MD>
                                        <DayPicker
                                            value={donationData.date}
                                            onChange={updateDonation}
                                            placeholder={'Choose a date'}
                                            numberOfMonths={2}
                                            name={'date'}
                                            disabledDays={disabledDays}/>
                                    </StyledField>
                                </Col>
                                <Col sm={3}>
                                    <StyledField>
                                        <MD>Arrival window</MD>
                                        <Dropdown
                                            selectedItem={selectedItem}
                                            onSelect={setSelectedItem}
                                            downshiftProps={{itemToString: (item: IItem) => item && item.label}}
                                        >
                                            <DropdownField>
                                                <Select disabled={slotsCalculating}>{selectedItem.label}</Select>
                                            </DropdownField>
                                            <Menu>
                                                {partOfDayOptions.map(option => (
                                                    <Item key={option.value} value={option}>
                                                        {option.label}
                                                    </Item>
                                                ))}
                                            </Menu>
                                        </Dropdown>
                                    </StyledField>
                                </Col>
                            </Row>
                        </Col>


                        <Col sm={6} offsetSm={3}>
                            <Row style={{textAlign: 'center'}}>
                                <Col>
                                    {(slotsCalculating && donationData.date)? (
                                        <SM style={{color: PALETTE.grey["500"]}}>
                                            Great! Let us find the available slot for your pickup. It will only take a second.
                                            {' '}
                                            <Inline/>
                                        </SM>
                                    ) : (
                                        <SM style={{color: PALETTE.grey["500"]}}>
                                            Your exact 4 hour time window will be sent to you via email or text once a
                                            driver is
                                            assigned
                                        </SM>
                                    )}

                                </Col>
                            </Row>
                        </Col>
                    </StyledRow>

                    <Line/>

                    <StyledRow>
                        <Col sm={6} offsetSm={3}>
                            <Well style={{padding: 10}}>
                                <StyledXXXL>Cost Estimate: <Span>${Donation.getEstimate(donationData.spec, donationData.pricing!)}</Span></StyledXXXL>
                            </Well>
                        </Col>
                    </StyledRow>

                    <Line/>

                    <Space size={30}/>

                    <StyledRow>
                        <Col>
                            <StyledButton
                                onClick={() => {
                                    history.push(`/${charityId}/additional-information`)
                                }}
                                disabled={
                                    !donationData.date
                                    || Donation.getEstimate(donationData.spec, donationData.pricing!) === pricing.base
                                    || slotsCalculating
                                    || donationData.partOfDay === 'not-available'
                                }
                                isStretched>
                                Enter Additional Information
                            </StyledButton>
                        </Col>
                    </StyledRow>
                    <Space size={60}/>
                    <StyledRow>
                        <Col>
                            <PoweredByResupply width={300} height={200}/>
                        </Col>
                    </StyledRow>
                    <Space size={60}/>
                </ContentWrapper>
            </BaseContainer>
        </StyledContainer>
    ) : <Inline/>

}

const ContentWrapper = styled.div`
  color: #2c3b64;
`
const StyledContainer = styled.div`
  //padding: 0 10px;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${PALETTE.grey["200"]};
`
const StyledField = styled(Field)`
  margin-bottom: 15px;
  text-align: left;
`
const Question = styled(XL)`
  margin-bottom: 10px;
  text-align: center;
`
const StyledRow = styled(Row)`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  transition: all 100ms ease-in-out;
  background-color: rgba(255, 255, 255, 0.4);

  &:hover {
    opacity: 0.8;
  }
`
const StyledXXXL = styled(XXXL)`
  color: #3C5E96;
  font-weight: 900;
  font-size: 32px;
  line-height: 54px;
  ${p => mediaQuery('up', 'lg', p.theme)} {
    font-size: 48px;
    line-height: 54px;

  }
`
