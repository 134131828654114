import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import styled from 'styled-components';
import {PALETTE} from '@zendeskgarden/react-theming';
import LocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import 'react-day-picker/lib/style.css';


const {parseDate, formatDate} = LocaleUtils

const DEFAULT_PLACEHOLDER = ""
const DEFAULT_NUMBER_OF_MONTHS = 1
const DEFAULT_DISABLED_DAYS_RULES: any[] = []
const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY'
const DEFAULT_DATE_DISPLAY_FORMAT = 'MMMM D, YYYY'


type Props = {
    onChange: (name: string, value: string, dateValue: Date) => void
    placeholder?: string,
    numberOfMonths?: number
    name: string,
    value?: string,
    disabledDays?: any[],
    outputFormat?: string,
    displayFormat?: string
}

export const DayPicker = (props: Props) => {
    const {
        onChange,
        name,
        value,
        placeholder = DEFAULT_PLACEHOLDER,
        numberOfMonths = DEFAULT_NUMBER_OF_MONTHS,
        disabledDays = DEFAULT_DISABLED_DAYS_RULES,
        displayFormat = DEFAULT_DATE_DISPLAY_FORMAT,
        outputFormat = DEFAULT_DATE_FORMAT
    } = props;

    const onDateChanged = (date: Date) => {
        onChange(name, moment(date).format(outputFormat), date)
    }
    return (
        <StyledDayPickerInput>
            <DayPickerInput
                placeholder={placeholder}
                value={value}
                onDayChange={onDateChanged}
                formatDate={formatDate}
                parseDate={parseDate}
                format={displayFormat}
                inputProps={{ readOnly: true }}

                dayPickerProps={{
                    numberOfMonths: numberOfMonths,
                    modifiers: {
                        disabled: disabledDays,
                    }
                }}
            />
        </StyledDayPickerInput>
    )
}

const StyledDayPickerInput = styled.div`
    .DayPickerInput {
      display: block;
      input {
        display: block;
        padding-left: 10px;
        width:100%;
        height: 40px;
        border: 1px solid ${PALETTE.grey['300']};
        border-radius: 4px;
        &:focus {
          outline-color: ${PALETTE.blue['500']};
      }
    }
`
