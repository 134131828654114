export const routes = {
    authenticate: 'authenticate',
    users: 'users',

    donations: 'api/donations',
    trucks: 'api/trucks',
    charities: 'api/charities',
    partners: 'api/tpls',
    zones: 'api/zones',
    schedules: 'api/schedules',
    availability: 'api/charity_slots',

    createServiceRequest: 'functions/create_service_request',
    acceptServiceRequest: 'functions/accept_service_request',
    getMerchantUrl: 'functions/stripe_account',
    getMerchantAccountInfo: 'functions/merchant_account_info',
    getMerchantAccountLogin: 'functions/merchant_account_login',
    stripeCheckout: 'functions/stripe_checkout',
    createStripeCustomer: 'functions/stripe_customer',

    slotsMeta: 'functions/get_availability',
    updateAvailability: 'functions/update_available_dates',

    submitDonation: 'functions/submit_donation'
}
