import {autoserialize, autoserializeAs} from "cerialize";


export class UserDetails {
    @autoserialize
    name: string = ''

    @autoserialize
    phone: string = ''

    @autoserialize
    email: string = ''

    @autoserialize
    tplOrganizationId: string = ''

    @autoserialize
    scheduleId: string = ''

    @autoserialize
    charityId: string = ''

    @autoserializeAs("last_active")
    lastActive: string = ''

}

class UserAuthorization{
    @autoserialize
    token?: string
}

export class User{
    @autoserialize
    username?: string

    @autoserializeAs("user_id")
    userId?: string

    @autoserialize
    password?: string

    @autoserialize
    role?: string

    @autoserialize
    active?: boolean

    @autoserializeAs(UserDetails)
    details: UserDetails

    @autoserializeAs(UserAuthorization)
    authorization?: UserAuthorization


    constructor(username?: string, role?: string, details: UserDetails = new UserDetails() ) {
        this.details = details;
        this.username = username;
        this.role = role
    }
}
