import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {BaseContainer} from "./base.container";
import {Col, Row} from "@zendeskgarden/react-grid";

import styled from "styled-components";
import {MD, Paragraph, XL} from "@zendeskgarden/react-typography";
import {Field, Input, MediaInput, Textarea} from "@zendeskgarden/react-forms";
import {Button} from "@zendeskgarden/react-buttons";
import {useHistory, useParams} from "react-router-dom";
import {DonorContext} from "../../context/donor.context";
import ReactInputMask from "react-input-mask";
import {Donation} from "../../domain";
import _ from "lodash";
import * as yup from 'yup';
import {ItemDescription} from "../../domain/schema/item-description";

const schema = yup.object().shape({
	name: yup.string().required(),
	address: yup.string().required(),
	city: yup.string().required(),
	state: yup.string().required(),
	zip: yup.string().required(),
	phone: yup.string().matches(/^(\+1)\d{10}/g).required(),
	email: yup.string().email().required(),
})

export const DonationInformationContainer = () => {
	const {donationData, setDonationData, actions} = useContext(DonorContext)
	const [disabled, setDisabled] = useState(true)
	const history = useHistory();

	const params = useParams<{ charityId: string }>();
	const {charityId} = params;

	useEffect(() => {
		schema.validate(donationData.donor).then((result) => {
			setDisabled(false)
		}).catch(() => {
			setDisabled(true)
		})
	}, [donationData])

	const updateDonation = (key: string, value: any) => {
		const d = new Donation();
		Object.assign(d, donationData);
		_.set(d, key, value);
		setDonationData(d);
	}

	const fieldChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const {value, name} = e.target;
		updateDonation(name, value);
	}

    const donationItemDescriptionChanged = (index: number, value: string) => {
        const item = donationData.donationItemsDescription[index];
        item.itemDescription = value;
        const d = new Donation();
        Object.assign(d, donationData);
        d.donationItemsDescription[index] = item;
        setDonationData(d);
    }

    useEffect(() => {
        console.log(donationData.donationItemsDescription);
    }, [donationData])

	const renderDonationDescription = () => {
		return donationData.donationItemsDescription.map((item, i) => (
            <StyledRow key={item.itemId}>
                <Col>
                    <StyledField>
                        <MD>{item.itemLabel} description</MD>
                        <Input onChange={(e) => donationItemDescriptionChanged(i, e.target.value)}
                               type={"text"}
                               name={"donationItemsDescription[i].itemDescription"}
                               placeholder={"Please add description here"}
                               value={item.itemDescription}/>
                    </StyledField>
                </Col>
            </StyledRow>
        ))
	}

	return <BaseContainer title={"Let's lock in your spot"} subtitle={"Confirm your donation"} showBackButton={true}>
		<ContentWrapper>
			<StyledRow>
				<Col xs={12}>
					<Question>Tell us about you</Question>
				</Col>

				<Col>
					<StyledField>
						<MD>What is your name?</MD>
						<Input
							onChange={fieldChanged}
							name={"donor.name"}
							placeholder={"Your first and last name"}
							value={donationData.donor.name}/>
					</StyledField>
				</Col>
			</StyledRow>
			<StyledRow>
				<Col>
					<StyledField>
						<MD>What is your phone number?</MD>
						<ReactInputMask mask={'+19999999999'}
										onChange={fieldChanged}
										name={"donor.phone"}
										placeholder={"Your phone number"}
										value={donationData.donor.phone}>
							<Input/>
						</ReactInputMask>
					</StyledField>
				</Col>
			</StyledRow>
			<StyledRow>
				<Col>
					<StyledField>
						<MD>What is your address?</MD>
						<MediaInput
							onChange={fieldChanged}
							name={"donor.address"}
							value={donationData.donor.address}/>
					</StyledField>
				</Col>
				<Col xs={12}>
					<Row>
						<Col>
							<StyledField>
								<MD>City</MD>
								<Input onChange={fieldChanged} name={"donor.city"} value={donationData.donor.city}/>
							</StyledField>
						</Col>
						<Col>
							<StyledField>
								<MD>State</MD>
								<Input onChange={fieldChanged} name={"donor.state"} value={donationData.donor.state}/>
							</StyledField>
						</Col>
						<Col>
							<StyledField>
								<MD>Zip</MD>
								<Input onChange={fieldChanged} name={"donor.zip"} value={donationData.donor.zip}/>
							</StyledField>
						</Col>
					</Row>
				</Col>
			</StyledRow>
			<StyledRow>
				<Col>
					<StyledField>
						<MD>What is your email?</MD>
						<Input onChange={fieldChanged}
							   type={"email"}
							   name={"donor.email"}
							   placeholder={"Your email"}
							   value={donationData.donor.email}/>
					</StyledField>
				</Col>
			</StyledRow>

			<StyledRow>
				<Col>
					<StyledField>
						<MD>Do you have any special instructions?</MD>
						<StyledTextArea onChange={fieldChanged}
										name="spec.additionalInformation"
										placeholder={"Enter any special instructions"}
										value={donationData.spec.additionalInformation}/>
					</StyledField>
				</Col>
			</StyledRow>

			<StyledRow>
				<Col xs={12}>
					<Question>Can you describe your items?</Question>
					<StyledParagraph>
						Please fill in the form explaining what your items are.
						We added a field for each of the items you selected, and a simple one word description
						should be enough. E.g. Dinning table
					</StyledParagraph>
				</Col>
			</StyledRow>

			{renderDonationDescription()}

			<StyledRow>
				<Col lg={12}>
					<StyledButton
						disabled={disabled}
						onClick={() => {
							history.push(`/${charityId}/confirm`)
						}}
						isStretched>Finalize Donation Details</StyledButton>
				</Col>
			</StyledRow>
		</ContentWrapper>
	</BaseContainer>
}

const ContentWrapper = styled.div`
  color: #2c3b64;
`

const StyledField = styled(Field)`
  margin-bottom: 15px;
  text-align: left;
`
const Question = styled(XL)`
  margin-bottom: 10px;
  text-align: center;
`
const StyledRow = styled(Row)`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`

const StyledButton = styled(Button)`
  transition: all 100ms ease-in-out;
  background-color: rgba(255, 255, 255, 0.4);

  &:hover {
    opacity: 0.8;
  }
`
const StyledParagraph = styled(Paragraph)`
  max-width: 600px;
  margin: auto;
`

const StyledTextArea = styled(Textarea)`
`
