import {donations} from "./donations";
import {charities} from "./charities";
import {users} from "./users";
import {partners} from "./partners";
import {slots} from "./slots";
import {zones} from "./zones";

export const actions = {
    donations,
    charities,
    users,
    partners,
    slots,
    zones
}
