import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import React, {useEffect, useMemo, useState} from "react";
import {Inline} from "@zendeskgarden/react-loaders";
import {CaptainScopeContextType} from "../context/captain-scope.context";
import {TplScopeContextType} from "../context/tpl-scope.context";
import {useForm} from "react-hook-form";
import {Truck} from "../domain/schema";
import {Form} from "./form";
import {Space} from "./space";
import {truckDefaults} from "../containers/tpl/trucks/truck.defaults";
import {truckResolver} from "../containers/tpl/trucks/truck.resolver";
import {truckSizeOptions} from "../containers/tpl/trucks/truck.size.options";
import {truckTypeOptions} from "../containers/tpl/trucks/truck.type.options";
import styled from "styled-components";
import {PALETTE} from "@zendeskgarden/react-theming";
import {Paragraph} from "@zendeskgarden/react-typography";

type Props = {
	onDone?: (truck: Truck) => void
	onClose?: () => void
	context: CaptainScopeContextType | TplScopeContextType
	tplOrganizationId: string,
	truck?: Truck
}

export const TruckCreationModal = (props: Props) => {
	const {
		onDone = () => {
		}, onClose = () => {
		},
		context,
		tplOrganizationId,
		truck
	} = props;
	const {actions} = context;

	const [isLoading, setLoading] = useState(false);

	const {control, reset, handleSubmit, setValue, formState: {errors}} = useForm<Truck>({
			defaultValues: truckDefaults,
			resolver: truckResolver
		}
	)

	useEffect(() => {
		if (truck) {
			reset(truck)
		}
	}, [truck])

	useEffect(() => {
		if (tplOrganizationId) {
			setValue('tplOrganizationId', tplOrganizationId)
		}
	}, [tplOrganizationId])

	const onSubmit = handleSubmit((data) => {
		setLoading(true);
		if (truck) {
			actions.updateTruck(data).then((truck) => {
				setLoading(false);
				onDone(truck);
			})
		} else {
			actions.createTruck(data).then((truck) => {
				setLoading(false);
				onDone(truck);
			})
		}

	})

	const onDelete = () =>{
		if (truck){
			actions.deleteTruck(truck);
			onClose();
		}
	}

	const buttonText = useMemo(() => truck ? 'Update truck' : 'Create truck', [truck])
	const formTitle = useMemo(() => truck ? 'Update truck' : 'Add a truck', [truck])

	return (
		<Modal onClose={onClose} style={{opacity: isLoading ? 0.5 : 1}}>
			<form onSubmit={onSubmit}>
				<Header>{formTitle}</Header>
				<Body>
					Please provide necessary info in order to create/modify a truck.
					Adding a truck will adjust your daily availability

					<Space size={20}/>
					<Form.Input control={control} label={"Truck name"} name={'name'} errors={errors}/>
					<Form.Input control={control} label={"Make"} name={'make'} errors={errors}/>
					<Form.Input control={control} label={"Model"} name={'model'} errors={errors}/>
					<Form.Input control={control} label={"License plate"} name={'licensePlate'} errors={errors}/>
					<Form.Dropdown
						control={control}
						label={"Truck size"}
						name={'size'}
						itemKey={i => i.value}
						resolveItemLabel={i => i?.label}
						resolveItemValue={i => i?.value}
						items={truckSizeOptions}/>
					<Form.Dropdown
						control={control}
						label={"Is truck open?"}
						name={'type'}
						itemKey={i => i.value}
						resolveItemLabel={i => i?.label}
						resolveItemValue={i => i?.value}
						items={truckTypeOptions}/>
				</Body>
				<Footer>
					<FooterItem>
						<Button onClick={onClose} isDanger isBasic>
							Cancel
						</Button>
					</FooterItem>
					<FooterItem>
						<Button
							type={'submit'}
							style={{minWidth: 200}}
							disabled={isLoading}
							isPrimary
						>
							{isLoading ? <Inline/> : buttonText}
						</Button>
					</FooterItem>
				</Footer>
				{truck && (
					<RedFooter>
						<FooterItem>
							<Paragraph>
								Deleting a truck is not reversible.
							</Paragraph>
						</FooterItem>
						<FooterItem>
							<Button isDanger isPrimary onClick={onDelete}>Delete truck</Button>
						</FooterItem>
					</RedFooter>
				)}
				<Close aria-label="Close modal"/>
			</form>
		</Modal>
	)
}

const RedFooter = styled(Footer)`
  border-top: 1px solid ${PALETTE.crimson.M400};
  padding-bottom: 20px
`
