import {BaseContainer} from "../../base.container";
import {Well} from "@zendeskgarden/react-notifications";
import {Col, Row} from "@zendeskgarden/react-grid";
import {FormField, Space, TableComponent} from "../../../components";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {field} from "../../../utility/field";
import {extraButton, ExtraButton} from "../../../utility/extraButton";
import {navigationRoutes} from "../../../utility/navigationRoutes";
import {TPLOrganization} from "../../../domain";
import {CaptainScopeContext} from "../../../context/captain-scope.context";

const fields = [
    field('name', '3PL name'),
    field('accountManagerName', 'Account manager'),
    field('phone', 'Phone'),
    field('email', 'Email'),
]

export const PartnersContainer = () => {
    const {partners} = useContext(CaptainScopeContext)

    const [matchedPartners, setMatchedPartners] = useState<TPLOrganization[]>([])
    const [filter, setFilter] = useState<string>('')

    const history = useHistory();

    const extraButtons: ExtraButton[] = [
        extraButton("Add a 3PL", () => {
            history.push(navigationRoutes.createTPL)
        })
    ]

    useEffect(() => {
        setMatchedPartners(partners)
    }, [partners])

    useEffect(() => {
        setMatchedPartners([
            ...partners.filter(p => p.name.includes(filter))
        ])
    }, [filter])

    const openPartnerDetails = (partner: TPLOrganization) => history.push(navigationRoutes.tplDetails(partner))

    return (
        <BaseContainer title={"3PLs"} subtitle={"List of all partners"} extraButtons={extraButtons}>
            <>
                <Well>
                    <Row>
                        <Col>
                            <FormField
                                onChange={(e) => {
                                    setFilter(e.target.value)
                                }}
                                name={'filter.name'}
                                label={"Organization filter"}/>
                        </Col>
                    </Row>
                </Well>
                <Space size={30}/>
                <Well>
                    <TableComponent fields={fields} onRowClicked={openPartnerDetails} data={matchedPartners}/>
                </Well>
            </>

        </BaseContainer>
    )
}
