import {MediaInput} from "@zendeskgarden/react-forms";
import {ReactComponent as CalendarIcon} from "../assets/icons/calendar-alt-light.svg";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import React, {HTMLProps, useEffect, useState} from "react";
import moment from "moment";

type DatePickerProps = {
    name: string
    value: string | undefined
    format?: string
    onChange: (field: string, value: string | undefined) => void,
    minValue?: Date
}
export const DatePicker = (props: DatePickerProps) => {
    const {name, value, format = "MM-DD-YYYY", onChange, minValue} = props;
    const [date, setDate] = useState<Date>()

    useEffect(() => {
        if (value) {
            setDate(moment(value, format).toDate())
        }else{
            setDate(undefined);
        }
    }, [value])

    useEffect(() => {
        if (date){
            onChange(name, moment(date).format(format));
        }

    }, [date])

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    return (
        <Datepicker value={date} onChange={setDate} minValue={minValue} formatDate={date => dateFormatter.format(date)}>
            <MediaInput readOnly start={<CalendarIcon/>} name={name}/>
        </Datepicker>
    )
}
