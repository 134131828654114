import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import styled from "styled-components";
import ReactInputMask from "react-input-mask";

type FormFieldProps = {
    label: string
    name: string
    mask?: string
    type?: string
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    placeholder? : string,
    value?: string

}

export const FormField:FC<FormFieldProps> = (props: FormFieldProps) => {
    const {value, onChange} = props;

    const [fieldValue, setFieldValue] = useState<string>()

    useEffect(() => {
        setFieldValue(value);
    }, [value])

    const onFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFieldValue(e.target.value);
        onChange(e);
    }

    return (
        <StyledField>
            <Label>{props.label}</Label>
            {props.mask ? (
                <ReactInputMask mask={props.mask} name={props.name} onChange={onFieldChange} type={props.type} value={fieldValue}>
                    <Input/>
                </ReactInputMask>
            ) : (
                <Input name={props.name} onChange={onFieldChange} type={props.type} placeholder={props.placeholder} value={fieldValue}/>
            )}

        </StyledField>
    )
}

const StyledField = styled(Field)`
  margin-bottom: 15px;
`
