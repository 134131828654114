import {BaseContainer} from "../base.container";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {LG, Paragraph} from "@zendeskgarden/react-typography";
import {useHistory, useParams} from "react-router-dom";
import {field} from "../../utility/field";
import {Tab, TabList, TabPanel, Tabs} from "@zendeskgarden/react-tabs";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Alert, Well} from "@zendeskgarden/react-notifications";
import {TableComponent} from "../../components";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Charity, CharityAdmin, Donation, User} from "../../domain";
import {navigationRoutes} from "../../utility/navigationRoutes";
import moment from 'moment';
import {Tag} from '@zendeskgarden/react-tags';
import {CaptainScopeContext} from "../../context/captain-scope.context";
import * as _ from "lodash";
import {Inline} from "@zendeskgarden/react-loaders";
import {CharityAdminCreationModal} from "../../components/charity-admin-creation-modal.c";
import {defaultPricing} from "../../utility/pricing";

const fields = [
    field('donationCode', 'Donation ID'),
    field('donor.name', 'Donor name'),
    field('donor.phone', 'phone'),
    field('donationStatus', 'Status')
]

const usersFields = [
    field('details.name', 'Name'),
    field('username', 'Username'),
    field('role', 'Role'),
]

export const CharityContainer = () => {
    const [visible, setVisible] = useState(false);
    const [selectedTab, setSelectedTab] = useState('tab-1');

    const history = useHistory()
    const params = useParams<{ id: string }>();

    const {actions, donations, users} = useContext(CaptainScopeContext)
    const [charity, setCharity] = useState<Charity>(new Charity())
    const [charityDonations, setCharityDonations] = useState<Donation[]>([])
    const [charityModalVisible, setCharityModalVisible] = useState(false);
    const [charityAdmins, setCharityAdmins] = useState<User[]>([])

    const [savingPricing, setSavingPricing] = useState(false);

    const {id} = params;

    const openAddUserModal = () => {
        setCharityModalVisible(true);
    }
    const closeAddUserModal = () => {
        setCharityModalVisible(false);
    }
    const onUserCreated = () => {
        closeAddUserModal();
        getCharityInfo();
        actions.getUsers();
    }

    const getCharityInfo = () => {
        actions.getCharity(id).then(setCharity)
        const filteredDonations = _.filter(donations, (item) => {
            return item.charity?.id === id;
        });
        setCharityDonations(filteredDonations);
    }

    useEffect(getCharityInfo, [id])
    useEffect(() => {
        setCharityAdmins([
            ...users.filter(u => u.details?.charityId === id)
        ])
    }, [id, users])

    const extraButtons = [
        {
            title: "Edit charity",
            onClick: () => {
                history.push(`/edit-charity/${charity!.id}`)
            }
        },
        {
            title: "Delete charity",
            onClick: () => {
                setVisible(true);
            }
        },
        {
            title: "Add charity admin",
            onClick: () => {
                openAddUserModal()
            }
        },
        {
            title: "Copy Webflow URL",
            onClick: () => {
                navigator.clipboard.writeText(`https://donate.resupplyme.com/start/${charity.id}`)
                    .then(() => {
                        console.log('URL COPIED');
                    })
            }
        },
        {
            title: "Reload zone",
            onClick: () => { reloadZone(); }
        }
    ]

    const reloadZone = () => {
        const zoneRequests = charity.zones.map(zone => actions.getZone(zone.id!));
        Promise.all(zoneRequests).then(data => {
            charity.zones = data;
            actions.updateCharity(charity).then(charityData => {
                console.log(charityData);
                alert("Zone data updated.")
            })
        })
    }
    const updatePricing = (e: ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        const c = new Charity();
        Object.assign(c, charity);
        _.set(c, `pricing.${name}`, value);
        setCharity(c);
    }

    const savePricing = () => {
        setSavingPricing(true);
        actions.updateCharity(charity).then(setCharity).then(() => {setSavingPricing(false)});
    }

    const openDonationDetails = (donation: Donation) => history.push(navigationRoutes.donationDetails(donation))
    const openSecondaryDropOffDetails = () => history.push(navigationRoutes.charityDetails(charity.secondaryDropOff!))

    const renderCharityInfo = (charity: Charity, title: string) => (
        <Well>
            <FormTitle>{title}</FormTitle>
            {infoField(charity.code, "Charity ID / Code")}
            {infoField(charity.name!, "Charity name")}
            {infoField(charity.phone!, "Phone number")}
            {infoField(charity.email!, "Email")}
            {infoField(charity.address!, "Address")}
            <Row>
                <Col>
                    {infoField(charity.city!, "City")}
                </Col>
                <Col>
                    {infoField(charity.state!, "State")}
                </Col>
                <Col>
                    {infoField(charity.zip!, "Zip")}
                </Col>
            </Row>
            {charity.daysOfOperation && (
                <StyledField>
                    <Label>Working days</Label>
                    <Paragraph>Mon - {charity.daysOfOperation.mon ? 'OPEN' : 'CLOSED'}</Paragraph>
                    <Paragraph>Tue - {charity.daysOfOperation.tue ? 'OPEN' : 'CLOSED'}</Paragraph>
                    <Paragraph>Wed - {charity.daysOfOperation.wed ? 'OPEN' : 'CLOSED'}</Paragraph>
                    <Paragraph>Thu - {charity.daysOfOperation.thu ? 'OPEN' : 'CLOSED'}</Paragraph>
                    <Paragraph>Fri - {charity.daysOfOperation.fri ? 'OPEN' : 'CLOSED'}</Paragraph>
                    <Paragraph>Sat - {charity.daysOfOperation.sat ? 'OPEN' : 'CLOSED'}</Paragraph>
                    <Paragraph>Sun - {charity.daysOfOperation.sun ? 'OPEN' : 'CLOSED'}</Paragraph>
                </StyledField>
            )}

            <StyledField>
                <Label>Closed dates</Label>
                {charity.closedDates && (
                    <TagContainer>
                        {charity.closedDates.map((date, index) => (
                            <StyledTag key={`${date}-${index}`}>
                                <span>{moment(date).format("MM-DD-YYYY")}</span>
                            </StyledTag>
                        ))}
                    </TagContainer>
                )}
            </StyledField>

            {infoField(charity.closingBy!, "Closing by")}
            {infoField(charity.salesforceId!, "Salesforce ID")}
            {infoField(charity.notes, "Notes")}
            {charity.secondaryDropOff && (
                <StyledField>
                    <Label>Secondary drop-off</Label>
                    <Paragraph>{charity.secondaryDropOff.name}</Paragraph>
                    <StyledButtonWrapper>
                        <Button onClick={openSecondaryDropOffDetails}>
                            View assigned charity
                        </Button>
                    </StyledButtonWrapper>
                </StyledField>
            )}
        </Well>
    )

    // Render
    return charity ? (
        <BaseContainer showBackButton title={charity.name!} subtitle={"Charity details"}
                       extraButtons={extraButtons}>

            <Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
                <TabList>
                    <Tab item="tab-1">General</Tab>
                    <Tab item="tab-2">Donations</Tab>
                    <Tab item="tab-3">User management</Tab>
                    <Tab item="tab-4">Pickup pricing</Tab>
                </TabList>
                <TabPanel item="tab-1">
                    <>
                        <Row>
                            <Col xs={5}>
                                {renderCharityInfo(charity, "Basic information")}
                            </Col>
                        </Row>

                        {visible && (
                            <Modal onClose={() => setVisible(false)}>
                                <Header isDanger>Are you sure you want to delete this charity? </Header>
                                <Body>
                                    This action is not reversible. Please make sure you want to remove this charity
                                    record from your database.
                                </Body>
                                <Footer>
                                    <FooterItem>
                                        <Button onClick={() => setVisible(false)} isBasic>
                                            Cancel
                                        </Button>
                                    </FooterItem>
                                    <FooterItem>
                                        <Button isPrimary isDanger onClick={() => {
                                            actions.deleteCharity(charity)
                                                .then(r => {
                                                    setVisible(false)
                                                    history.goBack()
                                                })
                                        }}>Remove charity
                                        </Button>
                                    </FooterItem>
                                </Footer>
                                <Close aria-label="Close modal"/>
                            </Modal>
                        )}
                    </>
                </TabPanel>
                <TabPanel item="tab-2">
                    <Row>
                        <Col>
                            <Well>
                                <FormTitle>Donations</FormTitle>
                                <TableComponent fields={fields} onRowClicked={openDonationDetails}
                                                data={charityDonations}/>
                            </Well>
                        </Col>
                    </Row>
                </TabPanel>
                <TabPanel item="tab-3">
                    <Row>
                        <Col>
                            <Well>
                                <TableComponent fields={usersFields} onRowClicked={() => {
                                }} data={charityAdmins}/>
                            </Well>
                        </Col>
                    </Row>
                </TabPanel>
                <TabPanel item="tab-4">
                    <Row>
                        <Col xs={6}>
                            <Well>
                                <FormTitle>Pickup pricing</FormTitle>
                                <StyledField>
                                    <Label>Base price</Label>
                                    <Input
                                        name={"base"}
                                        onChange={updatePricing}
                                        value={charity.pricing.base}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Extra Large Items</Label>
                                    <Input
                                        name={"xl"}
                                        onChange={updatePricing}
                                        value={charity.pricing.xl || defaultPricing.xl}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Large Items</Label>
                                    <Input
                                        name={"lg"}
                                        onChange={updatePricing}
                                        value={charity.pricing.lg || defaultPricing.lg}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Medium Items</Label>
                                    <Input
                                        name={"md"}
                                        onChange={updatePricing}
                                        value={charity.pricing.md || defaultPricing.md}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Bags and Boxes (Small items)</Label>
                                    <Input
                                        name={"sm"}
                                        onChange={updatePricing}
                                        value={charity.pricing.sm || defaultPricing.sm}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Appliances</Label>
                                    <Input
                                        name={"appliances"}
                                        onChange={updatePricing}
                                        value={charity.pricing.appliances}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Hazardous</Label>
                                    <Input
                                        name={"hazardous"}
                                        onChange={updatePricing}
                                        value={charity.pricing.hazardous}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Staircases</Label>
                                    <Input
                                        name={"staircases"}
                                        onChange={updatePricing}
                                        value={charity.pricing.staircases}
                                    />
                                </StyledField>
                                <StyledField>
                                    <Label>Disassembly</Label>
                                    <Input
                                        name={"disassembly"}
                                        onChange={updatePricing}
                                        value={charity.pricing.disassembly}
                                    />
                                </StyledField>
                                <Button style={{minWidth: 100}} onClick={savePricing}>{savingPricing ? (<Inline/>) : "Save"}</Button>
                            </Well>
                        </Col>
                    </Row>
                </TabPanel>
            </Tabs>
            {charityModalVisible && (
                <CharityAdminCreationModal charity={charity} onClose={closeAddUserModal} onDone={onUserCreated}/>
            )}
        </BaseContainer>
    ) : (<></>)

};

const infoField = (value: string, display: string) => (
    <StyledField>
        <Label>{display}</Label>
        <Paragraph>{value}</Paragraph>
    </StyledField>
)

const StyledField = styled(Field)`
  margin-bottom: 15px;
`

const FormTitle = styled(LG)`
  margin-bottom: 10px;
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0;
  }
`

const StyledButtonWrapper = styled(StyledField)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const TagContainer = styled(Well)`
  padding: 5px;
  margin-top: 8px;
  min-height: 40px;
`
const StyledTag = styled(Tag)`
  margin: 5px;
`

