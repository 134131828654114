import {Zone} from "../domain";
import {FC} from "react";
import styled from "styled-components";
import {Tag} from "@zendeskgarden/react-tags";

type Props = {
    zones: Zone[]
}
export const ZoneTags: FC<Props> = (props) => {
    const {zones = []} = props;
    return (
        <ZonesWrapper>
            {zones.map(zone => (<Tag key={zone.id} size={"large"}>{zone.name}</Tag>))}
        </ZonesWrapper>
    )
}

const ZonesWrapper = styled.div`
  * {
    margin-right: 5px;
    margin-top: 5px;
    &:first-of-type{
      margin-top: 0;
    }
  }
`
