import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {DonationContext} from "../../context";
import {Donation} from "../../domain";
import {Title, Well} from "@zendeskgarden/react-notifications";
import {Col, Row} from "@zendeskgarden/react-grid";
import styled from "styled-components";
import {PALETTE} from "@zendeskgarden/react-theming";
import {Paragraph, XXL} from "@zendeskgarden/react-typography";
import {Space} from "../../components";
import {Button} from "@zendeskgarden/react-buttons";
import {DonationStatus} from '../../domain/Donation';

export const DropOffOverviewContainer = () => {
    const {actions} = useContext(DonationContext)
    const [donation, setDonation] = useState<Donation>()

    const params = useParams<{ donationCode: string }>()
    const {donationCode} = params

    const redirectToReSupply = () => window.location.href = "https://resupplyme.com";

    useEffect(() => {
        if (donationCode) {
            actions.getAllDonations(`donation_code=${donationCode}`).then((result) => {
                setDonation(result[0])
            })
        } else {
            redirectToReSupply()
        }
    }, [])

    const confirmDropOff = () => {
        if (donation){
            if (donation.primaryDropOffOutcome){
                donation.donationStatus = DonationStatus.completed
                donation.eventType = `donation_${DonationStatus.completed}`;
                donation.primaryDropOffOutcome.pocConfirmed = true
                actions.updateDonation(donation).then(() => {
                    redirectToReSupply()
                })
            }
        }
    }

    return donation ? (
        <Page>
            {donation.primaryDropOffOutcome && (
                <>
                    <StyledWell>
                        <XXL>Donation: {donation.donationCode}</XXL>
                    </StyledWell>
                    <Space size={20}/>
                    <StyledWell>
                        <StiledTitle>Accepted items</StiledTitle>
                        <Paragraph>Please confirm donation drop-off</Paragraph>
                        <Row>
                            {donation.primaryDropOffOutcome.acceptedItems.map((drop, index) => {
                                if (drop.type) {
                                    return (
                                        <Col xs={6} key={`item_${drop.type}_${index}`}>
                                            <DropImage>
                                                <a href={drop.photos[0]} target={"_blank"}>
                                                    <img src={drop.photos[0]} alt={"Donation item"}/>
                                                </a>
                                            </DropImage>
                                        </Col>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </Row>

                        <Space size={20}/>
                        <Row>
                            <Col>
                                <StyledButtons>
                                    <Button
                                        onClick={confirmDropOff}
                                        isStretched
                                        isPrimary>Confirm</Button>
                                </StyledButtons>
                            </Col>
                        </Row>
                    </StyledWell>
                </>
            )}
        </Page>
    ) : <></>
}

const Page = styled.div`
  padding: 20px;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  align-items: center;
`

const StyledWell = styled(Well)`
  max-width: 600px;
  width: 100%;
`
const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StiledTitle = styled(Title)``

const DropImage = styled.div`
  border: 1px solid ${PALETTE.grey["300"]};
  margin: 10px 0;
  width: 100%;
  height: 150px;
  text-align: center;

  img {
    height: 100%;
    object-fit: contain;
  }
`
