import React, {useEffect, useState} from "react";
import {UserContext} from "../context";
import Api, {method} from "../services/api.service";
import {routes} from "../services/api.routes";
import {AuthRequest, AuthResponse, User} from "../domain";

type Props = {
    children: any
}

export const UserProvider = (props: Props) => {
    const [authData, setAuthData] = useState<AuthResponse>()
    const [users, setUsers] = useState<User[]>([])
    const [user, setUser] = useState<User>(new User())

    const [dataRetrieved, setDataRetrieved] = useState<boolean>(false);

    useEffect(() => {
        const authDataJson = localStorage.getItem('auth');
        if (authDataJson) {
            let authDataParsed = JSON.parse(authDataJson);
            setAuthData(authDataParsed)
            setUser(authDataParsed)
            Api.setToken(authDataParsed.authorization.token)
        }
        setDataRetrieved(true);
    }, [])

    useEffect(() => {
        if (authData && authData!.authorization) {
            localStorage.setItem('auth', JSON.stringify(authData))
            Api.setToken(authData!.authorization.token)
        }
    }, [authData])

    const authenticate = (authRequest: AuthRequest, restrict?: string[] ) => {
        return Api.$<AuthResponse>(routes.authenticate)
            .call(method.post, authRequest)
            .then((result: AuthResponse) => {
                if (restrict){
                    if (restrict.includes(result.role!)) {
                        Api.setToken(result.authorization!.token)
                        setAuthData(result);
                    }else{
                        throw new Error("Authorization error")
                    }
                }else{
                    Api.setToken(result.authorization!.token)
                    setAuthData(result);
                }
                return result;
            });
    }

    const logout = () => {
        Api.setToken('');
        setAuthData({} as AuthResponse);
        localStorage.clear();
    }

    const getUserData = () => {
        if (authData!.id) {
            Api.$(routes.users).get(authData!.id).then(result => console.log(result))
        }
    }
    const getUsers = () => {
        Api.$<User>(routes.users).getAll().then(setUsers);
    }

    const createUser = (user: User) : Promise<User> => {
        return Api.$<User>(routes.users).create(user)
    }

    const getUser = (id: string) => {
        Api.$<User>(routes.users).get(id).then(setUser)
    }

    const resetUser = () => {
        setUser(new User());
    }

    return (
        <UserContext.Provider value={
            {
                authData: authData!,
                setAuthData,
                users,
                setUsers,
                user,
                setUser,
                actions: {
                    authenticate,
                    logout,
                    getUserData,
                    getUsers,
                    createUser,
                    getUser,
                    resetUser
                }
            }}>
            {dataRetrieved ? (props.children) : (<></>)}
        </UserContext.Provider>
    )
}
