import React from "react";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {MD, XXL} from "@zendeskgarden/react-typography";
import styled from "styled-components";
import {IconButton} from "@zendeskgarden/react-buttons";
import {ReactComponent as BackIcon} from "../../assets/icons/long-arrow-left-light.svg";
import {useHistory} from "react-router-dom";
import {mediaQuery} from '@zendeskgarden/react-theming';

type Props = {
    title: string,
    subtitle: string
    children: JSX.Element
    showBackButton?: boolean
}
export const BaseContainer = (props: Props) => {
    const history = useHistory();
    const {title, subtitle, showBackButton} = props;

    const goBack = () => {
        history.goBack()
    }

    return (
        <Grid>
            <Row>
                <HorizontalOrientedColumn>
                    {showBackButton && (
                        <StyledIconButton
                            onClick={goBack}
                            aria-label="square back" isBasic={false} isPill={false}>
                            <BackIcon/>
                        </StyledIconButton>
                    )}
                    <ContainerTitle showBackButton={showBackButton}>
                        <XXL isBold>{title}</XXL>
                        <MD>{subtitle}</MD>
                    </ContainerTitle>
                </HorizontalOrientedColumn>
            </Row>
            <Row>
                <Col>
                    <InnerContainer>
                        {props.children}
                    </InnerContainer>
                </Col>
            </Row>
        </Grid>
    )
}

const InnerContainer = styled.div`
  margin-top: 30px;
`
const HorizontalOrientedColumn = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
   ${p => mediaQuery('down', 'md', p.theme)} {
    justify-content: start;
    align-items: start;
  }
`

const ContainerTitle = styled.div<{ showBackButton?: boolean }>`
  color: #2c3b64;
  text-align: center;
  
  ${p => mediaQuery('down', 'md', p.theme)} {
    text-align: ${props => props.showBackButton ? 'left' : 'center'};
    padding-left: ${props => props.showBackButton ? '10px' : '0'};
  }
`
const StyledIconButton = styled(IconButton)`
  position: absolute;
  left: 0;
  margin-top: 6px;
  margin-left: 10px;
  ${p => mediaQuery('down', 'md', p.theme)} {
    position: static;
    margin-left: 0;
  }
`
