import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object()
	.shape({
		name: yup.string().required('Name is required'),
		licensePlate: yup.string().required('License plate number is required'),
		tplOrganizationId: yup.string().required('Trailer must be assigned to a 3PL'),
	});

export const trailerResolver = yupResolver(schema);
