import {createContext} from "react";
import {Charity, Donation} from "../domain";
import {CharitySlots} from "../domain/CharitySlots";


export type DonorContextType = {
    donationData: Donation
    setDonationData: (data: Donation) => void
    actions: {
        getCharity: (id: string) => Promise<Charity>
        submitDonation: (data: Donation) => Promise<Donation>
    }

}

export const DonorContext = createContext<DonorContextType>(
    {} as DonorContextType
)
