import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {BaseContainer} from "../base.container";
import {Well} from "@zendeskgarden/react-notifications";
import {Col, Row} from "@zendeskgarden/react-grid";
import styled from "styled-components";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {LG} from "@zendeskgarden/react-typography";
import {Dropdown, Field as DropdownField, Item, Label as DropdownLabel, Menu, Select} from '@zendeskgarden/react-dropdowns';
import _ from 'lodash'
import {Charity, Donation, Zone} from "../../domain";
import {CounterItem} from "../../components/counter-item.c";
import {AutocompleteField} from "../../components/auto-complete-field";
import {useHistory, useParams} from "react-router-dom";
import {EntityRouteParams} from '../../types';
import {closedDays} from '../../utility/closedDays';
import moment from 'moment';
import {DayPicker, Space} from '../../components';
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {removeKeys} from "../../utility/remove-keys";
import {extraButton} from "../../utility/extraButton";

const items = [
    {label: 'am', value: 'am'},
    {label: 'pm', value: 'pm'}
];

export const CreateDonationContainer = () => {
    const {charities, actions} = useContext(CaptainScopeContext);
    const [donation, setDonation] = useState<Donation>(new Donation())

    const [selectedItem, setSelectedItem] = useState(items[0]);
    const [matchedCharities, setMatchedCharities] = useState<Charity[]>([])
    const history = useHistory();

    // Used if Component is in EDIT mode
    const [mode, setMode] = useState('new')
    const params = useParams<EntityRouteParams>()
    const {id} = params;

    useEffect(() => {
        if (id) {
            setMode('edit')
            actions.getDonation(id).then(setDonation)
        } else {
            setDonation(new Donation());
        }
        // Todo: check if we even need this anymore since we are using a donation instance inside a local scope
        return () => {
            setDonation(new Donation());
        }
    }, [])

    const updateDonationEntry = (name: any, value: any) => {
        const d = new Donation();
        Object.assign(d, donation);
        _.set(d, name, value);
        setDonation(d);
    }

    const footerButtons = [
        extraButton('Save changes', () => {
            saveChanges()
        })
    ]

    const updateField = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value, name} = e.target;
        updateDonationEntry(name, value);
    }

    const updateEstimate = (field: string, count: number) => {
        updateDonationEntry(`spec.${field}`, count);
        updateDonationEntry('costEstimate', Donation.getEstimate(donation!.spec, donation!.pricing!));
    }

    const goBack = () => history.goBack();
    const resolveCharityName = (c: Charity) => {
        if (matchedCharities.includes(c)) {
            return c ? c.name + ' - IN ZONE' : 'No charity specified';
        } else {
            return c ? c.name : 'No charity specified';
        }

    }
    const updateCharityField = (c: Charity) => updateDonationEntry('charity', c);


    const createDonation = () => {
        removeKeys(donation.primaryDropOff, ['primaryDropOff', 'secondaryDropOff'])
        removeKeys(donation.secondaryDropOff, ['primaryDropOff', 'secondaryDropOff'])
        removeKeys(donation, ['zones'])
        actions.createDonation(donation!).then(goBack)
    };
    const updateDonation = () => {
        removeKeys(donation.primaryDropOff, ['primaryDropOff', 'secondaryDropOff'])
        removeKeys(donation.secondaryDropOff, ['primaryDropOff', 'secondaryDropOff'])
        removeKeys(donation, ['zones'])
        donation.eventType = 'donation_edited_by_captain'
        actions.updateDonation(donation!).then(goBack)
    };

    const saveChanges = () => {
        if (mode === 'new') {
            createDonation()
        } else {
            updateDonation()
        }
    }
    const getTitle = () => mode === 'new' ? 'Create donation' : 'Update donation';

    const disabledDays = [
        {
            daysOfWeek: closedDays(donation!.charity?.daysOfOperation)
        },
        {
            before: moment(new Date()).toDate()
        }
    ];


    useEffect(() => {
        if (donation && charities.length >0){
            const zip = donation.donor.zip
            const inZoneCharities = charities.filter(charity => charity.zones.reduce((p: boolean,c:Zone) => {
                return p || c.zips.includes(zip!);
            }, false))
            setMatchedCharities(inZoneCharities)
        }
    }, [charities, donation])

    useEffect(() => {
        if (selectedItem){
            updateDonationEntry('partOfDay', selectedItem.value);
        }
    }, [selectedItem])

    return (donation.id || mode === 'new') ? (
        <BaseContainer
            showBackButton
            footerButtons={footerButtons}
            title={getTitle()}
            subtitle={'Manual donation entry'}>
            <Row>
                <Col md={7}>
                    <Well>
                        <FormTitle>Donor information</FormTitle>
                        <StyledField>
                            <Label>Donor name</Label>
                            <Input name={'donor.name'} onChange={updateField} value={donation.donor.name}/>
                        </StyledField>
                        <StyledField>
                            <Label>Address</Label>
                            <Input value={donation.donor.address} name={'donor.address'} onChange={updateField}/>
                        </StyledField>

                        <Row>
                            <Col>
                                <StyledField>
                                    <Label>City</Label>
                                    <Input name={'donor.city'} onChange={updateField} value={donation.donor.city}/>
                                </StyledField>
                            </Col>
                            <Col>
                                <StyledField>
                                    <Label>State</Label>
                                    <Input name={'donor.state'} onChange={updateField} value={donation.donor.state}/>
                                </StyledField>
                            </Col>
                            <Col>
                                <StyledField>
                                    <Label>Zip</Label>
                                    <Input name={'donor.zip'} onChange={updateField} value={donation.donor.zip}/>
                                </StyledField>
                            </Col>
                        </Row>

                        <StyledField>
                            <Label>Email</Label>
                            <Input name={'donor.email'} onChange={updateField} value={donation.donor.email}/>
                        </StyledField>
                        <StyledField>
                            <Label>Phone</Label>
                            <Input name={'donor.phone'} onChange={updateField} value={donation.donor.phone}/>
                        </StyledField>

                        <FormTitle>Schedule the donation</FormTitle>
                        <StyledField>
                            <AutocompleteField
                                label={"Pick a charity"}
                                options={charities}
                                value={donation.charity}
                                valueResolver={resolveCharityName}
                                onValueSelected={updateCharityField}
                            />
                        </StyledField>
                        <Row>
                            <Col><StyledField>
                                <Label>Date</Label>
                                <Space size={8}/>
                                {donation.charity ? <DayPicker
                                    onChange={updateDonationEntry}
                                    placeholder={'Choose a date'}
                                    name={'date'}
                                    value={donation.date}
                                    disabledDays={disabledDays}/> : <StyledDatepickerDisabled>Please pick a charity first</StyledDatepickerDisabled>}
                            </StyledField>

                            </Col>
                            <Col>
                                <Dropdown
                                    selectedItem={selectedItem}
                                    onSelect={setSelectedItem}
                                    downshiftProps={{itemToString: (item: any) => item && item.label}}
                                >
                                    <DropdownField>
                                        <DropdownLabel>Part of day</DropdownLabel>
                                        <Select>{selectedItem.label}</Select>
                                    </DropdownField>
                                    <Menu>
                                        {items.map(option => (
                                            <Item key={option.value} value={option}>
                                                {option.label}
                                            </Item>
                                        ))}
                                    </Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Well>
                </Col>

                <Col md={5}>
                    <Well>
                        <FormTitle>Create the estimate</FormTitle>

                        <CounterItem value={donation.spec.xl} title={"Extra Large items"} field={'xl'}
                                     onChange={updateEstimate}/>
                        <CounterItem value={donation.spec.lg} title={"Large items"} field={'lg'}
                                     onChange={updateEstimate}/>
                        <CounterItem value={donation.spec.md} title={"Medium items"} field={'md'}
                                     onChange={updateEstimate}/>
                        <CounterItem value={donation.spec.sm} title={"Bags&Boxes"} field={'sm'}
                                     onChange={updateEstimate}/>
                        <CounterItem value={donation.spec.staircases} title={"Staircases"} field={'staircases'}
                                     onChange={updateEstimate}/>
                        <CounterItem value={donation.spec.disassembly} title={"Disassembly"} field={'disassembly'}
                                     onChange={updateEstimate}/>

                        <hr/>
                        <FormTitle>Estimated cost ${Donation.getEstimate(donation.spec, donation.pricing!)}</FormTitle>

                    </Well>
                </Col>
            </Row>
        </BaseContainer>
    ) : <></>
}

const StyledField = styled(Field)`
  margin-bottom: 15px;
`

const FormTitle = styled(LG)`
  margin-bottom: 10px;
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0;
  }
`

const StyledButtonWrapper = styled(StyledField)`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StyledDatepickerDisabled = styled(StyledField)`
  border: 1px solid #dadada;
  padding: 9px 10px;
  border-radius: 4px;
`
