import React from 'react';
import {ThemeProvider} from '@zendeskgarden/react-theming';
import Api from "./services/api.service";
import appSlice from "./appSlice";
import CaptainApp from "./slices/CaptainApp";
import CharityApp from "./slices/CharityApp";
import DriverApp from "./slices/DriverApp";
import DonorApp from "./slices/DonorApp";
import {AppProvider} from "./providers/app.provider";
import {IntercomProvider} from 'react-use-intercom';
import ReactGA from 'react-ga';
import TplApp from "./slices/TplApp";

Api.configure('https://architect.rspl.dev')

const TRACKING_ID = "UA-91582377-5";
ReactGA.initialize(TRACKING_ID);

const INTERCOM_APP_ID = 'fomhra8n';

const App = () => {
    return (
        <ThemeProvider>
            <AppProvider>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                    {appSlice.captain && (<CaptainApp/>)}
                    {appSlice.charity && (<CharityApp/>)}
                    {appSlice.driver && (<DriverApp/>)}
                    {appSlice.donor && (<DonorApp/>)}
                    {appSlice.tpl && (<TplApp/>)}
                </IntercomProvider>
            </AppProvider>
        </ThemeProvider>

    );
}

export default App;
