import React, {FC} from "react";
import {Row as GardenRow, Col as GardenCol} from "@zendeskgarden/react-grid";

export const Col:FC = ({children}) => {
	return (
		<GardenRow>
			<GardenCol>
				{children}
			</GardenCol>
		</GardenRow>
	)
}
