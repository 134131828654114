import {Donation} from "../domain";
import {XXL} from "@zendeskgarden/react-typography";
import {Well} from "@zendeskgarden/react-notifications";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import {Col, Row} from "@zendeskgarden/react-grid";
import {PALETTE} from "@zendeskgarden/react-theming";
import {Box} from "./box.c";
import {Space} from "./space";

type Props = {
    donation: Donation
    title?: string
    spacing?: number,
    hideSlot?: boolean
}

export const DonationDateWidget = (props: Props) => {
    const {donation, title = "Date", spacing = 5, hideSlot = false} = props;

    const getSlotText = () => {
        switch (donation.timeSlot) {
            case 'slot1':
                return '8am - 10am'
            case 'slot2':
                return '10am - 12pm'
            case 'slot3':
                return '12pm - 2pm'
            case 'slot4':
                return '2pm - 4pm'
            default:
                return 'Unassigned'
        }
    }
    return (
        <StyledWell style={{marginTop: spacing}}>
            <Row>
                <StyledCol>
                    <Box>
                        Requested date
                        <XXL>{moment(donation.date).format("MM/DD/YYYY")}</XXL>
                    </Box>
                </StyledCol>
                <StyledCol>
                    <Box>
                        Requested part of day
                        <XXL>
                            {donation.partOfDay.toUpperCase() || 'Not specified'}
                        </XXL>
                    </Box>
                </StyledCol>
            </Row>
            <Space size={10}/>
            <Row>
                {!hideSlot && (
                    <StyledCol>
                        <Box>
                            Slot assigned
                            <XXL>
                                {getSlotText()}
                            </XXL>
                        </Box>
                    </StyledCol>
                )}
            </Row>

        </StyledWell>
    )
}
const StyledWell = styled(Well)`
  padding: 0;
  border: none;
`
const StyledCol = styled(Col)``
