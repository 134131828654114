import {BaseContainer} from "../base.container";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import moment from "moment";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {Box} from "../../components/box.c";
import {Col, Row} from "@zendeskgarden/react-grid";
import {FormField, Space} from "../../components";
import {Label} from "@zendeskgarden/react-forms";
import {DatePicker} from "../../components/date-picker.c";
import _ from "lodash";
import {DonationsTable} from "../../components/donations-table.c";
import {Donation} from "../../domain";
import {useHistory} from "react-router-dom";

type Filter = {
    tpl: string
}

export const LogisticsContainer = () => {
    const history = useHistory();
    const {donations, actions, partners} = useContext(CaptainScopeContext);

    const [localFilter, setLocalFilter] = useState<Filter>({tpl: ''});
    const [dateFilter, setDateFilter] = useState<string | undefined>(moment(new Date).format('MM-DD-YYYY'))
    const [donationsBy3pl, setDonationsBy3pl] = useState<any>({})


    const tplNameFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value} = e.target
        setLocalFilter({
            ...localFilter,
            tpl: value
        })
    }

    const openDonationDetailsView = (data: Donation) => {
        window.open(`/donations/${data!.id}`)
    }

    const donationDateFilterChanged = (name: string, date: string | undefined) => {
        setDateFilter(date);
    }

    const getPartnerName = (id: string) => {
        return partners.find(p => p.id === id)?.name
    }

    const previewServiceRequest = (id: string) => {
        history.push(`/service-request/${id}/${dateFilter}`)
    }

    useEffect(() => {
        if (donations.length > 0 && partners.length > 0) {
            const assignedDonations = donations.filter(donation => Boolean(donation.tplOrganizationId));
            const groupedDonations = _.groupBy(assignedDonations, (donation) => donation.tplOrganizationId);
            setDonationsBy3pl(groupedDonations);
        }
    }, [partners, donations])

    useEffect(() => {
        if (dateFilter) {
            actions.getDonations(`date=${dateFilter}`)
        }
    }, [dateFilter])

    return (
        <BaseContainer title={"Logistics"} subtitle={"Donations by 3PL by date"}>
            <Box>
                <Row>
                    <Col>
                        <FormField value={localFilter.tpl} label={"Search by 3PL"} name={"tpl"} onChange={tplNameFilterChanged}/>
                    </Col>
                    <Col>
                        <Label>Donation date</Label>
                        <DatePicker name={'date'} value={dateFilter} onChange={donationDateFilterChanged}/>
                    </Col>
                </Row>
            </Box>

            {Object.keys(donationsBy3pl)
                .filter(key => (getPartnerName(key)!.toLowerCase().includes(localFilter.tpl.toLowerCase())))
                .sort((a,b) => getPartnerName(a)! > getPartnerName(b)! ? 1 : -1)
                .map(key => (
                <div key={key}>
                    <Space size={20}/>
                    <Box title={getPartnerName(key)} buttons={[{
                        title: 'Preview Service Request', onClick: () => {
                            previewServiceRequest(key)
                        }
                    }]}>
                        <DonationsTable donations={donationsBy3pl[key]} onDonationSelected={openDonationDetailsView}/>
                    </Box>
                </div>
            ))}

        </BaseContainer>
    )
}
