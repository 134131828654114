import React, {useContext, useEffect} from 'react';
import {ThemeProvider} from '@zendeskgarden/react-theming';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {DonationProvider, UserProvider, ZoneProvider} from "../providers";
import {CharityProvider} from "../providers/charity.provider";
import {PartnerProvider} from "../providers/partner.provider";
import {Charity} from '../containers/charity';
import {charityTheme} from "../themes/charity.theme";
import {CharityScopeProvider} from "../providers/charity-scope.provider";
import {GuardedRoute} from "../components/guarded-route.c";
import {AppContext} from "../context";

const CharityApp = () => {
    const {user, actions} = useContext(AppContext);

    useEffect(() => {
        if (user){
            actions.trackUserActivity()
        }
    }, [user])

    return (
        <ThemeProvider focusVisibleRef={null} theme={charityTheme as any}>
            <UserProvider>
                <CharityProvider>
                    <PartnerProvider>
                        <CharityScopeProvider>
                            <DonationProvider>
                                <ZoneProvider>
                                    <Router>
                                        <Switch>
                                            <Route exact path={'/access'} component={Charity.LoginContainer}/>
                                            <Route exact path={'/d/:donationCode'}
                                                   component={Charity.DropOffOverviewContainer}/>

                                            <GuardedRoute path={'/'} component={Charity.HomeContainer}/>
                                        </Switch>
                                    </Router>
                                </ZoneProvider>
                            </DonationProvider>
                        </CharityScopeProvider>
                    </PartnerProvider>
                </CharityProvider>
            </UserProvider>
        </ThemeProvider>

    );
}

export default CharityApp;
