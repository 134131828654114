import React from 'react';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Donor} from '../containers/donor';
import {DonorProvider} from "../providers/donor.provider";
import {CharityProvider} from "../providers/charity.provider";
import {DonationProvider} from "../providers";
import Api from "../services/api.service";
import {Helmet} from "react-helmet";

Api.configure('https://architect.rspl.dev')
const DonorApp = () => {

	return (
		<Router>
			<DonorProvider>
				<CharityProvider>
					<DonationProvider>
						<Helmet>
							<meta charSet="utf-8" />
							<title>ReSupply</title>
							<script async src="https://www.googletagmanager.com/gtag/js?id=AW-860997040"/>
							<script>
								{
									`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
									new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
									j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
									'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
									})(window,document,'script','dataLayer','GTM-TM3LTG7');`
								}
							</script>
							<script>
								{
									`
									(function(h,o,t,j,a,r){
									h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
									h._hjSettings={hjid:2570518,hjsv:6};
									a=o.getElementsByTagName('head')[0];
									r=o.createElement('script');r.async=1;
									r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
									a.appendChild(r);
									})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
									`
								}
							</script>
						</Helmet>
						<Switch>
							<Route path={'/'} component={Donor.HomeContainer}/>
						</Switch>
					</DonationProvider>
				</CharityProvider>
			</DonorProvider>
		</Router>

	);
}

export default DonorApp;


