import {DonationContainer} from "./donation.container";
import {DonationsFunnelContainer} from "./donations-funnel.container";
import {LoginContainer} from "./login.container";
import {DropOffOverviewContainer} from "./drop-off-overview.container";
import {HomeContainer} from "./home.container";

export const Charity = {
    HomeContainer,
    DonationsFunnelContainer,
    DonationContainer,
    LoginContainer,
    DropOffOverviewContainer
}
