import React, {useEffect, useState} from "react";
import {CaptainScopeContext, CaptainScopeDataLoading} from "../context/captain-scope.context";
import {Charity, Donation, TPLOrganization, User, Zone} from "../domain";
import Api from "../services/api.service";
import {routes} from "../services/api.routes";

import {actions as commonActions} from './actions';
import {useLocation} from "react-router-dom";
import {CharitySlots} from "../domain/CharitySlots";
// @ts-ignore
import {architect} from "../services/architect.service";
import {Trailer, Truck} from "../domain/schema";
import {DonationStatus} from "../domain/Donation";
import {HistoryEvent} from "../domain/schema/historyEvent";

type Props = {
    children: React.ReactNode
}


export const CaptainScopeProvider = (props: Props) => {
    const location = useLocation()
    const [charities, setCharities] = useState<Charity[]>([])
    const [donations, setDonations] = useState<Donation[]>([])
    const [users, setUsers] = useState<User[]>([])
    const [partners, setPartners] = useState<TPLOrganization[]>([])
    const [zones, setZones] = useState<Zone[]>([])
    const [trucks, setTrucks] = useState<Truck[]>([])
    const [trailers, setTrailers] = useState<Trailer[]>([])

    const [loading, setLoading] = useState<CaptainScopeDataLoading>({
        isCharitiesLoading: false,
        isDonationsLoading: false
    })

    const getDonations = (q?: string): Promise<Donation[]> => {
        setLoading({
            ...loading,
            isDonationsLoading: true
        })
        return Api.$<Donation>(routes.donations).getAll(q)
            .then(data => {
                setDonations(data.filter(d => d.donationStatus !== DonationStatus.awaiting_card_on_file));
                setLoading({
                    ...loading,
                    isDonationsLoading: false
                })
                return data;
            })
    }
    const getCharities = (q?: string): Promise<Charity[]> => {
        setLoading({
            ...loading,
            isDonationsLoading: true
        })
        return Api.$<Charity>(routes.charities).getAll(q)
            .then(data => {
                setCharities(data);
                setLoading({
                    ...loading,
                    isCharitiesLoading: false
                })
                return data;
            })
    }
    const getUsers = (): Promise<User[]> => {
        return Api.$<User>(routes.users).getAll()
            .then(data => {
                setUsers(data);
                return data;
            })
    }
    const getPartners = (): Promise<TPLOrganization[]> => {
        return Api.$<TPLOrganization>(routes.partners).getAll()
            .then(data => {
                setPartners(data);
                return data;
            })
    }
    const getZones = (): Promise<Zone[]> => {
        return Api.$<Zone>(routes.zones).getAll()
            .then(data => {
                setZones(data);
                return data;
            })
    }

    const getSlots = (q: string): Promise<CharitySlots[]> => {
        return Api.$<CharitySlots>(routes.availability).getAll(q)
    }

    const getTrucks = () => {
        architect.trucks.getAll().then(setTrucks);
    }


    const getTrailers = () => {
        architect.trailers.getAll().then(setTrailers);
    }

    const updateCharityAvailability = (basedOnDonation: Donation) =>{
        return Api.$(routes.updateAvailability).create({donationId: basedOnDonation.id})
    }

    const getDonationHistory = (id:string) => {
        return Api.$<HistoryEvent<Donation>[]>(`${routes.donations}/${id}/history`).call("get", null)
    }


    const actions = {
        getDonations,
        getCharities,
        getPartners,
        getUsers,
        getZones,

        getSlots,
        updateCharityAvailability,

        getDonation: commonActions.donations.getAction,
        getDonationHistory,
        createDonation: commonActions.donations.createAction,
        updateDonation: commonActions.donations.updateAction,

        getCharity: commonActions.charities.getAction,
        createCharity: commonActions.charities.createAction,
        updateCharity: commonActions.charities.updateAction,
        deleteCharity: commonActions.charities.deleteAction,

        getUser: commonActions.users.getAction,
        createUser: commonActions.users.createAction,
        updateUser: commonActions.users.updateAction,

        getPartner: commonActions.partners.getAction,
        createPartner: commonActions.partners.createAction,
        updatePartner: commonActions.partners.updateAction,

        updateSlot: commonActions.slots.updateAction,
        createSlot: commonActions.slots.createAction,

        getZone: commonActions.zones.getAction,
        createZone: commonActions.zones.createAction,
        updateZone: commonActions.zones.updateAction,

        createTruck: (data: Partial<Truck>) => {
            return architect.trucks.create(data)
                .finally(getTrucks)
        },
        updateTruck: (data: Partial<Truck>) => {
            return architect.trucks.update(data.id!, data)
                .finally(getTrucks)
        },
        createTrailer: (data: Partial<Trailer>) => {
            return architect.trailers.create(data)
                .finally(getTrailers)
        },
        updateTrailer: (data: Partial<Trailer>) => {
            return architect.trailers.update(data.id!, data)
                .finally(getTrailers)
        },
        deleteTruck: (data: Partial<Truck>) => {
            architect.trucks.delete(data.id!)
                .finally(getTrucks)
        },
        deleteTrailer: (data: Partial<Trailer>) => {
            architect.trailers.delete(data.id!)
                .finally(getTrailers)
        }
    }
    const meta = {
        loading
    }

    useEffect(() => {
        // getDonations();
        getPartners();
        getCharities();
        getUsers();
        getZones()
    }, [])

    useEffect(() => {
        switch (location.pathname) {
            case '':
                // getDonations();
                return;
            case '/charities':
                getCharities();
                return;
            case '/users':
                getUsers();
                return;
            case '/partners':
                getPartners();
                return;
        }
    }, [location])

    return (
        <CaptainScopeContext.Provider value={
            {
                charities,
                setCharities,

                donations,
                setDonations,

                users,
                setUsers,

                partners,
                setPartners,

                zones,

                actions,
                resources: architect,
                meta
            }
        }>
            {props.children}
        </CaptainScopeContext.Provider>
    )
}
