import {createContext} from "react";
import {Donation} from "../domain";

export type CharityScopeDataLoading = {
    isDonationsLoading: boolean
}

export type CharityScopeContextActionsType = {
    getAllDonations: (q?: string) => Promise<Donation[]>
    getDonation: (id: string) => Promise<Donation>
}

export type CharityScopeContextMetaType = {
    loading: CharityScopeDataLoading
}

export type CharityScopeContextType = {
    donations: Donation[]
    setDonations: (data: Donation[]) => void

    donation: Donation
    setDonation: (data: Donation) => void

    actions: CharityScopeContextActionsType
    meta: CharityScopeContextMetaType

}

export const CharityScopeContext = createContext<CharityScopeContextType>(
    {} as CharityScopeContextType
)
