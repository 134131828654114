import {BaseContainer} from "../base.container";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Box} from "../../components/box.c";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {CaptainScopeContext} from "../../context/captain-scope.context";
import {Charity, Donation} from "../../domain";
import {FormField, Space} from "../../components";
import {Title, Well} from "@zendeskgarden/react-notifications";
import {DatePicker} from "../../components/date-picker.c";
import {Label} from "@zendeskgarden/react-forms";
import moment from "moment";
import {Button} from "@zendeskgarden/react-buttons";
import {AvailabilityBox} from "../../components/availability-box.c";
import {CharitySlots} from "../../domain/CharitySlots";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import _ from "lodash";
import {DonationsTable} from "../../components/donations-table.c";


export const AvailabilityContainer = () => {
    const {charities, actions} = useContext(CaptainScopeContext)

    const [matchedCharities, setMatchedCharities] = useState<Charity[]>([])
    const [pickedDate, setPickedDate] = useState<string | undefined>(moment(new Date()).format("MM-DD-YYYY"))
    const [donationsPerCharity, setDonationsPerCharity] = useState<any>({})
    const [slotsPerCharity, setSlotsPerCharity] = useState<any>({})
    const [allSlots, setAllSlots] = useState<CharitySlots[]>([])
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCharity, setSelectedCharity] = useState<Charity>()

    const [adjustedAm, setAdjustedAm] = useState<string>()
    const [adjustedPm, setAdjustedPm] = useState<string>()

    const [expandedCharity, setExpandedCharity] = useState<Charity>()

    const onCharityFilterChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const term = e.target.value;
        setMatchedCharities(
            [...charities.filter(charity => {
                return term.split(' ').reduce((p: boolean, c: string) => {
                    return p && charity.name.toLowerCase().includes(c.toLowerCase());
                }, true);
            })]
        )
    }

    const onDateChanged = (field: string, value?: string) => {
        setPickedDate(value);
    }

    const onAvailabilityChangeButtonClicked = (charity: Charity) => {
        setSelectedCharity(charity);
        setModalVisible(true);
    }

    const changeAdjustedSlots = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {value, name} = e.target
        if (name === 'slotsAm') {
            setAdjustedAm(value);
        } else {
            setAdjustedPm(value)
        }
    }

    const saveAdjustedSlots = () => {
        if (selectedCharity) {
            const targetSlot = allSlots.find((slot) => slot.charityId == selectedCharity.id)
            if (targetSlot) {
                targetSlot.slotsAm = adjustedAm || targetSlot.slotsAm
                targetSlot.slotsPm = adjustedPm || targetSlot.slotsPm
                actions.updateSlot(targetSlot).then(() => {
                    loadData();
                    setModalVisible(false)
                })
            } else {
                const slot = {
                    charityId: selectedCharity.id,
                    date: pickedDate,
                    slotsAm: adjustedAm || '0',
                    slotsPm: adjustedPm || '0'
                }
                actions.createSlot(slot as CharitySlots).then(() => {
                    loadData();
                    setModalVisible(false)
                })
            }
        }
    }

    const loadData = () => {
        actions.getDonations(`date=${pickedDate}`).then(donations => {
            const sortedDonations = _.sortBy(donations, "createdAt")
            const calc = sortedDonations.reduce((p: any, c: Donation) => {
                if (c.donationStatus !== 'canceled' && c.donationStatus !== 'awaiting_card_on_file') {
                    const charityId = c.charity?.id!;
                    const partOfDay = c.partOfDay || 'am';

                    const key = `${charityId}_${partOfDay}`
                    if (p[key]) {
                        p[key] = [...p[key], c]
                    } else {
                        p[key] = [c]
                    }
                }
                return {...p}
            }, {});
            setDonationsPerCharity(calc)

        })

        actions.getSlots(`date=${pickedDate}`).then(slots => {
            setAllSlots(slots)
            const calc = slots.reduce((p: any, c: CharitySlots) => {
                const amKey = `${c.charityId}_am`;
                const pmKey = `${c.charityId}_pm`;
                p[amKey] = c.slotsAm
                p[pmKey] = c.slotsPm
                return {...p}
            }, {})
            setSlotsPerCharity(calc)
        })
    }

    const onSlotClicked = (donation: Donation) => {
        window.open(`/donations/${donation.id}`)
    }

    const toggleDonationsView = (charity: Charity) => {
        if (expandedCharity && expandedCharity.id === charity.id) {
            setExpandedCharity(undefined)
        } else {
            setExpandedCharity(charity)
        }

    }

    useEffect(() => {
        setMatchedCharities(charities)
    }, [charities])

    useEffect(() => {
        if (!modalVisible) {
            setSelectedCharity(undefined)
        }
    }, [modalVisible])

    useEffect(() => {
        loadData()
    }, [pickedDate])


    return (
        <BaseContainer title={"Master availability"} subtitle={"Specify open slots per charity"}>
            <Well>
                <Row>
                    <Col>
                        <Label>Date</Label>
                        <DatePicker value={pickedDate} name={'date'} onChange={onDateChanged}/>
                    </Col>
                    <Col>
                        <FormField label={"Charity filter"} onChange={onCharityFilterChanged} name={'charity'}/>
                    </Col>
                </Row>
            </Well>
            <Space size={10}/>
            <Row>
                <Col>
                    <Box title={"Availability per charity"}>
                        {matchedCharities.map(charity => (
                            <div key={charity.id}>
                                <Box title={charity.name} key={charity.id}>
                                    <Row>
                                        <Col xs={4}>
                                            <Space size={18}/>
                                            <Row>
                                                <Col>
                                                    <Button onClick={() => {
                                                        onAvailabilityChangeButtonClicked(charity)
                                                    }}>Adjust availability</Button>
                                                </Col>
                                            </Row>

                                            <Space size={10}/>

                                            <Row>
                                                <Col>
                                                    <Button onClick={() => {
                                                        toggleDonationsView(charity)
                                                    }}>
                                                        {expandedCharity && expandedCharity.id === charity.id ?
                                                            'Collapse' : 'Expand'
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col>
                                            <Title>AM</Title>
                                            <AvailabilityBox
                                                onSlotClicked={onSlotClicked}
                                                allocated={slotsPerCharity[`${charity.id!}_am`] | 0}
                                                filled={donationsPerCharity[`${charity.id!}_am`]}
                                            />
                                            <Space size={10}/>
                                            <Title>PM</Title>
                                            <AvailabilityBox
                                                onSlotClicked={onSlotClicked}
                                                allocated={slotsPerCharity[`${charity.id!}_pm`] | 0}
                                                filled={donationsPerCharity[`${charity.id!}_pm`]}/>
                                        </Col>
                                    </Row>
                                    {
                                        (expandedCharity && charity.id === expandedCharity.id) && (
                                            <Row>
                                                <Col>
                                                    <Space size={30}/>
                                                    <Box title={"Donations"}>
                                                        <DonationsTable
                                                            donations={[
                                                                ...donationsPerCharity[`${charity.id!}_am`] || [],
                                                                ...donationsPerCharity[`${charity.id!}_pm`] || []
                                                            ]}
                                                            configuration={"masterSchedule"}
                                                            onDonationSelected={onSlotClicked}
                                                        />
                                                    </Box>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Box>
                                <Space size={10}/>
                            </div>
                        ))}
                    </Box>
                </Col>
            </Row>

            {modalVisible && (
                <Modal onClose={() => setModalVisible(false)}>
                    <Header>Adjust availability for {selectedCharity?.name}</Header>
                    <Body>
                        Selected date: {pickedDate}
                        <Space size={20}/>
                        <FormField label={'AM Slots'} name={'slotsAm'} onChange={changeAdjustedSlots}/>
                        <FormField label={'PM Slots'} name={'slotsPm'} onChange={changeAdjustedSlots}/>
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setModalVisible(false)} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary onClick={saveAdjustedSlots}>
                                Save
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>
            )}
        </BaseContainer>
    )
}
