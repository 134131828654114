import {Charity} from "../domain";
import {TableComponent} from "./table.component";
import React from "react";
import {field} from "../utility/field";

type Props = {
    charities: Charity[],
    onCharitySelected?: (charity: Charity) => void
}

const fields = [
    field('code', 'Charity ID'),
    field('name', 'Charity name', true),
    field('pocName', 'Point of contact'),
    field('phone', 'Phone number'),
    field('address', 'Address'),
    field('state', 'State'),
    field('closingBy', 'Closing by'),
]
export const CharitiesTable = (props: Props) => {
    const {charities, onCharitySelected = () => {}} = props;
    return (
        <TableComponent fields={fields} onRowClicked={onCharitySelected} data={charities}/>
    )
}
